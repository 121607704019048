// Generated by purs version 0.14.0
"use strict";
var CSS_Render = require("../CSS.Render/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var stylesheet = function (css) {
    var content = Data_Maybe.fromMaybe("")(CSS_Render.renderedSheet(CSS_Render.render(css)));
    return Halogen_HTML_Elements.style([ Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropMediaType)("text/css") ])([ Halogen_HTML_Core.text(content) ]);
};
var style = (function () {
    var toString = (function () {
        var $4 = Data_String_Common.joinWith("; ");
        var $5 = Foreign_Object.foldMap(Data_Monoid.monoidArray)(function (key) {
            return function (val) {
                return [ key + (": " + val) ];
            };
        });
        return function ($6) {
            return $4($5($6));
        };
    })();
    var rights = Data_Array.concatMap(Data_Foldable.foldMap(Data_Foldable.foldableEither)(Data_Monoid.monoidArray)(Data_Array.singleton));
    var property = function (v) {
        if (v instanceof CSS_Stylesheet.Property) {
            return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
        };
        return Data_Maybe.Nothing.value;
    };
    var rules = function (rs) {
        var properties = Control_Bind.bind(Control_Bind.bindArray)(Data_Array.mapMaybe(property)(rs))(function ($7) {
            return rights(CSS_Render.collect($7));
        });
        return Foreign_Object.fromFoldable(Data_Foldable.foldableArray)(properties);
    };
    var $8 = Halogen_HTML_Properties.attr("style");
    return function ($9) {
        return $8(toString(rules(CSS_Stylesheet.runS($9))));
    };
})();
module.exports = {
    style: style,
    stylesheet: stylesheet
};
