// Generated by purs version 0.14.0
"use strict";
var CSS_Property = require("../CSS.Property/index.js");
var CSS_Size = require("../CSS.Size/index.js");
var CSS_String = require("../CSS.String/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Tuple_Nested = require("../Data.Tuple.Nested/index.js");
var Stroke = function (x) {
    return x;
};
var wavy = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("wavy"));
var valStroke = new CSS_Property.Val(function (v) {
    return v;
});
var solid = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("solid"));
var ridge = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("ridge"));
var outset = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("outset"));
var inset = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("inset"));
var groove = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("groove"));
var eqStroke = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordStroke = new Data_Ord.Ord(function () {
    return eqStroke;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var $$double = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("double"));
var dotted = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("dotted"));
var dashed = Stroke(CSS_String.fromString(CSS_Property.isStringValue)("dashed"));
var borderTop = function (a) {
    return function (b) {
        return function (c) {
            return CSS_Stylesheet.key(CSS_Property.valTuple(valStroke)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Property.valColor)(CSS_Property.valUnit))))(CSS_String.fromString(CSS_Property.isStringKey)("border-top"))(Data_Tuple_Nested.tuple3(a)(b)(c));
        };
    };
};
var borderSpacing = CSS_Stylesheet.key(CSS_Size.valSize)(CSS_String.fromString(CSS_Property.isStringKey)("border-spacing"));
var borderRight = function (a) {
    return function (b) {
        return function (c) {
            return CSS_Stylesheet.key(CSS_Property.valTuple(valStroke)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Property.valColor)(CSS_Property.valUnit))))(CSS_String.fromString(CSS_Property.isStringKey)("border-right"))(Data_Tuple_Nested.tuple3(a)(b)(c));
        };
    };
};
var borderRadius = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return CSS_Stylesheet.key(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valUnit)))))(CSS_String.fromString(CSS_Property.isStringKey)("border-radius"))(Data_Tuple_Nested.tuple4(a)(b)(c)(d));
            };
        };
    };
};
var borderLeft = function (a) {
    return function (b) {
        return function (c) {
            return CSS_Stylesheet.key(CSS_Property.valTuple(valStroke)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Property.valColor)(CSS_Property.valUnit))))(CSS_String.fromString(CSS_Property.isStringKey)("border-left"))(Data_Tuple_Nested.tuple3(a)(b)(c));
        };
    };
};
var borderColor = CSS_Stylesheet.key(CSS_Property.valColor)(CSS_String.fromString(CSS_Property.isStringKey)("border-color"));
var borderBottom = function (a) {
    return function (b) {
        return function (c) {
            return CSS_Stylesheet.key(CSS_Property.valTuple(valStroke)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Property.valColor)(CSS_Property.valUnit))))(CSS_String.fromString(CSS_Property.isStringKey)("border-bottom"))(Data_Tuple_Nested.tuple3(a)(b)(c));
        };
    };
};
var border = function (a) {
    return function (b) {
        return function (c) {
            return CSS_Stylesheet.key(CSS_Property.valTuple(valStroke)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Property.valColor)(CSS_Property.valUnit))))(CSS_String.fromString(CSS_Property.isStringKey)("border"))(Data_Tuple_Nested.tuple3(a)(b)(c));
        };
    };
};
module.exports = {
    Stroke: Stroke,
    solid: solid,
    dotted: dotted,
    dashed: dashed,
    "double": $$double,
    wavy: wavy,
    groove: groove,
    ridge: ridge,
    inset: inset,
    outset: outset,
    border: border,
    borderTop: borderTop,
    borderBottom: borderBottom,
    borderLeft: borderLeft,
    borderRight: borderRight,
    borderColor: borderColor,
    borderRadius: borderRadius,
    borderSpacing: borderSpacing,
    eqStroke: eqStroke,
    ordStroke: ordStroke,
    valStroke: valStroke
};
