"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Submit = (function () {
    function Submit() {

    };
    Submit.value = new Submit();
    return Submit;
})();
var ValueInput = (function () {
    function ValueInput(value0) {
        this.value0 = value0;
    };
    ValueInput.create = function (value0) {
        return new ValueInput(value0);
    };
    return ValueInput;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var component = function (dictMonadAff) {
    var render = function (name) {
        return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.input([ Halogen_HTML_Properties.value(name), Halogen_HTML_Events.onValueInput(ValueInput.create) ]), Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(Data_Function["const"](Submit.value)) ])([ Halogen_HTML_Core.text("\u540d\u524d\u66f4\u65b0") ]) ]);
    };
    var initialState = function (x) {
        return x;
    };
    var handleAction = function (v) {
        if (v instanceof ValueInput) {
            return Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(v.value0);
        };
        if (v instanceof Submit) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(Halogen_Query_HalogenM.raise);
        };
        if (v instanceof Receive) {
            return Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(v.value0);
        };
        throw new Error("Failed pattern match at Form.Name (line 50, column 18 - line 53, column 29): " + [ v.constructor.name ]);
    };
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($6) {
                return Data_Maybe.Just.create(Receive.create($6));
            },
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    Submit: Submit,
    ValueInput: ValueInput,
    Receive: Receive,
    component: component
};
