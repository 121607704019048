"use strict";
var Data_Show = require("../Data.Show/index.js");
var gridItem = function (row) {
    return function (col) {
        return "grid-row: " + (Data_Show.show(Data_Show.showInt)(row) + ("; grid-column: " + (Data_Show.show(Data_Show.showInt)(col) + ";")));
    };
};
var grid = function (nRow) {
    return function (nCol) {
        return "display: grid; grid-template-rows: repeat(" + (Data_Show.show(Data_Show.showInt)(nRow) + (", 20px); grid-template-columns: 40px 80px repeat(" + (Data_Show.show(Data_Show.showInt)(nCol - 2 | 0) + ", 60px);")));
    };
};
module.exports = {
    grid: grid,
    gridItem: gridItem
};
