// Generated by purs version 0.14.0
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Distributive = function (Functor0, collect, distribute) {
    this.Functor0 = Functor0;
    this.collect = collect;
    this.distribute = distribute;
};
var distributiveIdentity = new Distributive(function () {
    return Data_Identity.functorIdentity;
}, function (dictFunctor) {
    return function (f) {
        var $14 = Data_Functor.map(dictFunctor)((function () {
            var $16 = Data_Newtype.unwrap();
            return function ($17) {
                return $16(f($17));
            };
        })());
        return function ($15) {
            return Data_Identity.Identity($14($15));
        };
    };
}, function (dictFunctor) {
    var $18 = Data_Functor.map(dictFunctor)(Data_Newtype.unwrap());
    return function ($19) {
        return Data_Identity.Identity($18($19));
    };
});
var distribute = function (dict) {
    return dict.distribute;
};
var distributiveFunction = new Distributive(function () {
    return Data_Functor.functorFn;
}, function (dictFunctor) {
    return function (f) {
        var $20 = distribute(distributiveFunction)(dictFunctor);
        var $21 = Data_Functor.map(dictFunctor)(f);
        return function ($22) {
            return $20($21($22));
        };
    };
}, function (dictFunctor) {
    return function (a) {
        return function (e) {
            return Data_Functor.map(dictFunctor)(function (v) {
                return v(e);
            })(a);
        };
    };
});
var cotraverse = function (dictDistributive) {
    return function (dictFunctor) {
        return function (f) {
            var $23 = Data_Functor.map(dictDistributive.Functor0())(f);
            var $24 = distribute(dictDistributive)(dictFunctor);
            return function ($25) {
                return $23($24($25));
            };
        };
    };
};
var collectDefault = function (dictDistributive) {
    return function (dictFunctor) {
        return function (f) {
            var $26 = distribute(dictDistributive)(dictFunctor);
            var $27 = Data_Functor.map(dictFunctor)(f);
            return function ($28) {
                return $26($27($28));
            };
        };
    };
};
var distributiveTuple = function (dictTypeEquals) {
    return new Distributive(function () {
        return Data_Tuple.functorTuple;
    }, function (dictFunctor) {
        return collectDefault(distributiveTuple(dictTypeEquals))(dictFunctor);
    }, function (dictFunctor) {
        var $29 = Data_Tuple.Tuple.create(Type_Equality.from(dictTypeEquals)(Data_Unit.unit));
        var $30 = Data_Functor.map(dictFunctor)(Data_Tuple.snd);
        return function ($31) {
            return $29($30($31));
        };
    });
};
var collect = function (dict) {
    return dict.collect;
};
var distributeDefault = function (dictDistributive) {
    return function (dictFunctor) {
        return collect(dictDistributive)(dictFunctor)(Control_Category.identity(Control_Category.categoryFn));
    };
};
module.exports = {
    collect: collect,
    distribute: distribute,
    Distributive: Distributive,
    distributeDefault: distributeDefault,
    collectDefault: collectDefault,
    cotraverse: cotraverse,
    distributiveIdentity: distributiveIdentity,
    distributiveFunction: distributiveFunction,
    distributiveTuple: distributiveTuple
};
