"use strict";
var CSS_Border = require("../CSS.Border/index.js");
var CSS_Box = require("../CSS.Box/index.js");
var CSS_Common = require("../CSS.Common/index.js");
var CSS_Display = require("../CSS.Display/index.js");
var CSS_Flexbox = require("../CSS.Flexbox/index.js");
var CSS_Font = require("../CSS.Font/index.js");
var CSS_Geometry = require("../CSS.Geometry/index.js");
var CSS_Size = require("../CSS.Size/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var CSS_TextAlign = require("../CSS.TextAlign/index.js");
var Color = require("../Color/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_CSS = require("../Halogen.HTML.CSS/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Types = require("../Types/index.js");
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var ChangeIsProportional = (function () {
    function ChangeIsProportional(value0) {
        this.value0 = value0;
    };
    ChangeIsProportional.create = function (value0) {
        return new ChangeIsProportional(value0);
    };
    return ChangeIsProportional;
})();
var component = function (dictMonadAff) {
    var renderPillerName = function (name) {
        return Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.width(CSS_Size.px(199.0)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_TextAlign.textAlign(CSS_TextAlign.center))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.marginRight(CSS_Size.px(80.0)))(function () {
                    return CSS_Flexbox.flexShrink(0.0);
                });
            });
        })) ])([ Halogen_HTML_Core.text(name) ]);
    };
    var renderExtraElement = Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.width(CSS_Size.px(1.0)))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.height(CSS_Size.px(400.0)))(function () {
            return CSS_Flexbox.flexShrink(0.0);
        });
    })) ])([  ]);
    var renderCell = function (name) {
        return function (value) {
            return function (maxValue) {
                return function (isEdge) {
                    var n = Data_Int.toNumber(Data_String_CodePoints.length(name));
                    var cellHeight = (value / maxValue) * 400.0 + (function () {
                        if (isEdge) {
                            return 0.5;
                        };
                        return 1.0;
                    })();
                    var fs = (function () {
                        var $10 = n < 200.0 / cellHeight;
                        if ($10) {
                            return Data_Ord.min(Data_Ord.ordNumber)(98.0 / n)(cellHeight - 2.0);
                        };
                        return Data_Ord.min(Data_Ord.ordNumber)(196.0 / n)((cellHeight - 2.0) / 2.0);
                    })();
                    return Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.height(CSS_Size.px(cellHeight)))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Border.border(CSS_Border.solid)(CSS_Size.px(1.0))(Color.black))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.marginTop(CSS_Size.px(-1.0)))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Display.display(CSS_Display.flex))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Flexbox.justifyContent(CSS_Common.center(CSS_Flexbox.centerJustifyContentValue)))(function () {
                                        return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Flexbox.alignItems(CSS_Common.center(CSS_Flexbox.centerAlignItemsValue)))(function () {
                                            return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Box.boxSizing(CSS_Box.borderBox))(function () {
                                                var $11 = fs < 16.0;
                                                if ($11) {
                                                    return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Font.fontSize(CSS_Size.px(fs)))(function () {
                                                        return CSS_Geometry.lineHeight(CSS_Size.px(fs));
                                                    });
                                                };
                                                return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Font.fontSize(CSS_Size.px(16.0)))(function () {
                                                    return CSS_Geometry.lineHeight(CSS_Size.px(16.0));
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    })) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.style("overflow-wrap: anywhere;") ])([ Halogen_HTML_Core.text(name) ]) ]);
                };
            };
        };
    };
    var renderPillar = function (debitNames) {
        return function (creditNames) {
            return function (pillarName) {
                return function (values) {
                    return function (maxHeight) {
                        return Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.height(CSS_Size.px(400.0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.width(CSS_Size.px(200.0)))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Display.display(CSS_Display.flex))(function () {
                                    return CSS_Geometry.marginRight(CSS_Size.px(80.0));
                                });
                            });
                        })) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.height(CSS_Size.px(400.0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.width(CSS_Size.px(100.0)))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Display.display(CSS_Display.flex))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Flexbox.flexDirection(CSS_Flexbox.column))(function () {
                                        return CSS_Flexbox.justifyContent(CSS_Flexbox.flexEnd(CSS_Flexbox.flexEndJustifyContentValue));
                                    });
                                });
                            });
                        })) ])(Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(debitNames)(values.debit))(function (idx) {
                            return function (v) {
                                return renderCell(v.value0)(v.value1)(maxHeight)(idx === 0 || idx === (Data_Array.length(debitNames) - 1 | 0));
                            };
                        })), Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.height(CSS_Size.px(400.0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.width(CSS_Size.px(100.0)))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Display.display(CSS_Display.flex))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Flexbox.flexDirection(CSS_Flexbox.column))(function () {
                                        return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Flexbox.justifyContent(CSS_Flexbox.flexEnd(CSS_Flexbox.flexEndJustifyContentValue)))(function () {
                                            return CSS_Geometry.marginLeft(CSS_Size.px(-1.0));
                                        });
                                    });
                                });
                            });
                        })) ])(Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(creditNames)(values.credit))(function (idx) {
                            return function (v) {
                                return renderCell(v.value0)(v.value1)(maxHeight)(idx === 0 || idx === (Data_Array.length(creditNames) - 1 | 0));
                            };
                        })) ]);
                    };
                };
            };
        };
    };
    var render = function (v) {
        return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Display.display(CSS_Display.flex))(function () {
            return CSS_Geometry.marginBottom(CSS_Size.px(60.0));
        })) ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(Data_Function["const"](new ChangeIsProportional(true))) ])([ Halogen_HTML_Core.text("\u6bd4\u4f8b") ]), Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(Data_Function["const"](new ChangeIsProportional(false))) ])([ Halogen_HTML_Core.text("\u7b49\u9ad8") ]) ]), Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.height(CSS_Size.px(400.0)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.width(CSS_Size.px(100.0)))(function () {
                return CSS_Display.display(CSS_Display.flex);
            });
        })) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(Data_Array.zip(v.pillarsData.pillarNames)(v.pillarsData.values))(function (v1) {
            return renderPillar(v.pillarsData.debitNames)(v.pillarsData.creditNames)(v1.value0)(v1.value1)((function () {
                if (v.isProportional) {
                    return Types.getMaxValue(v.pillarsData);
                };
                return Types.getMaxValueSingle(v1.value1);
            })());
        }))([ renderExtraElement ])), Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(CSS_Display.display(CSS_Display.flex)) ])(Data_Functor.map(Data_Functor.functorArray)(renderPillerName)(v.pillarsData.pillarNames)) ]);
    };
    var handleAction = function (v) {
        if (v instanceof Receive) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                var $26 = {};
                for (var $27 in v1) {
                    if ({}.hasOwnProperty.call(v1, $27)) {
                        $26[$27] = v1[$27];
                    };
                };
                $26.pillarsData = v.value0;
                return $26;
            });
        };
        if (v instanceof ChangeIsProportional) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                var $30 = {};
                for (var $31 in v1) {
                    if ({}.hasOwnProperty.call(v1, $31)) {
                        $30[$31] = v1[$31];
                    };
                };
                $30.isProportional = v.value0;
                return $30;
            });
        };
        throw new Error("Failed pattern match at Pillar (line 193, column 18 - line 195, column 67): " + [ v.constructor.name ]);
    };
    return Halogen_Component.mkComponent({
        initialState: function (x) {
            return {
                pillarsData: x,
                isProportional: true
            };
        },
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($34) {
                return Data_Maybe.Just.create(Receive.create($34));
            },
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    Receive: Receive,
    ChangeIsProportional: ChangeIsProportional,
    component: component
};
