// Generated by purs version 0.14.0
"use strict";
var CSS_Display = require("../CSS.Display/index.js");
var CSS_Geometry = require("../CSS.Geometry/index.js");
var CSS_Size = require("../CSS.Size/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Argonaut_Parser = require("../Data.Argonaut.Parser/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DeleteButton = require("../DeleteButton/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Form = require("../Form/index.js");
var Form_Name = require("../Form.Name/index.js");
var Form_Select = require("../Form.Select/index.js");
var Halogen_Aff_Util = require("../Halogen.Aff.Util/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML = require("../Halogen.HTML/index.js");
var Halogen_HTML_CSS = require("../Halogen.HTML.CSS/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_VDom_Driver = require("../Halogen.VDom.Driver/index.js");
var Pillar = require("../Pillar/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Types = require("../Types/index.js");
var Utils = require("../Utils/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var HandleSelect = (function () {
    function HandleSelect(value0) {
        this.value0 = value0;
    };
    HandleSelect.create = function (value0) {
        return new HandleSelect(value0);
    };
    return HandleSelect;
})();
var HandleDelete = (function () {
    function HandleDelete(value0) {
        this.value0 = value0;
    };
    HandleDelete.create = function (value0) {
        return new HandleDelete(value0);
    };
    return HandleDelete;
})();
var FormUpdated = (function () {
    function FormUpdated(value0) {
        this.value0 = value0;
    };
    FormUpdated.create = function (value0) {
        return new FormUpdated(value0);
    };
    return FormUpdated;
})();
var NameUpdated = (function () {
    function NameUpdated(value0) {
        this.value0 = value0;
    };
    NameUpdated.create = function (value0) {
        return new NameUpdated(value0);
    };
    return NameUpdated;
})();
var component = function (dictMonadAff) {
    var initState = {
        pillarsDataList: [ {
            name: "default",
            pillarsData: Types.init
        } ],
        index: 0
    };
    var initialState = Data_Function["const"](initState);
    var handleAction = function (v) {
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML.window))(function (w) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_Window.localStorage(w)))(function (s) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Storage_Storage.getItem("data")(s)))(function (ms) {
                        if (ms instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                        };
                        if (ms instanceof Data_Maybe.Just) {
                            var v1 = Data_Argonaut_Parser.jsonParser(ms.value0);
                            if (v1 instanceof Data_Either.Left) {
                                return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                            };
                            if (v1 instanceof Data_Either.Right) {
                                var v2 = Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonString)))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonString)))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonString)))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonNumber)))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonNumber)))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                    return "debit";
                                }))()())(new Data_Symbol.IsSymbol(function () {
                                    return "credit";
                                }))()())())))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                    return "values";
                                }))()())(new Data_Symbol.IsSymbol(function () {
                                    return "pillarNames";
                                }))()())(new Data_Symbol.IsSymbol(function () {
                                    return "debitNames";
                                }))()())(new Data_Symbol.IsSymbol(function () {
                                    return "creditNames";
                                }))()())()))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                    return "pillarsData";
                                }))()())(new Data_Symbol.IsSymbol(function () {
                                    return "name";
                                }))()())())))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                    return "pillarsDataList";
                                }))()())(new Data_Symbol.IsSymbol(function () {
                                    return "index";
                                }))()())())(v1.value0);
                                if (v2 instanceof Data_Either.Left) {
                                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                                };
                                if (v2 instanceof Data_Either.Right) {
                                    return Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(v2.value0);
                                };
                                throw new Error("Failed pattern match at Main (line 124, column 25 - line 126, column 49): " + [ v2.constructor.name ]);
                            };
                            throw new Error("Failed pattern match at Main (line 122, column 21 - line 126, column 49): " + [ v1.constructor.name ]);
                        };
                        throw new Error("Failed pattern match at Main (line 120, column 7 - line 126, column 49): " + [ ms.constructor.name ]);
                    });
                });
            });
        };
        if (v instanceof HandleSelect && v.value0 instanceof Form_Select.Switch) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return v1.pillarsDataList;
            }))(function (pdl) {
                var $23 = 0 <= v.value0.value0 && v.value0.value0 <= (Data_Array.length(pdl) - 1 | 0);
                if ($23) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                        var $24 = {};
                        for (var $25 in v1) {
                            if ({}.hasOwnProperty.call(v1, $25)) {
                                $24[$25] = v1[$25];
                            };
                        };
                        $24.index = v.value0.value0;
                        return $24;
                    }))(function () {
                        return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML.window))(function (w) {
                            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_Window.localStorage(w)))(function (s) {
                                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                                    return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Storage_Storage.setItem("data")(Data_Argonaut_Core.stringify(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "debit";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "credit";
                                    }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "values";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "pillarNames";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "debitNames";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "creditNames";
                                    }))())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "pillarsData";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "name";
                                    }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "pillarsDataList";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "index";
                                    }))())())(state)))(s));
                                });
                            });
                        });
                    });
                };
                return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
            });
        };
        if (v instanceof HandleSelect && v.value0 instanceof Form_Select.Add) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)({
                    pillarsDataList: Data_Array.snoc(state.pillarsDataList)({
                        pillarsData: Types.init,
                        name: "\u65b0\u898f\u30c7\u30fc\u30bf"
                    }),
                    index: Data_Array.length(state.pillarsDataList)
                }))(function () {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML.window))(function (w) {
                        return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_Window.localStorage(w)))(function (s) {
                            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (updatedState) {
                                return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Storage_Storage.setItem("data")(Data_Argonaut_Core.stringify(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                    return "debit";
                                }))())(new Data_Symbol.IsSymbol(function () {
                                    return "credit";
                                }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                    return "values";
                                }))())(new Data_Symbol.IsSymbol(function () {
                                    return "pillarNames";
                                }))())(new Data_Symbol.IsSymbol(function () {
                                    return "debitNames";
                                }))())(new Data_Symbol.IsSymbol(function () {
                                    return "creditNames";
                                }))())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                    return "pillarsData";
                                }))())(new Data_Symbol.IsSymbol(function () {
                                    return "name";
                                }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                    return "pillarsDataList";
                                }))())(new Data_Symbol.IsSymbol(function () {
                                    return "index";
                                }))())())(updatedState)))(s));
                            });
                        });
                    });
                });
            });
        };
        if (v instanceof HandleDelete) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Effect_Class_Console.logShow(Effect_Class.monadEffectEffect)(Data_Show.showString)("delete")))(function () {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                    return Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Array.length(state.pillarsDataList) > 1)(Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Data_Array.deleteAt(state.index)(state.pillarsDataList))(function (pdl) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                            var $31 = {};
                            for (var $32 in v1) {
                                if ({}.hasOwnProperty.call(v1, $32)) {
                                    $31[$32] = v1[$32];
                                };
                            };
                            $31.pillarsDataList = pdl;
                            $31.index = (function () {
                                var $30 = state.index === 0;
                                if ($30) {
                                    return 0;
                                };
                                return state.index - 1 | 0;
                            })();
                            return $31;
                        }))(function () {
                            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML.window))(function (w) {
                                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_Window.localStorage(w)))(function (s) {
                                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (updatedState) {
                                        return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Storage_Storage.setItem("data")(Data_Argonaut_Core.stringify(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                            return "debit";
                                        }))())(new Data_Symbol.IsSymbol(function () {
                                            return "credit";
                                        }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                            return "values";
                                        }))())(new Data_Symbol.IsSymbol(function () {
                                            return "pillarNames";
                                        }))())(new Data_Symbol.IsSymbol(function () {
                                            return "debitNames";
                                        }))())(new Data_Symbol.IsSymbol(function () {
                                            return "creditNames";
                                        }))())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                            return "pillarsData";
                                        }))())(new Data_Symbol.IsSymbol(function () {
                                            return "name";
                                        }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                            return "pillarsDataList";
                                        }))())(new Data_Symbol.IsSymbol(function () {
                                            return "index";
                                        }))())())(updatedState)))(s));
                                    });
                                });
                            });
                        });
                    }));
                });
            });
        };
        if (v instanceof FormUpdated) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Data_Array.modifyAt(state.index)(function (v1) {
                    var $35 = {};
                    for (var $36 in v1) {
                        if ({}.hasOwnProperty.call(v1, $36)) {
                            $35[$36] = v1[$36];
                        };
                    };
                    $35.pillarsData = v.value0;
                    return $35;
                })(state.pillarsDataList))(function (pdl) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                        var $38 = {};
                        for (var $39 in v1) {
                            if ({}.hasOwnProperty.call(v1, $39)) {
                                $38[$39] = v1[$39];
                            };
                        };
                        $38.pillarsDataList = pdl;
                        return $38;
                    }))(function () {
                        return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML.window))(function (w) {
                            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_Window.localStorage(w)))(function (s) {
                                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (updatedState) {
                                    return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Storage_Storage.setItem("data")(Data_Argonaut_Core.stringify(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "debit";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "credit";
                                    }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "values";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "pillarNames";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "debitNames";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "creditNames";
                                    }))())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "pillarsData";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "name";
                                    }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "pillarsDataList";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "index";
                                    }))())())(updatedState)))(s));
                                });
                            });
                        });
                    });
                });
            });
        };
        if (v instanceof NameUpdated) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Data_Array.modifyAt(state.index)(function (v1) {
                    var $42 = {};
                    for (var $43 in v1) {
                        if ({}.hasOwnProperty.call(v1, $43)) {
                            $42[$43] = v1[$43];
                        };
                    };
                    $42.name = v.value0;
                    return $42;
                })(state.pillarsDataList))(function (pdl) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                        var $45 = {};
                        for (var $46 in v1) {
                            if ({}.hasOwnProperty.call(v1, $46)) {
                                $45[$46] = v1[$46];
                            };
                        };
                        $45.pillarsDataList = pdl;
                        return $45;
                    }))(function () {
                        return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML.window))(function (w) {
                            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_Window.localStorage(w)))(function (s) {
                                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (updatedState) {
                                    return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Storage_Storage.setItem("data")(Data_Argonaut_Core.stringify(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "debit";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "credit";
                                    }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "values";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "pillarNames";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "debitNames";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "creditNames";
                                    }))())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "pillarsData";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "name";
                                    }))())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                        return "pillarsDataList";
                                    }))())(new Data_Symbol.IsSymbol(function () {
                                        return "index";
                                    }))())())(updatedState)))(s));
                                });
                            });
                        });
                    });
                });
            });
        };
        throw new Error("Failed pattern match at Main (line 115, column 18 - line 173, column 78): " + [ v.constructor.name ]);
    };
    var render = function (state) {
        var pillarsData = (function () {
            var v = Data_Array.index(state.pillarsDataList)(state.index);
            if (v instanceof Data_Maybe.Just) {
                return v.value0.pillarsData;
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Types.init;
            };
            throw new Error("Failed pattern match at Main (line 107, column 19 - line 109, column 22): " + [ v.constructor.name ]);
        })();
        var name = (function () {
            var v = Data_Array.index(state.pillarsDataList)(state.index);
            if (v instanceof Data_Maybe.Just) {
                return v.value0.name;
            };
            if (v instanceof Data_Maybe.Nothing) {
                return "default";
            };
            throw new Error("Failed pattern match at Main (line 111, column 12 - line 113, column 27): " + [ v.constructor.name ]);
        })();
        return Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.marginTop(CSS_Size.px(40.0)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.marginLeft(CSS_Size.px(60.0)))(function () {
                return CSS_Geometry.marginBottom(CSS_Size.px(60.0));
            });
        })) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(CSS_Display.display(CSS_Display.flex)) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(CSS_Geometry.marginRight(CSS_Size.px(20.0))) ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
            return "select";
        }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Form_Select.component(dictMonadAff))(state)(HandleSelect.create) ]), Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(CSS_Geometry.marginRight(CSS_Size.px(20.0))) ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
            return "name";
        }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Form_Name.component(dictMonadAff))(name)(NameUpdated.create) ]), Halogen_HTML_Elements.div_([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
            return "deleteButton";
        }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(DeleteButton.component(dictMonadAff))(name)(HandleDelete.create) ]) ]), Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(CSS_Geometry.marginBottom(CSS_Size.px(10.0))) ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
            return "form";
        }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Form.component(dictMonadAff))(pillarsData)(FormUpdated.create) ]), Halogen_HTML_Elements.div_([ Halogen_HTML.slot_()(new Data_Symbol.IsSymbol(function () {
            return "pillar";
        }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Pillar.component(dictMonadAff))(pillarsData) ]) ]);
    };
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
var main = Halogen_Aff_Util.runHalogenAff(Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitBody)(function (body) {
    return Halogen_VDom_Driver.runUI(component(Effect_Aff_Class.monadAffAff))(Data_Unit.unit)(body);
}));
module.exports = {
    main: main,
    Initialize: Initialize,
    HandleSelect: HandleSelect,
    HandleDelete: HandleDelete,
    FormUpdated: FormUpdated,
    NameUpdated: NameUpdated,
    component: component
};
