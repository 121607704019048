// Generated by purs version 0.14.0
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var RefLabel = function (x) {
    return x;
};
var RefUpdate = (function () {
    function RefUpdate(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    RefUpdate.create = function (value0) {
        return function (value1) {
            return new RefUpdate(value0, value1);
        };
    };
    return RefUpdate;
})();
var Action = (function () {
    function Action(value0) {
        this.value0 = value0;
    };
    Action.create = function (value0) {
        return new Action(value0);
    };
    return Action;
})();
var ordRefLabel = Data_Ord.ordString;
var newtypeRefLabel = new Data_Newtype.Newtype(function () {
    return undefined;
});
var functorInput = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof RefUpdate) {
            return new RefUpdate(m.value0, m.value1);
        };
        if (m instanceof Action) {
            return new Action(f(m.value0));
        };
        throw new Error("Failed pattern match at Halogen.Query.Input (line 19, column 1 - line 19, column 46): " + [ m.constructor.name ]);
    };
});
var eqRefLabel = Data_Eq.eqString;
module.exports = {
    RefLabel: RefLabel,
    RefUpdate: RefUpdate,
    Action: Action,
    newtypeRefLabel: newtypeRefLabel,
    eqRefLabel: eqRefLabel,
    ordRefLabel: ordRefLabel,
    functorInput: functorInput
};
