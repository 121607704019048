// Generated by purs version 0.14.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Utils = require("../Utils/index.js");
var Web_Event_Event = require("../Web.Event.Event/index.js");
var Web_HTML_HTMLSelectElement = require("../Web.HTML.HTMLSelectElement/index.js");
var Switch = (function () {
    function Switch(value0) {
        this.value0 = value0;
    };
    Switch.create = function (value0) {
        return new Switch(value0);
    };
    return Switch;
})();
var Add = (function () {
    function Add() {

    };
    Add.value = new Add();
    return Add;
})();
var Selected = (function () {
    function Selected(value0) {
        this.value0 = value0;
    };
    Selected.create = function (value0) {
        return new Selected(value0);
    };
    return Selected;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var component = function (dictMonadAff) {
    var render = function (state) {
        return Halogen_HTML_Elements.select([ Halogen_HTML_Events.onChange(Selected.create) ])(Data_Array.cons(Halogen_HTML_Elements.option([ Halogen_HTML_Properties.value("-1") ])([ Halogen_HTML_Core.text("\u65b0\u898f\u4f5c\u6210") ]))(Data_Function.flip(Data_Array.mapWithIndex)(state.names)(function (idx) {
            return function (name) {
                return Halogen_HTML_Elements.option([ Halogen_HTML_Properties.value(Data_Show.show(Data_Show.showInt)(idx)), Halogen_HTML_Properties.selected(idx === state.index) ])([ Halogen_HTML_Core.text(name) ]);
            };
        })));
    };
    var initialState = function (input) {
        return {
            names: Data_Functor.map(Data_Functor.functorArray)(function (v) {
                return v.name;
            })(input.pillarsDataList),
            index: input.index
        };
    };
    var handleAction = function (v) {
        if (v instanceof Selected) {
            return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Control_Bind.bind(Data_Maybe.bindMaybe)(Web_Event_Event.currentTarget(v.value0))(Web_HTML_HTMLSelectElement.fromEventTarget))(function (selectElement) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLSelectElement.value(selectElement)))(function (str) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                        return v1.names;
                    }))(function (names) {
                        return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Data_Int.fromString(str))(function (idx) {
                            var $6 = idx < 0;
                            if ($6) {
                                return Halogen_Query_HalogenM.raise(Add.value);
                            };
                            return Halogen_Query_HalogenM.raise(new Switch(idx));
                        });
                    });
                });
            });
        };
        if (v instanceof Receive) {
            return Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(initialState(v.value0));
        };
        throw new Error("Failed pattern match at Form.Select (line 58, column 18 - line 68, column 40): " + [ v.constructor.name ]);
    };
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($9) {
                return Data_Maybe.Just.create(Receive.create($9));
            },
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    Switch: Switch,
    Add: Add,
    Selected: Selected,
    Receive: Receive,
    component: component
};
