// Generated by purs version 0.14.0
"use strict";
var $foreign = require("./foreign.js");
var Web_HTML_Common = require("../Web.HTML.Common/index.js");
var Web_HTML_HTMLAnchorElement = require("../Web.HTML.HTMLAnchorElement/index.js");
var Web_HTML_HTMLAreaElement = require("../Web.HTML.HTMLAreaElement/index.js");
var Web_HTML_HTMLAudioElement = require("../Web.HTML.HTMLAudioElement/index.js");
var Web_HTML_HTMLBRElement = require("../Web.HTML.HTMLBRElement/index.js");
var Web_HTML_HTMLBaseElement = require("../Web.HTML.HTMLBaseElement/index.js");
var Web_HTML_HTMLBodyElement = require("../Web.HTML.HTMLBodyElement/index.js");
var Web_HTML_HTMLButtonElement = require("../Web.HTML.HTMLButtonElement/index.js");
var Web_HTML_HTMLCanvasElement = require("../Web.HTML.HTMLCanvasElement/index.js");
var Web_HTML_HTMLDListElement = require("../Web.HTML.HTMLDListElement/index.js");
var Web_HTML_HTMLDataElement = require("../Web.HTML.HTMLDataElement/index.js");
var Web_HTML_HTMLDataListElement = require("../Web.HTML.HTMLDataListElement/index.js");
var Web_HTML_HTMLDivElement = require("../Web.HTML.HTMLDivElement/index.js");
var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var Web_HTML_HTMLEmbedElement = require("../Web.HTML.HTMLEmbedElement/index.js");
var Web_HTML_HTMLFieldSetElement = require("../Web.HTML.HTMLFieldSetElement/index.js");
var Web_HTML_HTMLFormElement = require("../Web.HTML.HTMLFormElement/index.js");
var Web_HTML_HTMLHRElement = require("../Web.HTML.HTMLHRElement/index.js");
var Web_HTML_HTMLHeadElement = require("../Web.HTML.HTMLHeadElement/index.js");
var Web_HTML_HTMLHeadingElement = require("../Web.HTML.HTMLHeadingElement/index.js");
var Web_HTML_HTMLIFrameElement = require("../Web.HTML.HTMLIFrameElement/index.js");
var Web_HTML_HTMLImageElement = require("../Web.HTML.HTMLImageElement/index.js");
var Web_HTML_HTMLInputElement = require("../Web.HTML.HTMLInputElement/index.js");
var Web_HTML_HTMLKeygenElement = require("../Web.HTML.HTMLKeygenElement/index.js");
var Web_HTML_HTMLLIElement = require("../Web.HTML.HTMLLIElement/index.js");
var Web_HTML_HTMLLabelElement = require("../Web.HTML.HTMLLabelElement/index.js");
var Web_HTML_HTMLLegendElement = require("../Web.HTML.HTMLLegendElement/index.js");
var Web_HTML_HTMLLinkElement = require("../Web.HTML.HTMLLinkElement/index.js");
var Web_HTML_HTMLMapElement = require("../Web.HTML.HTMLMapElement/index.js");
var Web_HTML_HTMLMediaElement = require("../Web.HTML.HTMLMediaElement/index.js");
var Web_HTML_HTMLMetaElement = require("../Web.HTML.HTMLMetaElement/index.js");
var Web_HTML_HTMLMeterElement = require("../Web.HTML.HTMLMeterElement/index.js");
var Web_HTML_HTMLModElement = require("../Web.HTML.HTMLModElement/index.js");
var Web_HTML_HTMLOListElement = require("../Web.HTML.HTMLOListElement/index.js");
var Web_HTML_HTMLObjectElement = require("../Web.HTML.HTMLObjectElement/index.js");
var Web_HTML_HTMLOptGroupElement = require("../Web.HTML.HTMLOptGroupElement/index.js");
var Web_HTML_HTMLOptionElement = require("../Web.HTML.HTMLOptionElement/index.js");
var Web_HTML_HTMLOutputElement = require("../Web.HTML.HTMLOutputElement/index.js");
var Web_HTML_HTMLParagraphElement = require("../Web.HTML.HTMLParagraphElement/index.js");
var Web_HTML_HTMLParamElement = require("../Web.HTML.HTMLParamElement/index.js");
var Web_HTML_HTMLPreElement = require("../Web.HTML.HTMLPreElement/index.js");
var Web_HTML_HTMLProgressElement = require("../Web.HTML.HTMLProgressElement/index.js");
var Web_HTML_HTMLQuoteElement = require("../Web.HTML.HTMLQuoteElement/index.js");
var Web_HTML_HTMLScriptElement = require("../Web.HTML.HTMLScriptElement/index.js");
var Web_HTML_HTMLSelectElement = require("../Web.HTML.HTMLSelectElement/index.js");
var Web_HTML_HTMLSourceElement = require("../Web.HTML.HTMLSourceElement/index.js");
var Web_HTML_HTMLSpanElement = require("../Web.HTML.HTMLSpanElement/index.js");
var Web_HTML_HTMLStyleElement = require("../Web.HTML.HTMLStyleElement/index.js");
var Web_HTML_HTMLTableCaptionElement = require("../Web.HTML.HTMLTableCaptionElement/index.js");
var Web_HTML_HTMLTableCellElement = require("../Web.HTML.HTMLTableCellElement/index.js");
var Web_HTML_HTMLTableColElement = require("../Web.HTML.HTMLTableColElement/index.js");
var Web_HTML_HTMLTableDataCellElement = require("../Web.HTML.HTMLTableDataCellElement/index.js");
var Web_HTML_HTMLTableElement = require("../Web.HTML.HTMLTableElement/index.js");
var Web_HTML_HTMLTableHeaderCellElement = require("../Web.HTML.HTMLTableHeaderCellElement/index.js");
var Web_HTML_HTMLTableRowElement = require("../Web.HTML.HTMLTableRowElement/index.js");
var Web_HTML_HTMLTableSectionElement = require("../Web.HTML.HTMLTableSectionElement/index.js");
var Web_HTML_HTMLTemplateElement = require("../Web.HTML.HTMLTemplateElement/index.js");
var Web_HTML_HTMLTextAreaElement = require("../Web.HTML.HTMLTextAreaElement/index.js");
var Web_HTML_HTMLTimeElement = require("../Web.HTML.HTMLTimeElement/index.js");
var Web_HTML_HTMLTitleElement = require("../Web.HTML.HTMLTitleElement/index.js");
var Web_HTML_HTMLTrackElement = require("../Web.HTML.HTMLTrackElement/index.js");
var Web_HTML_HTMLUListElement = require("../Web.HTML.HTMLUListElement/index.js");
var Web_HTML_HTMLVideoElement = require("../Web.HTML.HTMLVideoElement/index.js");
var Web_HTML_History = require("../Web.HTML.History/index.js");
var Web_HTML_Location = require("../Web.HTML.Location/index.js");
var Web_HTML_Navigator = require("../Web.HTML.Navigator/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
module.exports = {
    window: $foreign.window
};
