// Generated by purs version 0.14.0
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Const = require("../Data.Const/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_App = require("../Data.Functor.App/index.js");
var Data_Functor_Compose = require("../Data.Functor.Compose/index.js");
var Data_Functor_Coproduct = require("../Data.Functor.Coproduct/index.js");
var Data_Functor_Product = require("../Data.Functor.Product/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Maybe_First = require("../Data.Maybe.First/index.js");
var Data_Maybe_Last = require("../Data.Maybe.Last/index.js");
var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");
var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");
var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");
var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");
var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");
var Data_Traversable_Accum = require("../Data.Traversable.Accum/index.js");
var Data_Traversable_Accum_Internal = require("../Data.Traversable.Accum.Internal/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Traversable = function (Foldable1, Functor0, sequence, traverse) {
    this.Foldable1 = Foldable1;
    this.Functor0 = Functor0;
    this.sequence = sequence;
    this.traverse = traverse;
};
var traverse = function (dict) {
    return dict.traverse;
};
var traversableTuple = new Traversable(function () {
    return Data_Foldable.foldableTuple;
}, function () {
    return Data_Tuple.functorTuple;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Tuple.Tuple.create(v.value0))(v.value1);
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Tuple.Tuple.create(v.value0))(f(v.value1));
        };
    };
});
var traversableMultiplicative = new Traversable(function () {
    return Data_Foldable.foldableMultiplicative;
}, function () {
    return Data_Monoid_Multiplicative.functorMultiplicative;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Multiplicative.Multiplicative)(v);
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Multiplicative.Multiplicative)(f(v));
        };
    };
});
var traversableMaybe = new Traversable(function () {
    return Data_Foldable.foldableMaybe;
}, function () {
    return Data_Maybe.functorMaybe;
}, function (dictApplicative) {
    return function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
        };
        if (v instanceof Data_Maybe.Just) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe.Just.create)(v.value0);
        };
        throw new Error("Failed pattern match at Data.Traversable (line 115, column 1 - line 119, column 33): " + [ v.constructor.name ]);
    };
}, function (dictApplicative) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
            };
            if (v1 instanceof Data_Maybe.Just) {
                return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe.Just.create)(v(v1.value0));
            };
            throw new Error("Failed pattern match at Data.Traversable (line 115, column 1 - line 119, column 33): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
});
var traversableIdentity = new Traversable(function () {
    return Data_Foldable.foldableIdentity;
}, function () {
    return Data_Identity.functorIdentity;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Identity.Identity)(v);
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Identity.Identity)(f(v));
        };
    };
});
var traversableEither = new Traversable(function () {
    return Data_Foldable.foldableEither;
}, function () {
    return Data_Either.functorEither;
}, function (dictApplicative) {
    return function (v) {
        if (v instanceof Data_Either.Left) {
            return Control_Applicative.pure(dictApplicative)(new Data_Either.Left(v.value0));
        };
        if (v instanceof Data_Either.Right) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Either.Right.create)(v.value0);
        };
        throw new Error("Failed pattern match at Data.Traversable (line 149, column 1 - line 153, column 36): " + [ v.constructor.name ]);
    };
}, function (dictApplicative) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Data_Either.Left) {
                return Control_Applicative.pure(dictApplicative)(new Data_Either.Left(v1.value0));
            };
            if (v1 instanceof Data_Either.Right) {
                return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Either.Right.create)(v(v1.value0));
            };
            throw new Error("Failed pattern match at Data.Traversable (line 149, column 1 - line 153, column 36): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
});
var traversableDual = new Traversable(function () {
    return Data_Foldable.foldableDual;
}, function () {
    return Data_Monoid_Dual.functorDual;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Dual.Dual)(v);
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Dual.Dual)(f(v));
        };
    };
});
var traversableDisj = new Traversable(function () {
    return Data_Foldable.foldableDisj;
}, function () {
    return Data_Monoid_Disj.functorDisj;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Disj.Disj)(v);
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Disj.Disj)(f(v));
        };
    };
});
var traversableConst = new Traversable(function () {
    return Data_Foldable.foldableConst;
}, function () {
    return Data_Const.functorConst;
}, function (dictApplicative) {
    return function (v) {
        return Control_Applicative.pure(dictApplicative)(v);
    };
}, function (dictApplicative) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictApplicative)(v1);
        };
    };
});
var traversableConj = new Traversable(function () {
    return Data_Foldable.foldableConj;
}, function () {
    return Data_Monoid_Conj.functorConj;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Conj.Conj)(v);
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Conj.Conj)(f(v));
        };
    };
});
var traversableCompose = function (dictTraversable) {
    return function (dictTraversable1) {
        return new Traversable(function () {
            return Data_Foldable.foldableCompose(dictTraversable.Foldable1())(dictTraversable1.Foldable1());
        }, function () {
            return Data_Functor_Compose.functorCompose(dictTraversable.Functor0())(dictTraversable1.Functor0());
        }, function (dictApplicative) {
            return traverse(traversableCompose(dictTraversable)(dictTraversable1))(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
        }, function (dictApplicative) {
            return function (f) {
                return function (v) {
                    return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Functor_Compose.Compose)(traverse(dictTraversable)(dictApplicative)(traverse(dictTraversable1)(dictApplicative)(f))(v));
                };
            };
        });
    };
};
var traversableAdditive = new Traversable(function () {
    return Data_Foldable.foldableAdditive;
}, function () {
    return Data_Monoid_Additive.functorAdditive;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Additive.Additive)(v);
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Monoid_Additive.Additive)(f(v));
        };
    };
});
var sequenceDefault = function (dictTraversable) {
    return function (dictApplicative) {
        return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
    };
};
var traversableArray = new Traversable(function () {
    return Data_Foldable.foldableArray;
}, function () {
    return Data_Functor.functorArray;
}, function (dictApplicative) {
    return sequenceDefault(traversableArray)(dictApplicative);
}, function (dictApplicative) {
    return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map((dictApplicative.Apply0()).Functor0()))(Control_Applicative.pure(dictApplicative));
});
var sequence = function (dict) {
    return dict.sequence;
};
var traversableApp = function (dictTraversable) {
    return new Traversable(function () {
        return Data_Foldable.foldableApp(dictTraversable.Foldable1());
    }, function () {
        return Data_Functor_App.functorApp(dictTraversable.Functor0());
    }, function (dictApplicative) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Functor_App.App)(sequence(dictTraversable)(dictApplicative)(v));
        };
    }, function (dictApplicative) {
        return function (f) {
            return function (v) {
                return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Functor_App.App)(traverse(dictTraversable)(dictApplicative)(f)(v));
            };
        };
    });
};
var traversableCoproduct = function (dictTraversable) {
    return function (dictTraversable1) {
        return new Traversable(function () {
            return Data_Foldable.foldableCoproduct(dictTraversable.Foldable1())(dictTraversable1.Foldable1());
        }, function () {
            return Data_Functor_Coproduct.functorCoproduct(dictTraversable.Functor0())(dictTraversable1.Functor0());
        }, function (dictApplicative) {
            return Data_Functor_Coproduct.coproduct((function () {
                var $141 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($144) {
                    return Data_Functor_Coproduct.Coproduct(Data_Either.Left.create($144));
                });
                var $142 = sequence(dictTraversable)(dictApplicative);
                return function ($143) {
                    return $141($142($143));
                };
            })())((function () {
                var $145 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($148) {
                    return Data_Functor_Coproduct.Coproduct(Data_Either.Right.create($148));
                });
                var $146 = sequence(dictTraversable1)(dictApplicative);
                return function ($147) {
                    return $145($146($147));
                };
            })());
        }, function (dictApplicative) {
            return function (f) {
                return Data_Functor_Coproduct.coproduct((function () {
                    var $149 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($152) {
                        return Data_Functor_Coproduct.Coproduct(Data_Either.Left.create($152));
                    });
                    var $150 = traverse(dictTraversable)(dictApplicative)(f);
                    return function ($151) {
                        return $149($150($151));
                    };
                })())((function () {
                    var $153 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($156) {
                        return Data_Functor_Coproduct.Coproduct(Data_Either.Right.create($156));
                    });
                    var $154 = traverse(dictTraversable1)(dictApplicative)(f);
                    return function ($155) {
                        return $153($154($155));
                    };
                })());
            };
        });
    };
};
var traversableFirst = new Traversable(function () {
    return Data_Foldable.foldableFirst;
}, function () {
    return Data_Maybe_First.functorFirst;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe_First.First)(sequence(traversableMaybe)(dictApplicative)(v));
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe_First.First)(traverse(traversableMaybe)(dictApplicative)(f)(v));
        };
    };
});
var traversableLast = new Traversable(function () {
    return Data_Foldable.foldableLast;
}, function () {
    return Data_Maybe_Last.functorLast;
}, function (dictApplicative) {
    return function (v) {
        return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe_Last.Last)(sequence(traversableMaybe)(dictApplicative)(v));
    };
}, function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe_Last.Last)(traverse(traversableMaybe)(dictApplicative)(f)(v));
        };
    };
});
var traversableProduct = function (dictTraversable) {
    return function (dictTraversable1) {
        return new Traversable(function () {
            return Data_Foldable.foldableProduct(dictTraversable.Foldable1())(dictTraversable1.Foldable1());
        }, function () {
            return Data_Functor_Product.functorProduct(dictTraversable.Functor0())(dictTraversable1.Functor0());
        }, function (dictApplicative) {
            return function (v) {
                return Control_Apply.lift2(dictApplicative.Apply0())(Data_Functor_Product.product)(sequence(dictTraversable)(dictApplicative)(v.value0))(sequence(dictTraversable1)(dictApplicative)(v.value1));
            };
        }, function (dictApplicative) {
            return function (f) {
                return function (v) {
                    return Control_Apply.lift2(dictApplicative.Apply0())(Data_Functor_Product.product)(traverse(dictTraversable)(dictApplicative)(f)(v.value0))(traverse(dictTraversable1)(dictApplicative)(f)(v.value1));
                };
            };
        });
    };
};
var traverseDefault = function (dictTraversable) {
    return function (dictApplicative) {
        return function (f) {
            return function (ta) {
                return sequence(dictTraversable)(dictApplicative)(Data_Functor.map(dictTraversable.Functor0())(f)(ta));
            };
        };
    };
};
var mapAccumR = function (dictTraversable) {
    return function (f) {
        return function (s0) {
            return function (xs) {
                return Data_Traversable_Accum_Internal.stateR(traverse(dictTraversable)(Data_Traversable_Accum_Internal.applicativeStateR)(function (a) {
                    return function (s) {
                        return f(s)(a);
                    };
                })(xs))(s0);
            };
        };
    };
};
var scanr = function (dictTraversable) {
    return function (f) {
        return function (b0) {
            return function (xs) {
                return (mapAccumR(dictTraversable)(function (b) {
                    return function (a) {
                        var b$prime = f(a)(b);
                        return {
                            accum: b$prime,
                            value: b$prime
                        };
                    };
                })(b0)(xs)).value;
            };
        };
    };
};
var mapAccumL = function (dictTraversable) {
    return function (f) {
        return function (s0) {
            return function (xs) {
                return Data_Traversable_Accum_Internal.stateL(traverse(dictTraversable)(Data_Traversable_Accum_Internal.applicativeStateL)(function (a) {
                    return function (s) {
                        return f(s)(a);
                    };
                })(xs))(s0);
            };
        };
    };
};
var scanl = function (dictTraversable) {
    return function (f) {
        return function (b0) {
            return function (xs) {
                return (mapAccumL(dictTraversable)(function (b) {
                    return function (a) {
                        var b$prime = f(b)(a);
                        return {
                            accum: b$prime,
                            value: b$prime
                        };
                    };
                })(b0)(xs)).value;
            };
        };
    };
};
var $$for = function (dictApplicative) {
    return function (dictTraversable) {
        return function (x) {
            return function (f) {
                return traverse(dictTraversable)(dictApplicative)(f)(x);
            };
        };
    };
};
module.exports = {
    Traversable: Traversable,
    traverse: traverse,
    sequence: sequence,
    traverseDefault: traverseDefault,
    sequenceDefault: sequenceDefault,
    "for": $$for,
    scanl: scanl,
    scanr: scanr,
    mapAccumL: mapAccumL,
    mapAccumR: mapAccumR,
    traversableArray: traversableArray,
    traversableMaybe: traversableMaybe,
    traversableFirst: traversableFirst,
    traversableLast: traversableLast,
    traversableAdditive: traversableAdditive,
    traversableDual: traversableDual,
    traversableConj: traversableConj,
    traversableDisj: traversableDisj,
    traversableMultiplicative: traversableMultiplicative,
    traversableEither: traversableEither,
    traversableTuple: traversableTuple,
    traversableIdentity: traversableIdentity,
    traversableConst: traversableConst,
    traversableProduct: traversableProduct,
    traversableCoproduct: traversableCoproduct,
    traversableCompose: traversableCompose,
    traversableApp: traversableApp,
    Foldable: Data_Foldable.Foldable,
    all: Data_Foldable.all,
    and: Data_Foldable.and,
    any: Data_Foldable.any,
    elem: Data_Foldable.elem,
    find: Data_Foldable.find,
    fold: Data_Foldable.fold,
    foldMap: Data_Foldable.foldMap,
    foldMapDefaultL: Data_Foldable.foldMapDefaultL,
    foldMapDefaultR: Data_Foldable.foldMapDefaultR,
    foldl: Data_Foldable.foldl,
    foldlDefault: Data_Foldable.foldlDefault,
    foldr: Data_Foldable.foldr,
    foldrDefault: Data_Foldable.foldrDefault,
    for_: Data_Foldable.for_,
    intercalate: Data_Foldable.intercalate,
    maximum: Data_Foldable.maximum,
    maximumBy: Data_Foldable.maximumBy,
    minimum: Data_Foldable.minimum,
    minimumBy: Data_Foldable.minimumBy,
    notElem: Data_Foldable.notElem,
    oneOf: Data_Foldable.oneOf,
    or: Data_Foldable.or,
    sequence_: Data_Foldable.sequence_,
    sum: Data_Foldable.sum,
    traverse_: Data_Foldable.traverse_
};
