// Generated by purs version 0.14.0
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Eager = (function () {
    function Eager() {

    };
    Eager.value = new Eager();
    return Eager;
})();
var Lazy = (function () {
    function Lazy() {

    };
    Lazy.value = new Lazy();
    return Lazy;
})();
var showDecodingHint = new Data_Show.Show(function (v) {
    if (v instanceof Eager) {
        return "Eager";
    };
    if (v instanceof Lazy) {
        return "Lazy";
    };
    throw new Error("Failed pattern match at Web.HTML.HTMLImageElement.Laziness (line 18, column 10 - line 20, column 19): " + [ v.constructor.name ]);
});
var print = function (v) {
    if (v instanceof Eager) {
        return "eager";
    };
    if (v instanceof Lazy) {
        return "lazy";
    };
    throw new Error("Failed pattern match at Web.HTML.HTMLImageElement.Laziness (line 31, column 9 - line 33, column 17): " + [ v.constructor.name ]);
};
var parse = function (v) {
    if (v === "") {
        return new Data_Maybe.Just(Eager.value);
    };
    if (v === "eager") {
        return new Data_Maybe.Just(Eager.value);
    };
    if (v === "lazy") {
        return new Data_Maybe.Just(Lazy.value);
    };
    return Data_Maybe.Nothing.value;
};
var eqDecodingHint = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Eager && y instanceof Eager) {
            return true;
        };
        if (x instanceof Lazy && y instanceof Lazy) {
            return true;
        };
        return false;
    };
});
var ordDecodingHint = new Data_Ord.Ord(function () {
    return eqDecodingHint;
}, function (x) {
    return function (y) {
        if (x instanceof Eager && y instanceof Eager) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Eager) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Eager) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Lazy && y instanceof Lazy) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Web.HTML.HTMLImageElement.Laziness (line 15, column 1 - line 15, column 48): " + [ x.constructor.name, y.constructor.name ]);
    };
});
module.exports = {
    Eager: Eager,
    Lazy: Lazy,
    parse: parse,
    print: print,
    eqDecodingHint: eqDecodingHint,
    ordDecodingHint: ordDecodingHint,
    showDecodingHint: showDecodingHint
};
