// Generated by purs version 0.14.0
"use strict";
var Data_Lazy = require("../Data.Lazy/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_Data_Slot = require("../Halogen.Data.Slot/index.js");
var Halogen_HTML = require("../Halogen.HTML/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_Query = require("../Halogen.Query/index.js");
module.exports = {
    defer: Data_Lazy.defer,
    componentSlot: Halogen_Component.componentSlot,
    defaultEval: Halogen_Component.defaultEval,
    hoist: Halogen_Component.hoist,
    mkComponent: Halogen_Component.mkComponent,
    mkEval: Halogen_Component.mkEval,
    unComponent: Halogen_Component.unComponent,
    unComponentSlot: Halogen_Component.unComponentSlot,
    AttrName: Halogen_HTML_Core.AttrName,
    ClassName: Halogen_HTML_Core.ClassName,
    ElemName: Halogen_HTML_Core.ElemName,
    Namespace: Halogen_HTML_Core.Namespace,
    PropName: Halogen_HTML_Core.PropName,
    ChildQuery: Halogen_Query.ChildQuery,
    Fork: Halogen_Query.Fork,
    GetRef: Halogen_Query.GetRef,
    Kill: Halogen_Query.Kill,
    Lift: Halogen_Query.Lift,
    Par: Halogen_Query.Par,
    Raise: Halogen_Query.Raise,
    State: Halogen_Query.State,
    Subscribe: Halogen_Query.Subscribe,
    Unsubscribe: Halogen_Query.Unsubscribe,
    HalogenM: Halogen_Query.HalogenM,
    Action: Halogen_Query.Action,
    Finalize: Halogen_Query.Finalize,
    Initialize: Halogen_Query.Initialize,
    Query: Halogen_Query.Query,
    Receive: Halogen_Query.Receive,
    RefLabel: Halogen_Query.RefLabel,
    fork: Halogen_Query.fork,
    get: Halogen_Query.get,
    getHTMLElementRef: Halogen_Query.getHTMLElementRef,
    getRef: Halogen_Query.getRef,
    gets: Halogen_Query.gets,
    kill: Halogen_Query.kill,
    lift: Halogen_Query.lift,
    liftAff: Halogen_Query.liftAff,
    liftEffect: Halogen_Query.liftEffect,
    mkRequest: Halogen_Query.mkRequest,
    mkTell: Halogen_Query.mkTell,
    modify: Halogen_Query.modify,
    modify_: Halogen_Query.modify_,
    put: Halogen_Query.put,
    query: Halogen_Query.query,
    queryAll: Halogen_Query.queryAll,
    raise: Halogen_Query.raise,
    request: Halogen_Query.request,
    subscribe: Halogen_Query.subscribe,
    "subscribe'": Halogen_Query["subscribe'"],
    tell: Halogen_Query.tell,
    unsubscribe: Halogen_Query.unsubscribe
};
