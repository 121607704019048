// Generated by purs version 0.14.0
"use strict";
var unload = "unload";
var toggle = "toggle";
var submit = "submit";
var storage = "storage";
var select = "select";
var reset = "reset";
var readystatechange = "readystatechange";
var progress = "progress";
var paste = "paste";
var online = "online";
var offline = "offline";
var message = "message";
var loadstart = "loadstart";
var loadend = "loadend";
var load = "load";
var languagechange = "languagechange";
var invalid = "invalid";
var input = "input";
var focus = "focus";
var error = "error";
var domcontentloaded = "DOMContentLoaded";
var cut = "cut";
var copy = "copy";
var close = "close";
var click = "click";
var change = "change";
var cancel = "cancel";
var blur = "blur";
var beforescriptexecute = "beforescriptexecute";
var beforeprint = "beforeprint";
var afterscriptexecute = "afterscriptexecute";
var afterprint = "afterprint";
var abort = "abort";
module.exports = {
    abort: abort,
    afterprint: afterprint,
    afterscriptexecute: afterscriptexecute,
    beforeprint: beforeprint,
    beforescriptexecute: beforescriptexecute,
    blur: blur,
    cancel: cancel,
    change: change,
    click: click,
    close: close,
    copy: copy,
    cut: cut,
    domcontentloaded: domcontentloaded,
    error: error,
    focus: focus,
    input: input,
    invalid: invalid,
    languagechange: languagechange,
    load: load,
    loadend: loadend,
    loadstart: loadstart,
    message: message,
    offline: offline,
    online: online,
    paste: paste,
    progress: progress,
    readystatechange: readystatechange,
    reset: reset,
    select: select,
    storage: storage,
    submit: submit,
    toggle: toggle,
    unload: unload
};
