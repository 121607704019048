// Generated by purs version 0.14.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Extend = require("../Control.Extend/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Data_Bifoldable = require("../Data.Bifoldable/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Bitraversable = require("../Data.Bitraversable/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var This = (function () {
    function This(value0) {
        this.value0 = value0;
    };
    This.create = function (value0) {
        return new This(value0);
    };
    return This;
})();
var That = (function () {
    function That(value0) {
        this.value0 = value0;
    };
    That.create = function (value0) {
        return new That(value0);
    };
    return That;
})();
var Both = (function () {
    function Both(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Both.create = function (value0) {
        return function (value1) {
            return new Both(value0, value1);
        };
    };
    return Both;
})();
var thisOrBoth = function (a) {
    return function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return new This(a);
        };
        if (v instanceof Data_Maybe.Just) {
            return new Both(a, v.value0);
        };
        throw new Error("Failed pattern match at Data.These (line 110, column 1 - line 110, column 52): " + [ a.constructor.name, v.constructor.name ]);
    };
};
var $$this = function (v) {
    if (v instanceof This) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var theseRight = function (v) {
    if (v instanceof Both) {
        return new Data_Maybe.Just(v.value1);
    };
    if (v instanceof That) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var theseLeft = function (v) {
    if (v instanceof Both) {
        return new Data_Maybe.Just(v.value0);
    };
    if (v instanceof This) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var these = function (v) {
    return function (v1) {
        return function (v2) {
            return function (v3) {
                if (v3 instanceof This) {
                    return v(v3.value0);
                };
                if (v3 instanceof That) {
                    return v1(v3.value0);
                };
                if (v3 instanceof Both) {
                    return v2(v3.value0)(v3.value1);
                };
                throw new Error("Failed pattern match at Data.These (line 105, column 1 - line 105, column 79): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name, v3.constructor.name ]);
            };
        };
    };
};
var thatOrBoth = function (b) {
    return function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return new That(b);
        };
        if (v instanceof Data_Maybe.Just) {
            return new Both(v.value0, b);
        };
        throw new Error("Failed pattern match at Data.These (line 114, column 1 - line 114, column 52): " + [ b.constructor.name, v.constructor.name ]);
    };
};
var that = function (v) {
    if (v instanceof That) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var swap = these(That.create)(This.create)(Data_Function.flip(Both.create));
var showThese = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (v) {
            if (v instanceof This) {
                return "(This " + (Data_Show.show(dictShow)(v.value0) + ")");
            };
            if (v instanceof That) {
                return "(That " + (Data_Show.show(dictShow1)(v.value0) + ")");
            };
            if (v instanceof Both) {
                return "(Both " + (Data_Show.show(dictShow)(v.value0) + (" " + (Data_Show.show(dictShow1)(v.value1) + ")")));
            };
            throw new Error("Failed pattern match at Data.These (line 98, column 1 - line 101, column 63): " + [ v.constructor.name ]);
        });
    };
};
var semigroupThese = function (dictSemigroup) {
    return function (dictSemigroup1) {
        return new Data_Semigroup.Semigroup(function (v) {
            return function (v1) {
                if (v instanceof This && v1 instanceof This) {
                    return new This(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0));
                };
                if (v instanceof This && v1 instanceof That) {
                    return new Both(v.value0, v1.value0);
                };
                if (v instanceof This && v1 instanceof Both) {
                    return new Both(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0), v1.value1);
                };
                if (v instanceof That && v1 instanceof This) {
                    return new Both(v1.value0, v.value0);
                };
                if (v instanceof That && v1 instanceof That) {
                    return new That(Data_Semigroup.append(dictSemigroup1)(v.value0)(v1.value0));
                };
                if (v instanceof That && v1 instanceof Both) {
                    return new Both(v1.value0, Data_Semigroup.append(dictSemigroup1)(v.value0)(v1.value1));
                };
                if (v instanceof Both && v1 instanceof This) {
                    return new Both(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0), v.value1);
                };
                if (v instanceof Both && v1 instanceof That) {
                    return new Both(v.value0, Data_Semigroup.append(dictSemigroup1)(v.value1)(v1.value0));
                };
                if (v instanceof Both && v1 instanceof Both) {
                    return new Both(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0), Data_Semigroup.append(dictSemigroup1)(v.value1)(v1.value1));
                };
                throw new Error("Failed pattern match at Data.These (line 23, column 1 - line 32, column 56): " + [ v.constructor.name, v1.constructor.name ]);
            };
        });
    };
};
var maybeThese = function (v) {
    return function (v1) {
        if (v instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Nothing) {
            return new Data_Maybe.Just(new This(v.value0));
        };
        if (v instanceof Data_Maybe.Nothing && v1 instanceof Data_Maybe.Just) {
            return new Data_Maybe.Just(new That(v1.value0));
        };
        if (v instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Just) {
            return new Data_Maybe.Just(new Both(v.value0, v1.value0));
        };
        if (v instanceof Data_Maybe.Nothing && v1 instanceof Data_Maybe.Nothing) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at Data.These (line 120, column 14 - line 124, column 30): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var isThis = function ($265) {
    return Data_Maybe.isJust($$this($265));
};
var isThat = function ($266) {
    return Data_Maybe.isJust(that($266));
};
var functorThese = new Data_Functor.Functor(function (v) {
    return function (v1) {
        if (v1 instanceof Both) {
            return new Both(v1.value0, v(v1.value1));
        };
        if (v1 instanceof That) {
            return new That(v(v1.value0));
        };
        if (v1 instanceof This) {
            return new This(v1.value0);
        };
        throw new Error("Failed pattern match at Data.These (line 34, column 1 - line 37, column 26): " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var invariantThese = new Data_Functor_Invariant.Invariant(Data_Functor_Invariant.imapF(functorThese));
var fromThese = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof This) {
                return new Data_Tuple.Tuple(v2.value0, v1);
            };
            if (v2 instanceof That) {
                return new Data_Tuple.Tuple(v, v2.value0);
            };
            if (v2 instanceof Both) {
                return new Data_Tuple.Tuple(v2.value0, v2.value1);
            };
            throw new Error("Failed pattern match at Data.These (line 130, column 1 - line 130, column 58): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var foldableThese = new Data_Foldable.Foldable(function (dictMonoid) {
    return function (f) {
        var $267 = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(dictMonoid)(f);
        return function ($268) {
            return $267(theseRight($268));
        };
    };
}, function (f) {
    return function (z) {
        var $269 = Data_Foldable.foldl(Data_Foldable.foldableMaybe)(f)(z);
        return function ($270) {
            return $269(theseRight($270));
        };
    };
}, function (f) {
    return function (z) {
        var $271 = Data_Foldable.foldr(Data_Foldable.foldableMaybe)(f)(z);
        return function ($272) {
            return $271(theseRight($272));
        };
    };
});
var traversableThese = new Data_Traversable.Traversable(function () {
    return foldableThese;
}, function () {
    return functorThese;
}, function (dictApplicative) {
    return function (v) {
        if (v instanceof This) {
            return Control_Applicative.pure(dictApplicative)(new This(v.value0));
        };
        if (v instanceof That) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(That.create)(v.value0);
        };
        if (v instanceof Both) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Both.create(v.value0))(v.value1);
        };
        throw new Error("Failed pattern match at Data.These (line 47, column 1 - line 53, column 37): " + [ v.constructor.name ]);
    };
}, function (dictApplicative) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof This) {
                return Control_Applicative.pure(dictApplicative)(new This(v1.value0));
            };
            if (v1 instanceof That) {
                return Data_Functor.map((dictApplicative.Apply0()).Functor0())(That.create)(v(v1.value0));
            };
            if (v1 instanceof Both) {
                return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Both.create(v1.value0))(v(v1.value1));
            };
            throw new Error("Failed pattern match at Data.These (line 47, column 1 - line 53, column 37): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
});
var extendEither = new Control_Extend.Extend(function () {
    return functorThese;
}, function (v) {
    return function (v1) {
        if (v1 instanceof This) {
            return new This(v1.value0);
        };
        return Data_Functor.map(functorThese)(Data_Function["const"](v(v1)))(v1);
    };
});
var eqThese = function (dictEq) {
    return function (dictEq1) {
        return new Data_Eq.Eq(function (x) {
            return function (y) {
                if (x instanceof This && y instanceof This) {
                    return Data_Eq.eq(dictEq)(x.value0)(y.value0);
                };
                if (x instanceof That && y instanceof That) {
                    return Data_Eq.eq(dictEq1)(x.value0)(y.value0);
                };
                if (x instanceof Both && y instanceof Both) {
                    return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq1)(x.value1)(y.value1);
                };
                return false;
            };
        });
    };
};
var ordThese = function (dictOrd) {
    return function (dictOrd1) {
        return new Data_Ord.Ord(function () {
            return eqThese(dictOrd.Eq0())(dictOrd1.Eq0());
        }, function (x) {
            return function (y) {
                if (x instanceof This && y instanceof This) {
                    return Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                };
                if (x instanceof This) {
                    return Data_Ordering.LT.value;
                };
                if (y instanceof This) {
                    return Data_Ordering.GT.value;
                };
                if (x instanceof That && y instanceof That) {
                    return Data_Ord.compare(dictOrd1)(x.value0)(y.value0);
                };
                if (x instanceof That) {
                    return Data_Ordering.LT.value;
                };
                if (y instanceof That) {
                    return Data_Ordering.GT.value;
                };
                if (x instanceof Both && y instanceof Both) {
                    var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                    if (v instanceof Data_Ordering.LT) {
                        return Data_Ordering.LT.value;
                    };
                    if (v instanceof Data_Ordering.GT) {
                        return Data_Ordering.GT.value;
                    };
                    return Data_Ord.compare(dictOrd1)(x.value1)(y.value1);
                };
                throw new Error("Failed pattern match at Data.These (line 21, column 1 - line 21, column 62): " + [ x.constructor.name, y.constructor.name ]);
            };
        });
    };
};
var both = function (v) {
    if (v instanceof Both) {
        return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
    };
    return Data_Maybe.Nothing.value;
};
var isBoth = function ($273) {
    return Data_Maybe.isJust(both($273));
};
var bifunctorThese = new Data_Bifunctor.Bifunctor(function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof This) {
                return new This(v(v2.value0));
            };
            if (v2 instanceof That) {
                return new That(v1(v2.value0));
            };
            if (v2 instanceof Both) {
                return new Both(v(v2.value0), v1(v2.value1));
            };
            throw new Error("Failed pattern match at Data.These (line 55, column 1 - line 58, column 42): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
});
var bifoldableThese = new Data_Bifoldable.Bifoldable(function (dictMonoid) {
    return function (f) {
        return function (g) {
            return these(f)(g)(function (x) {
                return function (y) {
                    return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(g(y));
                };
            });
        };
    };
}, function (f) {
    return function (g) {
        return function (z) {
            return these(function (v) {
                return f(z)(v);
            })(function (v) {
                return g(z)(v);
            })(function (x) {
                return function (y) {
                    return g(f(z)(x))(y);
                };
            });
        };
    };
}, function (f) {
    return function (g) {
        return function (z) {
            return these(function (v) {
                return f(v)(z);
            })(function (v) {
                return g(v)(z);
            })(function (x) {
                return function (y) {
                    return f(x)(g(y)(z));
                };
            });
        };
    };
});
var bitraversableThese = new Data_Bitraversable.Bitraversable(function () {
    return bifoldableThese;
}, function () {
    return bifunctorThese;
}, function (dictApplicative) {
    return Data_Bitraversable.bitraverse(bitraversableThese)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn));
}, function (dictApplicative) {
    return function (v) {
        return function (v1) {
            return function (v2) {
                if (v2 instanceof This) {
                    return Data_Functor.map((dictApplicative.Apply0()).Functor0())(This.create)(v(v2.value0));
                };
                if (v2 instanceof That) {
                    return Data_Functor.map((dictApplicative.Apply0()).Functor0())(That.create)(v1(v2.value0));
                };
                if (v2 instanceof Both) {
                    return Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(Both.create)(v(v2.value0)))(v1(v2.value1));
                };
                throw new Error("Failed pattern match at Data.These (line 65, column 1 - line 69, column 44): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
        };
    };
});
var assoc = function (v) {
    if (v instanceof This && v.value0 instanceof This) {
        return new This(v.value0.value0);
    };
    if (v instanceof This && v.value0 instanceof That) {
        return new That(new This(v.value0.value0));
    };
    if (v instanceof This && v.value0 instanceof Both) {
        return new Both(v.value0.value0, new This(v.value0.value1));
    };
    if (v instanceof That) {
        return new That(new That(v.value0));
    };
    if (v instanceof Both && v.value0 instanceof This) {
        return new Both(v.value0.value0, new That(v.value1));
    };
    if (v instanceof Both && v.value0 instanceof That) {
        return new That(new Both(v.value0.value0, v.value1));
    };
    if (v instanceof Both && v.value0 instanceof Both) {
        return new Both(v.value0.value0, new Both(v.value0.value1, v.value1));
    };
    throw new Error("Failed pattern match at Data.These (line 184, column 9 - line 191, column 41): " + [ v.constructor.name ]);
};
var applyThese = function (dictSemigroup) {
    return new Control_Apply.Apply(function () {
        return functorThese;
    }, function (v) {
        return function (v1) {
            if (v instanceof This) {
                return new This(v.value0);
            };
            if (v instanceof That && v1 instanceof This) {
                return new This(v1.value0);
            };
            if (v instanceof That && v1 instanceof That) {
                return new That(v.value0(v1.value0));
            };
            if (v instanceof That && v1 instanceof Both) {
                return new Both(v1.value0, v.value0(v1.value1));
            };
            if (v instanceof Both && v1 instanceof This) {
                return new This(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0));
            };
            if (v instanceof Both && v1 instanceof That) {
                return new Both(v.value0, v.value1(v1.value0));
            };
            if (v instanceof Both && v1 instanceof Both) {
                return new Both(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0), v.value1(v1.value1));
            };
            throw new Error("Failed pattern match at Data.These (line 71, column 1 - line 78, column 52): " + [ v.constructor.name, v1.constructor.name ]);
        };
    });
};
var bindThese = function (dictSemigroup) {
    return new Control_Bind.Bind(function () {
        return applyThese(dictSemigroup);
    }, function (v) {
        return function (v1) {
            if (v instanceof This) {
                return new This(v.value0);
            };
            if (v instanceof That) {
                return v1(v.value0);
            };
            if (v instanceof Both) {
                var v2 = v1(v.value1);
                if (v2 instanceof This) {
                    return new This(Data_Semigroup.append(dictSemigroup)(v.value0)(v2.value0));
                };
                if (v2 instanceof That) {
                    return new Both(v.value0, v2.value0);
                };
                if (v2 instanceof Both) {
                    return new Both(Data_Semigroup.append(dictSemigroup)(v.value0)(v2.value0), v2.value1);
                };
                throw new Error("Failed pattern match at Data.These (line 87, column 5 - line 90, column 34): " + [ v2.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.These (line 83, column 1 - line 90, column 34): " + [ v.constructor.name, v1.constructor.name ]);
        };
    });
};
var applicativeThese = function (dictSemigroup) {
    return new Control_Applicative.Applicative(function () {
        return applyThese(dictSemigroup);
    }, That.create);
};
var monadThese = function (dictSemigroup) {
    return new Control_Monad.Monad(function () {
        return applicativeThese(dictSemigroup);
    }, function () {
        return bindThese(dictSemigroup);
    });
};
module.exports = {
    This: This,
    That: That,
    Both: Both,
    these: these,
    thisOrBoth: thisOrBoth,
    thatOrBoth: thatOrBoth,
    maybeThese: maybeThese,
    fromThese: fromThese,
    theseLeft: theseLeft,
    theseRight: theseRight,
    "this": $$this,
    that: that,
    both: both,
    isThis: isThis,
    isThat: isThat,
    isBoth: isBoth,
    swap: swap,
    assoc: assoc,
    eqThese: eqThese,
    ordThese: ordThese,
    semigroupThese: semigroupThese,
    functorThese: functorThese,
    invariantThese: invariantThese,
    foldableThese: foldableThese,
    traversableThese: traversableThese,
    bifunctorThese: bifunctorThese,
    bifoldableThese: bifoldableThese,
    bitraversableThese: bitraversableThese,
    applyThese: applyThese,
    applicativeThese: applicativeThese,
    bindThese: bindThese,
    monadThese: monadThese,
    extendEither: extendEither,
    showThese: showThese
};
