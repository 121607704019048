// Generated by purs version 0.14.0
"use strict";
var CSS_Border = require("../CSS.Border/index.js");
var CSS_Common = require("../CSS.Common/index.js");
var CSS_Property = require("../CSS.Property/index.js");
var CSS_Size = require("../CSS.Size/index.js");
var CSS_String = require("../CSS.String/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var BoxType = function (x) {
    return x;
};
var valBoxType = new CSS_Property.Val(function (v) {
    return v;
});
var paddingBox = BoxType(CSS_String.fromString(CSS_Property.isStringValue)("padding-box"));
var isStringBoxType = new CSS_String.IsString((function () {
    var $14 = CSS_String.fromString(CSS_Property.isStringValue);
    return function ($15) {
        return BoxType($14($15));
    };
})());
var insetBoxShadow = function (x) {
    return function (y) {
        return function (w) {
            return function (c) {
                return function (z) {
                    return CSS_Stylesheet.prefixed(CSS_Property.valTuple(CSS_Border.valStroke)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valColor)))))(Data_Semigroup.append(CSS_Property.semigroupPrefixed)(CSS_Common.browsers)(CSS_String.fromString(CSS_Property.isStringPrefixed)("box-shadow")))(new Data_Tuple.Tuple(x, new Data_Tuple.Tuple(y, new Data_Tuple.Tuple(w, new Data_Tuple.Tuple(c, z)))));
                };
            };
        };
    };
};
var inheritBoxType = new CSS_Common.Inherit(CSS_String.fromString(isStringBoxType)("inherit"));
var eqBoxType = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordBoxType = new Data_Ord.Ord(function () {
    return eqBoxType;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var contentBox = BoxType(CSS_String.fromString(CSS_Property.isStringValue)("content-box"));
var boxSizing = CSS_Stylesheet.key(valBoxType)(CSS_String.fromString(CSS_Property.isStringKey)("box-sizing"));
var boxShadow = function (x) {
    return function (y) {
        return function (w) {
            return function (c) {
                return CSS_Stylesheet.prefixed(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Property.valColor))))(Data_Semigroup.append(CSS_Property.semigroupPrefixed)(CSS_Common.browsers)(CSS_String.fromString(CSS_Property.isStringPrefixed)("box-shadow")))(new Data_Tuple.Tuple(x, new Data_Tuple.Tuple(y, new Data_Tuple.Tuple(w, c))));
            };
        };
    };
};
var borderBox = BoxType(CSS_String.fromString(CSS_Property.isStringValue)("border-box"));
module.exports = {
    paddingBox: paddingBox,
    borderBox: borderBox,
    contentBox: contentBox,
    boxSizing: boxSizing,
    boxShadow: boxShadow,
    insetBoxShadow: insetBoxShadow,
    eqBoxType: eqBoxType,
    ordBoxType: ordBoxType,
    isStringBoxType: isStringBoxType,
    valBoxType: valBoxType,
    inheritBoxType: inheritBoxType
};
