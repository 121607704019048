"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var IsString = function (fromString, toString) {
    this.fromString = fromString;
    this.toString = toString;
};
var ArrowUp = (function () {
    function ArrowUp(value0) {
        this.value0 = value0;
    };
    ArrowUp.create = function (value0) {
        return new ArrowUp(value0);
    };
    return ArrowUp;
})();
var ArrowDown = (function () {
    function ArrowDown(value0) {
        this.value0 = value0;
    };
    ArrowDown.create = function (value0) {
        return new ArrowDown(value0);
    };
    return ArrowDown;
})();
var ArrowLeft = (function () {
    function ArrowLeft() {

    };
    ArrowLeft.value = new ArrowLeft();
    return ArrowLeft;
})();
var ArrowRight = (function () {
    function ArrowRight() {

    };
    ArrowRight.value = new ArrowRight();
    return ArrowRight;
})();
var toString = function (dict) {
    return dict.toString;
};
var showArrowDirection = new Data_Show.Show(function (v) {
    if (v instanceof ArrowUp) {
        return "ArrowUp " + Data_Show.show(Data_Show.showInt)(v.value0);
    };
    if (v instanceof ArrowDown) {
        return "ArrowDown " + Data_Show.show(Data_Show.showInt)(v.value0);
    };
    if (v instanceof ArrowLeft) {
        return "ArrowLeft";
    };
    if (v instanceof ArrowRight) {
        return "ArrowRight";
    };
    throw new Error("Failed pattern match at Types (line 45, column 10 - line 49, column 31): " + [ v.constructor.name ]);
});
var maximum$prime = function (arr) {
    var v = Data_Foldable.maximum(Data_Ord.ordNumber)(Data_Foldable.foldableArray)(arr);
    if (v instanceof Data_Maybe.Nothing) {
        return 0.0;
    };
    if (v instanceof Data_Maybe.Just) {
        return v.value0;
    };
    throw new Error("Failed pattern match at Types (line 34, column 16 - line 36, column 14): " + [ v.constructor.name ]);
};
var init = {
    debitNames: [ "\u501f\u65b9" ],
    creditNames: [ "\u8cb8\u65b9" ],
    pillarNames: [ "A\u793e" ],
    values: [ {
        debit: [ 10.0 ],
        credit: [ 10.0 ]
    } ]
};
var getMaxValueSingle = function (v) {
    return Data_Ord.max(Data_Ord.ordNumber)(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(v.debit))(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(v.credit));
};
var getMaxValue = function (pdata) {
    return maximum$prime(Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(pdata.values)(function (pvalues) {
        return getMaxValueSingle(pvalues);
    }));
};
var fromStringString = new IsString(function (x) {
    return new Data_Maybe.Just(x);
}, function (x) {
    return x;
});
var fromStringNumber = new IsString(function (x) {
    return Data_Number.fromString(x);
}, Data_Number_Format.toString);
var fromString = function (dict) {
    return dict.fromString;
};
module.exports = {
    fromString: fromString,
    toString: toString,
    init: init,
    getMaxValueSingle: getMaxValueSingle,
    getMaxValue: getMaxValue,
    "maximum'": maximum$prime,
    ArrowUp: ArrowUp,
    ArrowDown: ArrowDown,
    ArrowLeft: ArrowLeft,
    ArrowRight: ArrowRight,
    IsString: IsString,
    showArrowDirection: showArrowDirection,
    fromStringString: fromStringString,
    fromStringNumber: fromStringNumber
};
