"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var whenJust = function (dictMonadAff) {
    return function (a) {
        return function (f) {
            if (a instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(Data_Unit.unit);
            };
            if (a instanceof Data_Maybe.Just) {
                return f(a.value0);
            };
            throw new Error("Failed pattern match at Utils (line 9, column 16 - line 11, column 16): " + [ a.constructor.name ]);
        };
    };
};
var range = function (begin) {
    return function (end) {
        if (begin <= end) {
            return Data_Array.range(begin)(end);
        };
        if (Data_Boolean.otherwise) {
            return [  ];
        };
        throw new Error("Failed pattern match at Utils (line 13, column 1 - line 13, column 33): " + [ begin.constructor.name, end.constructor.name ]);
    };
};
module.exports = {
    whenJust: whenJust,
    range: range
};
