// Generated by purs version 0.14.0
"use strict";
var CSS_String = require("../CSS.String/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Id = (function () {
    function Id(value0) {
        this.value0 = value0;
    };
    Id.create = function (value0) {
        return new Id(value0);
    };
    return Id;
})();
var Class = (function () {
    function Class(value0) {
        this.value0 = value0;
    };
    Class.create = function (value0) {
        return new Class(value0);
    };
    return Class;
})();
var Attr = (function () {
    function Attr(value0) {
        this.value0 = value0;
    };
    Attr.create = function (value0) {
        return new Attr(value0);
    };
    return Attr;
})();
var AttrVal = (function () {
    function AttrVal(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrVal.create = function (value0) {
        return function (value1) {
            return new AttrVal(value0, value1);
        };
    };
    return AttrVal;
})();
var AttrBegins = (function () {
    function AttrBegins(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrBegins.create = function (value0) {
        return function (value1) {
            return new AttrBegins(value0, value1);
        };
    };
    return AttrBegins;
})();
var AttrEnds = (function () {
    function AttrEnds(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrEnds.create = function (value0) {
        return function (value1) {
            return new AttrEnds(value0, value1);
        };
    };
    return AttrEnds;
})();
var AttrContains = (function () {
    function AttrContains(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrContains.create = function (value0) {
        return function (value1) {
            return new AttrContains(value0, value1);
        };
    };
    return AttrContains;
})();
var AttrSpace = (function () {
    function AttrSpace(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrSpace.create = function (value0) {
        return function (value1) {
            return new AttrSpace(value0, value1);
        };
    };
    return AttrSpace;
})();
var AttrHyph = (function () {
    function AttrHyph(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrHyph.create = function (value0) {
        return function (value1) {
            return new AttrHyph(value0, value1);
        };
    };
    return AttrHyph;
})();
var Pseudo = (function () {
    function Pseudo(value0) {
        this.value0 = value0;
    };
    Pseudo.create = function (value0) {
        return new Pseudo(value0);
    };
    return Pseudo;
})();
var PseudoFunc = (function () {
    function PseudoFunc(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PseudoFunc.create = function (value0) {
        return function (value1) {
            return new PseudoFunc(value0, value1);
        };
    };
    return PseudoFunc;
})();
var Refinement = function (x) {
    return x;
};
var Star = (function () {
    function Star() {

    };
    Star.value = new Star();
    return Star;
})();
var Elem = (function () {
    function Elem(value0) {
        this.value0 = value0;
    };
    Elem.create = function (value0) {
        return new Elem(value0);
    };
    return Elem;
})();
var PathChild = (function () {
    function PathChild(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PathChild.create = function (value0) {
        return function (value1) {
            return new PathChild(value0, value1);
        };
    };
    return PathChild;
})();
var Deep = (function () {
    function Deep(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Deep.create = function (value0) {
        return function (value1) {
            return new Deep(value0, value1);
        };
    };
    return Deep;
})();
var Adjacent = (function () {
    function Adjacent(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Adjacent.create = function (value0) {
        return function (value1) {
            return new Adjacent(value0, value1);
        };
    };
    return Adjacent;
})();
var Combined = (function () {
    function Combined(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Combined.create = function (value0) {
        return function (value1) {
            return new Combined(value0, value1);
        };
    };
    return Combined;
})();
var Selector = (function () {
    function Selector(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Selector.create = function (value0) {
        return function (value1) {
            return new Selector(value0, value1);
        };
    };
    return Selector;
})();
var $$with = function (v) {
    return function (v1) {
        return new Selector(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value0)(v1), v.value1);
    };
};
var star = new Selector([  ], Star.value);
var pseudo = (function () {
    var $364 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    return function ($365) {
        return Refinement($364(Pseudo.create($365)));
    };
})();
var isStringRefinement = new CSS_String.IsString(function (s) {
    return [ (function () {
        var v = Data_String_CodePoints.take(1)(s);
        if (v === "#") {
            return Id.create(Data_String_CodePoints.drop(1)(s));
        };
        if (v === ".") {
            return Class.create(Data_String_CodePoints.drop(1)(s));
        };
        if (v === ":") {
            return Pseudo.create(Data_String_CodePoints.drop(1)(s));
        };
        if (v === "@") {
            return Attr.create(Data_String_CodePoints.drop(1)(s));
        };
        return new Attr(s);
    })() ];
});
var func = function (f) {
    var $366 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    var $367 = PseudoFunc.create(f);
    return function ($368) {
        return Refinement($366($367($368)));
    };
};
var eqPredicate = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Id && y instanceof Id) {
            return x.value0 === y.value0;
        };
        if (x instanceof Class && y instanceof Class) {
            return x.value0 === y.value0;
        };
        if (x instanceof Attr && y instanceof Attr) {
            return x.value0 === y.value0;
        };
        if (x instanceof AttrVal && y instanceof AttrVal) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrBegins && y instanceof AttrBegins) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrEnds && y instanceof AttrEnds) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrContains && y instanceof AttrContains) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrSpace && y instanceof AttrSpace) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrHyph && y instanceof AttrHyph) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof Pseudo && y instanceof Pseudo) {
            return x.value0 === y.value0;
        };
        if (x instanceof PseudoFunc && y instanceof PseudoFunc) {
            return x.value0 === y.value0 && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value1)(y.value1);
        };
        return false;
    };
});
var eqRefinement = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_Eq.eqArray(eqPredicate))(x)(y);
    };
});
var ordPredicate = new Data_Ord.Ord(function () {
    return eqPredicate;
}, function (x) {
    return function (y) {
        if (x instanceof Id && y instanceof Id) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof Id) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Id) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Class && y instanceof Class) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof Class) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Class) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Attr && y instanceof Attr) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof Attr) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Attr) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrVal && y instanceof AttrVal) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrVal) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrVal) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrBegins && y instanceof AttrBegins) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrBegins) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrBegins) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrEnds && y instanceof AttrEnds) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrEnds) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrEnds) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrContains && y instanceof AttrContains) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrContains) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrContains) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrSpace && y instanceof AttrSpace) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrSpace) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrSpace) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrHyph && y instanceof AttrHyph) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrHyph) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrHyph) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Pseudo && y instanceof Pseudo) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof Pseudo) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Pseudo) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof PseudoFunc && y instanceof PseudoFunc) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordArray(Data_Ord.ordString))(x.value1)(y.value1);
        };
        throw new Error("Failed pattern match at CSS.Selector (line 23, column 1 - line 23, column 46): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var ordRefinement = new Data_Ord.Ord(function () {
    return eqRefinement;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordArray(ordPredicate))(x)(y);
    };
});
var eqPath = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            if (x instanceof Star && y instanceof Star) {
                return true;
            };
            if (x instanceof Elem && y instanceof Elem) {
                return x.value0 === y.value0;
            };
            if (x instanceof PathChild && y instanceof PathChild) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            if (x instanceof Deep && y instanceof Deep) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            if (x instanceof Adjacent && y instanceof Adjacent) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            if (x instanceof Combined && y instanceof Combined) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            return false;
        };
    });
};
var eqSelector = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(eqRefinement)(x.value0)(y.value0) && Data_Eq.eq(eqPath(eqSelector))(x.value1)(y.value1);
    };
});
var ordPath = function (dictOrd) {
    return new Data_Ord.Ord(function () {
        return eqPath(dictOrd.Eq0());
    }, function (x) {
        return function (y) {
            if (x instanceof Star && y instanceof Star) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Star) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Star) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Elem && y instanceof Elem) {
                return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            };
            if (x instanceof Elem) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Elem) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof PathChild && y instanceof PathChild) {
                var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(dictOrd)(x.value1)(y.value1);
            };
            if (x instanceof PathChild) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof PathChild) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Deep && y instanceof Deep) {
                var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(dictOrd)(x.value1)(y.value1);
            };
            if (x instanceof Deep) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Deep) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Adjacent && y instanceof Adjacent) {
                var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(dictOrd)(x.value1)(y.value1);
            };
            if (x instanceof Adjacent) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Adjacent) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Combined && y instanceof Combined) {
                var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(dictOrd)(x.value1)(y.value1);
            };
            throw new Error("Failed pattern match at CSS.Selector (line 50, column 1 - line 50, column 51): " + [ x.constructor.name, y.constructor.name ]);
        };
    });
};
var ordSelector = new Data_Ord.Ord(function () {
    return eqSelector;
}, function (x) {
    return function (y) {
        var v = Data_Ord.compare(ordRefinement)(x.value0)(y.value0);
        if (v instanceof Data_Ordering.LT) {
            return Data_Ordering.LT.value;
        };
        if (v instanceof Data_Ordering.GT) {
            return Data_Ordering.GT.value;
        };
        return Data_Ord.compare(ordPath(ordSelector))(x.value1)(y.value1);
    };
});
var element = function (e) {
    return new Selector([  ], new Elem(e));
};
var deep = function (a) {
    return function (b) {
        return new Selector([  ], new Deep(a, b));
    };
};
var child = function (a) {
    return function (b) {
        return new Selector([  ], new PathChild(a, b));
    };
};
var byId = (function () {
    var $369 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    return function ($370) {
        return Refinement($369(Id.create($370)));
    };
})();
var byClass = (function () {
    var $371 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    return function ($372) {
        return Refinement($371(Class.create($372)));
    };
})();
var isStringSelector = new CSS_String.IsString(function (s) {
    var v = Data_String_CodePoints.take(1)(s);
    if (v === "#") {
        return new Selector(byId(Data_String_CodePoints.drop(1)(s)), Star.value);
    };
    if (v === ".") {
        return new Selector(byClass(Data_String_CodePoints.drop(1)(s)), Star.value);
    };
    return new Selector([  ], new Elem(s));
});
var attrVal = function (a) {
    var $373 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    var $374 = AttrVal.create(a);
    return function ($375) {
        return Refinement($373($374($375)));
    };
};
var attrSpace = function (a) {
    var $376 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    var $377 = AttrSpace.create(a);
    return function ($378) {
        return Refinement($376($377($378)));
    };
};
var attrHyph = function (a) {
    var $379 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    var $380 = AttrHyph.create(a);
    return function ($381) {
        return Refinement($379($380($381)));
    };
};
var attrEnds = function (a) {
    var $382 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    var $383 = AttrEnds.create(a);
    return function ($384) {
        return Refinement($382($383($384)));
    };
};
var attrContains = function (a) {
    var $385 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    var $386 = AttrContains.create(a);
    return function ($387) {
        return Refinement($385($386($387)));
    };
};
var attrBegins = function (a) {
    var $388 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    var $389 = AttrBegins.create(a);
    return function ($390) {
        return Refinement($388($389($390)));
    };
};
var attr = (function () {
    var $391 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    return function ($392) {
        return Refinement($391(Attr.create($392)));
    };
})();
var adjacent = function (a) {
    return function (b) {
        return new Selector([  ], new Adjacent(a, b));
    };
};
module.exports = {
    Id: Id,
    Class: Class,
    Attr: Attr,
    AttrVal: AttrVal,
    AttrBegins: AttrBegins,
    AttrEnds: AttrEnds,
    AttrContains: AttrContains,
    AttrSpace: AttrSpace,
    AttrHyph: AttrHyph,
    Pseudo: Pseudo,
    PseudoFunc: PseudoFunc,
    Refinement: Refinement,
    Star: Star,
    Elem: Elem,
    PathChild: PathChild,
    Deep: Deep,
    Adjacent: Adjacent,
    Combined: Combined,
    Selector: Selector,
    star: star,
    element: element,
    deep: deep,
    child: child,
    adjacent: adjacent,
    "with": $$with,
    byId: byId,
    byClass: byClass,
    pseudo: pseudo,
    func: func,
    attr: attr,
    attrVal: attrVal,
    attrBegins: attrBegins,
    attrEnds: attrEnds,
    attrContains: attrContains,
    attrSpace: attrSpace,
    attrHyph: attrHyph,
    eqPredicate: eqPredicate,
    ordPredicate: ordPredicate,
    eqRefinement: eqRefinement,
    ordRefinement: ordRefinement,
    isStringRefinement: isStringRefinement,
    eqPath: eqPath,
    ordPath: ordPath,
    eqSelector: eqSelector,
    ordSelector: ordSelector,
    isStringSelector: isStringSelector
};
