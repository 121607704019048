// Generated by purs version 0.14.0
"use strict";
var CSS_Property = require("../CSS.Property/index.js");
var CSS_String = require("../CSS.String/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Visible = function (visible) {
    this.visible = visible;
};
var Unset = function (unset) {
    this.unset = unset;
};
var URL = function (url) {
    this.url = url;
};
var Top = function (top) {
    this.top = top;
};
var Other = function (other) {
    this.other = other;
};
var Normal = function (normal) {
    this.normal = normal;
};
var None = function (none) {
    this.none = none;
};
var Middle = function (middle) {
    this.middle = middle;
};
var Initial = function (initial) {
    this.initial = initial;
};
var Inherit = function (inherit) {
    this.inherit = inherit;
};
var Hidden = function (hidden) {
    this.hidden = hidden;
};
var Center = function (center) {
    this.center = center;
};
var Bottom = function (bottom) {
    this.bottom = bottom;
};
var Baseline = function (baseline) {
    this.baseline = baseline;
};
var Auto = function (auto) {
    this.auto = auto;
};
var All = function (all) {
    this.all = all;
};
var visibleValue = new Visible(CSS_String.fromString(CSS_Property.isStringValue)("visible"));
var visible = function (dict) {
    return dict.visible;
};
var urlValue = new URL(function (s) {
    return CSS_String.fromString(CSS_Property.isStringValue)("url(\"" + (s + "\")"));
});
var url = function (dict) {
    return dict.url;
};
var unsetValue = new Unset(CSS_String.fromString(CSS_Property.isStringValue)("unset"));
var unset = function (dict) {
    return dict.unset;
};
var topValue = new Top(CSS_String.fromString(CSS_Property.isStringValue)("top"));
var top = function (dict) {
    return dict.top;
};
var otherValue = new Other(Control_Category.identity(Control_Category.categoryFn));
var other = function (dict) {
    return dict.other;
};
var normalValue = new Normal(CSS_String.fromString(CSS_Property.isStringValue)("normal"));
var normal = function (dict) {
    return dict.normal;
};
var noneValue = new None(CSS_String.fromString(CSS_Property.isStringValue)("none"));
var none = function (dict) {
    return dict.none;
};
var middleValue = new Middle(CSS_String.fromString(CSS_Property.isStringValue)("middle"));
var middle = function (dict) {
    return dict.middle;
};
var initialValue = new Initial(CSS_String.fromString(CSS_Property.isStringValue)("initial"));
var initial = function (dict) {
    return dict.initial;
};
var inheritValue = new Inherit(CSS_String.fromString(CSS_Property.isStringValue)("inherit"));
var inherit = function (dict) {
    return dict.inherit;
};
var hiddenValue = new Hidden(CSS_String.fromString(CSS_Property.isStringValue)("hidden"));
var hidden = function (dict) {
    return dict.hidden;
};
var centerValue = new Center(CSS_String.fromString(CSS_Property.isStringValue)("center"));
var center = function (dict) {
    return dict.center;
};
var call = function (dictIsString) {
    return function (dictMonoid) {
        return function (fn) {
            return function (arg) {
                return Data_Semigroup.append(dictMonoid.Semigroup0())(fn)(Data_Semigroup.append(dictMonoid.Semigroup0())(CSS_String.fromString(dictIsString)("("))(Data_Semigroup.append(dictMonoid.Semigroup0())(arg)(CSS_String.fromString(dictIsString)(")"))));
            };
        };
    };
};
var browsers = new CSS_Property.Prefixed([ new Data_Tuple.Tuple("-webkit-", ""), new Data_Tuple.Tuple("-moz-", ""), new Data_Tuple.Tuple("-ms-", ""), new Data_Tuple.Tuple("-o-", ""), new Data_Tuple.Tuple("", "") ]);
var bottomValue = new Bottom(CSS_String.fromString(CSS_Property.isStringValue)("bottom"));
var bottom = function (dict) {
    return dict.bottom;
};
var baselineValue = new Baseline(CSS_String.fromString(CSS_Property.isStringValue)("baseline"));
var baseline = function (dict) {
    return dict.baseline;
};
var autoValue = new Auto(CSS_String.fromString(CSS_Property.isStringValue)("auto"));
var auto = function (dict) {
    return dict.auto;
};
var allValue = new All(CSS_String.fromString(CSS_Property.isStringValue)("all"));
var all = function (dict) {
    return dict.all;
};
module.exports = {
    all: all,
    auto: auto,
    baseline: baseline,
    bottom: bottom,
    center: center,
    hidden: hidden,
    inherit: inherit,
    initial: initial,
    middle: middle,
    none: none,
    normal: normal,
    other: other,
    top: top,
    unset: unset,
    url: url,
    visible: visible,
    All: All,
    Auto: Auto,
    Baseline: Baseline,
    Center: Center,
    Inherit: Inherit,
    None: None,
    Normal: Normal,
    Visible: Visible,
    Hidden: Hidden,
    Initial: Initial,
    Unset: Unset,
    Top: Top,
    Middle: Middle,
    Bottom: Bottom,
    URL: URL,
    Other: Other,
    browsers: browsers,
    call: call,
    allValue: allValue,
    autoValue: autoValue,
    baselineValue: baselineValue,
    centerValue: centerValue,
    inheritValue: inheritValue,
    normalValue: normalValue,
    noneValue: noneValue,
    visibleValue: visibleValue,
    hiddenValue: hiddenValue,
    otherValue: otherValue,
    initialValue: initialValue,
    unsetValue: unsetValue,
    topValue: topValue,
    middleValue: middleValue,
    bottomValue: bottomValue,
    urlValue: urlValue
};
