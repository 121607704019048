// Generated by purs version 0.14.0
"use strict";
var CSS_Property = require("../CSS.Property/index.js");
var CSS_Selector = require("../CSS.Selector/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Monad_Writer = require("../Control.Monad.Writer/index.js");
var Control_Monad_Writer_Class = require("../Control.Monad.Writer.Class/index.js");
var Control_Monad_Writer_Trans = require("../Control.Monad.Writer.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Not = (function () {
    function Not() {

    };
    Not.value = new Not();
    return Not;
})();
var Only = (function () {
    function Only() {

    };
    Only.value = new Only();
    return Only;
})();
var MediaType = function (x) {
    return x;
};
var Feature = (function () {
    function Feature(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Feature.create = function (value0) {
        return function (value1) {
            return new Feature(value0, value1);
        };
    };
    return Feature;
})();
var MediaQuery = (function () {
    function MediaQuery(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    MediaQuery.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new MediaQuery(value0, value1, value2);
            };
        };
    };
    return MediaQuery;
})();
var Self = (function () {
    function Self(value0) {
        this.value0 = value0;
    };
    Self.create = function (value0) {
        return new Self(value0);
    };
    return Self;
})();
var Root = (function () {
    function Root(value0) {
        this.value0 = value0;
    };
    Root.create = function (value0) {
        return new Root(value0);
    };
    return Root;
})();
var Pop = (function () {
    function Pop(value0) {
        this.value0 = value0;
    };
    Pop.create = function (value0) {
        return new Pop(value0);
    };
    return Pop;
})();
var Child = (function () {
    function Child(value0) {
        this.value0 = value0;
    };
    Child.create = function (value0) {
        return new Child(value0);
    };
    return Child;
})();
var Sub = (function () {
    function Sub(value0) {
        this.value0 = value0;
    };
    Sub.create = function (value0) {
        return new Sub(value0);
    };
    return Sub;
})();
var Property = (function () {
    function Property(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Property.create = function (value0) {
        return function (value1) {
            return new Property(value0, value1);
        };
    };
    return Property;
})();
var Nested = (function () {
    function Nested(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Nested.create = function (value0) {
        return function (value1) {
            return new Nested(value0, value1);
        };
    };
    return Nested;
})();
var Query = (function () {
    function Query(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Query.create = function (value0) {
        return function (value1) {
            return new Query(value0, value1);
        };
    };
    return Query;
})();
var Face = (function () {
    function Face(value0) {
        this.value0 = value0;
    };
    Face.create = function (value0) {
        return new Face(value0);
    };
    return Face;
})();
var Keyframe = (function () {
    function Keyframe(value0) {
        this.value0 = value0;
    };
    Keyframe.create = function (value0) {
        return new Keyframe(value0);
    };
    return Keyframe;
})();
var Import = (function () {
    function Import(value0) {
        this.value0 = value0;
    };
    Import.create = function (value0) {
        return new Import(value0);
    };
    return Import;
})();
var Keyframes = (function () {
    function Keyframes(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Keyframes.create = function (value0) {
        return function (value1) {
            return new Keyframes(value0, value1);
        };
    };
    return Keyframes;
})();
var S = function (x) {
    return x;
};
var runS = function (v) {
    return Control_Monad_Writer.execWriter(v);
};
var rule = (function () {
    var $289 = Control_Monad_Writer_Class.tell(Control_Monad_Writer_Trans.monadTellWriterT(Data_Monoid.monoidArray)(Data_Identity.monadIdentity));
    return function ($290) {
        return S($289(Data_Array.singleton($290)));
    };
})();
var select = function (sel) {
    return function (rs) {
        return rule(new Nested(new Sub(sel), runS(rs)));
    };
};
var query = function (ty) {
    return function (fs) {
        var $291 = Query.create(new MediaQuery(Data_Maybe.Nothing.value, ty, fs));
        return function ($292) {
            return rule($291(runS($292)));
        };
    };
};
var keyframes = function (n) {
    return function (xs) {
        return rule(new Keyframe(new Keyframes(n, Data_Functor.map(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(Data_Profunctor_Strong.second(Data_Profunctor_Strong.strongFn)(runS))(xs))));
    };
};
var keyframesFromTo = function (n) {
    return function (a) {
        return function (b) {
            return keyframes(n)(new Data_NonEmpty.NonEmpty(new Data_Tuple.Tuple(0.0, a), [ new Data_Tuple.Tuple(100.0, b) ]));
        };
    };
};
var key = function (dictVal) {
    return function (k) {
        return function (v) {
            return rule(new Property(CSS_Property.cast(k), CSS_Property.value(dictVal)(v)));
        };
    };
};
var prefixed = function (dictVal) {
    return function (xs) {
        return key(dictVal)(xs);
    };
};
var importUrl = function ($293) {
    return rule(Import.create($293));
};
var functorStyleM = new Data_Functor.Functor(function (f) {
    return function (v) {
        return S(Data_Functor.map(Control_Monad_Writer_Trans.functorWriterT(Data_Identity.functorIdentity))(f)(v));
    };
});
var fontFace = function ($294) {
    return rule(Face.create(runS($294)));
};
var eqNotOrOnly = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Not && y instanceof Not) {
            return true;
        };
        if (x instanceof Only && y instanceof Only) {
            return true;
        };
        return false;
    };
});
var ordNotOrOnly = new Data_Ord.Ord(function () {
    return eqNotOrOnly;
}, function (x) {
    return function (y) {
        if (x instanceof Not && y instanceof Not) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Not) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Not) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Only && y instanceof Only) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at CSS.Stylesheet (line 25, column 1 - line 25, column 45): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var eqMediaType = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordMediaType = new Data_Ord.Ord(function () {
    return eqMediaType;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var eqFeature = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x.value0 === y.value0 && Data_Eq.eq(Data_Maybe.eqMaybe(CSS_Property.eqValue))(x.value1)(y.value1);
    };
});
var eqMediaQuery = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_Maybe.eqMaybe(eqNotOrOnly))(x.value0)(y.value0) && Data_Eq.eq(eqMediaType)(x.value1)(y.value1) && Data_Eq.eq(Data_NonEmpty.eqNonEmpty(Data_Eq.eq1Array)(eqFeature))(x.value2)(y.value2);
    };
});
var ordFeature = new Data_Ord.Ord(function () {
    return eqFeature;
}, function (x) {
    return function (y) {
        var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        if (v instanceof Data_Ordering.LT) {
            return Data_Ordering.LT.value;
        };
        if (v instanceof Data_Ordering.GT) {
            return Data_Ordering.GT.value;
        };
        return Data_Ord.compare(Data_Maybe.ordMaybe(CSS_Property.ordValue))(x.value1)(y.value1);
    };
});
var ordMediaQuery = new Data_Ord.Ord(function () {
    return eqMediaQuery;
}, function (x) {
    return function (y) {
        var v = Data_Ord.compare(Data_Maybe.ordMaybe(ordNotOrOnly))(x.value0)(y.value0);
        if (v instanceof Data_Ordering.LT) {
            return Data_Ordering.LT.value;
        };
        if (v instanceof Data_Ordering.GT) {
            return Data_Ordering.GT.value;
        };
        var v1 = Data_Ord.compare(ordMediaType)(x.value1)(y.value1);
        if (v1 instanceof Data_Ordering.LT) {
            return Data_Ordering.LT.value;
        };
        if (v1 instanceof Data_Ordering.GT) {
            return Data_Ordering.GT.value;
        };
        return Data_Ord.compare(Data_NonEmpty.ordNonEmpty(Data_Ord.ord1Array)(ordFeature))(x.value2)(y.value2);
    };
});
var eqApp = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Self && y instanceof Self) {
            return Data_Eq.eq(CSS_Selector.eqRefinement)(x.value0)(y.value0);
        };
        if (x instanceof Root && y instanceof Root) {
            return Data_Eq.eq(CSS_Selector.eqSelector)(x.value0)(y.value0);
        };
        if (x instanceof Pop && y instanceof Pop) {
            return x.value0 === y.value0;
        };
        if (x instanceof Child && y instanceof Child) {
            return Data_Eq.eq(CSS_Selector.eqSelector)(x.value0)(y.value0);
        };
        if (x instanceof Sub && y instanceof Sub) {
            return Data_Eq.eq(CSS_Selector.eqSelector)(x.value0)(y.value0);
        };
        return false;
    };
});
var eqRule = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Property && y instanceof Property) {
            return Data_Eq.eq(CSS_Property.eqKey(Data_Eq.eqUnit))(x.value0)(y.value0) && Data_Eq.eq(CSS_Property.eqValue)(x.value1)(y.value1);
        };
        if (x instanceof Nested && y instanceof Nested) {
            return Data_Eq.eq(eqApp)(x.value0)(y.value0) && Data_Eq.eq(Data_Eq.eqArray(eqRule))(x.value1)(y.value1);
        };
        if (x instanceof Query && y instanceof Query) {
            return Data_Eq.eq(eqMediaQuery)(x.value0)(y.value0) && Data_Eq.eq(Data_Eq.eqArray(eqRule))(x.value1)(y.value1);
        };
        if (x instanceof Face && y instanceof Face) {
            return Data_Eq.eq(Data_Eq.eqArray(eqRule))(x.value0)(y.value0);
        };
        if (x instanceof Keyframe && y instanceof Keyframe) {
            return Data_Eq.eq(eqKeyframes)(x.value0)(y.value0);
        };
        if (x instanceof Import && y instanceof Import) {
            return x.value0 === y.value0;
        };
        return false;
    };
});
var eqKeyframes = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x.value0 === y.value0 && Data_Eq.eq(Data_NonEmpty.eqNonEmpty(Data_Eq.eq1Array)(Data_Tuple.eqTuple(Data_Eq.eqNumber)(Data_Eq.eqArray(eqRule))))(x.value1)(y.value1);
    };
});
var ordApp = new Data_Ord.Ord(function () {
    return eqApp;
}, function (x) {
    return function (y) {
        if (x instanceof Self && y instanceof Self) {
            return Data_Ord.compare(CSS_Selector.ordRefinement)(x.value0)(y.value0);
        };
        if (x instanceof Self) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Self) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Root && y instanceof Root) {
            return Data_Ord.compare(CSS_Selector.ordSelector)(x.value0)(y.value0);
        };
        if (x instanceof Root) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Root) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Pop && y instanceof Pop) {
            return Data_Ord.compare(Data_Ord.ordInt)(x.value0)(y.value0);
        };
        if (x instanceof Pop) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Pop) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Child && y instanceof Child) {
            return Data_Ord.compare(CSS_Selector.ordSelector)(x.value0)(y.value0);
        };
        if (x instanceof Child) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Child) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Sub && y instanceof Sub) {
            return Data_Ord.compare(CSS_Selector.ordSelector)(x.value0)(y.value0);
        };
        throw new Error("Failed pattern match at CSS.Stylesheet (line 45, column 1 - line 45, column 34): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var ordRule = new Data_Ord.Ord(function () {
    return eqRule;
}, function (x) {
    return function (y) {
        if (x instanceof Property && y instanceof Property) {
            var v = Data_Ord.compare(CSS_Property.ordKey(Data_Ord.ordUnit))(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(CSS_Property.ordValue)(x.value1)(y.value1);
        };
        if (x instanceof Property) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Property) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Nested && y instanceof Nested) {
            var v = Data_Ord.compare(ordApp)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordArray(ordRule))(x.value1)(y.value1);
        };
        if (x instanceof Nested) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Nested) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Query && y instanceof Query) {
            var v = Data_Ord.compare(ordMediaQuery)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordArray(ordRule))(x.value1)(y.value1);
        };
        if (x instanceof Query) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Query) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Face && y instanceof Face) {
            return Data_Ord.compare(Data_Ord.ordArray(ordRule))(x.value0)(y.value0);
        };
        if (x instanceof Face) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Face) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Keyframe && y instanceof Keyframe) {
            return Data_Ord.compare(ordKeyframes)(x.value0)(y.value0);
        };
        if (x instanceof Keyframe) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Keyframe) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Import && y instanceof Import) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        throw new Error("Failed pattern match at CSS.Stylesheet (line 61, column 1 - line 61, column 36): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var ordKeyframes = new Data_Ord.Ord(function () {
    return eqKeyframes;
}, function (x) {
    return function (y) {
        var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        if (v instanceof Data_Ordering.LT) {
            return Data_Ordering.LT.value;
        };
        if (v instanceof Data_Ordering.GT) {
            return Data_Ordering.GT.value;
        };
        return Data_Ord.compare(Data_NonEmpty.ordNonEmpty(Data_Ord.ord1Array)(Data_Tuple.ordTuple(Data_Ord.ordNumber)(Data_Ord.ordArray(ordRule))))(x.value1)(y.value1);
    };
});
var applyStyleM = new Control_Apply.Apply(function () {
    return functorStyleM;
}, function (v) {
    return function (v1) {
        return S(Control_Apply.apply(Control_Monad_Writer_Trans.applyWriterT(Data_Semigroup.semigroupArray)(Data_Identity.applyIdentity))(v)(v1));
    };
});
var bindStyleM = new Control_Bind.Bind(function () {
    return applyStyleM;
}, function (v) {
    return function (f) {
        return S(Control_Bind.bind(Control_Monad_Writer_Trans.bindWriterT(Data_Semigroup.semigroupArray)(Data_Identity.bindIdentity))(v)(function ($295) {
            return (function (v1) {
                return v1;
            })(f($295));
        }));
    };
});
var semigroupCSS = new Data_Semigroup.Semigroup(Control_Apply.applySecond(applyStyleM));
var applicativeStyleM = new Control_Applicative.Applicative(function () {
    return applyStyleM;
}, (function () {
    var $296 = Control_Applicative.pure(Control_Monad_Writer_Trans.applicativeWriterT(Data_Monoid.monoidArray)(Data_Identity.applicativeIdentity));
    return function ($297) {
        return S($296($297));
    };
})());
var monadStyleM = new Control_Monad.Monad(function () {
    return applicativeStyleM;
}, function () {
    return bindStyleM;
});
module.exports = {
    MediaType: MediaType,
    Not: Not,
    Only: Only,
    MediaQuery: MediaQuery,
    Feature: Feature,
    Self: Self,
    Root: Root,
    Pop: Pop,
    Child: Child,
    Sub: Sub,
    Keyframes: Keyframes,
    Property: Property,
    Nested: Nested,
    Query: Query,
    Face: Face,
    Keyframe: Keyframe,
    Import: Import,
    S: S,
    runS: runS,
    rule: rule,
    key: key,
    prefixed: prefixed,
    select: select,
    query: query,
    keyframes: keyframes,
    keyframesFromTo: keyframesFromTo,
    fontFace: fontFace,
    importUrl: importUrl,
    eqMediaType: eqMediaType,
    ordMediaType: ordMediaType,
    eqNotOrOnly: eqNotOrOnly,
    ordNotOrOnly: ordNotOrOnly,
    eqMediaQuery: eqMediaQuery,
    ordMediaQuery: ordMediaQuery,
    eqFeature: eqFeature,
    ordFeature: ordFeature,
    eqApp: eqApp,
    ordApp: ordApp,
    eqKeyframes: eqKeyframes,
    ordKeyframes: ordKeyframes,
    eqRule: eqRule,
    ordRule: ordRule,
    functorStyleM: functorStyleM,
    applyStyleM: applyStyleM,
    bindStyleM: bindStyleM,
    applicativeStyleM: applicativeStyleM,
    monadStyleM: monadStyleM,
    semigroupCSS: semigroupCSS
};
