// Generated by purs version 0.14.0
"use strict";
var Control_Comonad = require("../Control.Comonad/index.js");
var Control_Extend = require("../Control.Extend/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Coproduct = function (x) {
    return x;
};
var showCoproduct = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (v) {
            if (v instanceof Data_Either.Left) {
                return "(left " + (Data_Show.show(dictShow)(v.value0) + ")");
            };
            if (v instanceof Data_Either.Right) {
                return "(right " + (Data_Show.show(dictShow1)(v.value0) + ")");
            };
            throw new Error("Failed pattern match at Data.Functor.Coproduct (line 63, column 1 - line 65, column 60): " + [ v.constructor.name ]);
        });
    };
};
var right = function (ga) {
    return new Data_Either.Right(ga);
};
var newtypeCoproduct = new Data_Newtype.Newtype(function () {
    return undefined;
});
var left = function (fa) {
    return new Data_Either.Left(fa);
};
var functorCoproduct = function (dictFunctor) {
    return function (dictFunctor1) {
        return new Data_Functor.Functor(function (f) {
            return function (v) {
                return Data_Bifunctor.bimap(Data_Bifunctor.bifunctorEither)(Data_Functor.map(dictFunctor)(f))(Data_Functor.map(dictFunctor1)(f))(v);
            };
        });
    };
};
var eq1Coproduct = function (dictEq1) {
    return function (dictEq11) {
        return new Data_Eq.Eq1(function (dictEq) {
            return function (v) {
                return function (v1) {
                    if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                        return Data_Eq.eq1(dictEq1)(dictEq)(v.value0)(v1.value0);
                    };
                    if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                        return Data_Eq.eq1(dictEq11)(dictEq)(v.value0)(v1.value0);
                    };
                    return false;
                };
            };
        });
    };
};
var eqCoproduct = function (dictEq1) {
    return function (dictEq11) {
        return function (dictEq) {
            return new Data_Eq.Eq(Data_Eq.eq1(eq1Coproduct(dictEq1)(dictEq11))(dictEq));
        };
    };
};
var ord1Coproduct = function (dictOrd1) {
    return function (dictOrd11) {
        return new Data_Ord.Ord1(function () {
            return eq1Coproduct(dictOrd1.Eq10())(dictOrd11.Eq10());
        }, function (dictOrd) {
            return function (v) {
                return function (v1) {
                    if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                        return Data_Ord.compare1(dictOrd1)(dictOrd)(v.value0)(v1.value0);
                    };
                    if (v instanceof Data_Either.Left) {
                        return Data_Ordering.LT.value;
                    };
                    if (v1 instanceof Data_Either.Left) {
                        return Data_Ordering.GT.value;
                    };
                    if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                        return Data_Ord.compare1(dictOrd11)(dictOrd)(v.value0)(v1.value0);
                    };
                    throw new Error("Failed pattern match at Data.Functor.Coproduct (line 57, column 5 - line 61, column 43): " + [ v.constructor.name, v1.constructor.name ]);
                };
            };
        });
    };
};
var ordCoproduct = function (dictOrd1) {
    return function (dictOrd11) {
        return function (dictOrd) {
            return new Data_Ord.Ord(function () {
                return eqCoproduct(dictOrd1.Eq10())(dictOrd11.Eq10())(dictOrd.Eq0());
            }, Data_Ord.compare1(ord1Coproduct(dictOrd1)(dictOrd11))(dictOrd));
        };
    };
};
var coproduct = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof Data_Either.Left) {
                return v(v2.value0);
            };
            if (v2 instanceof Data_Either.Right) {
                return v1(v2.value0);
            };
            throw new Error("Failed pattern match at Data.Functor.Coproduct (line 27, column 1 - line 27, column 78): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var extendCoproduct = function (dictExtend) {
    return function (dictExtend1) {
        return new Control_Extend.Extend(function () {
            return functorCoproduct(dictExtend.Functor0())(dictExtend1.Functor0());
        }, function (f) {
            var $61 = coproduct((function () {
                var $63 = Control_Extend.extend(dictExtend)(function ($65) {
                    return f(Coproduct(Data_Either.Left.create($65)));
                });
                return function ($64) {
                    return Data_Either.Left.create($63($64));
                };
            })())((function () {
                var $66 = Control_Extend.extend(dictExtend1)(function ($68) {
                    return f(Coproduct(Data_Either.Right.create($68)));
                });
                return function ($67) {
                    return Data_Either.Right.create($66($67));
                };
            })());
            return function ($62) {
                return Coproduct($61($62));
            };
        });
    };
};
var comonadCoproduct = function (dictComonad) {
    return function (dictComonad1) {
        return new Control_Comonad.Comonad(function () {
            return extendCoproduct(dictComonad.Extend0())(dictComonad1.Extend0());
        }, coproduct(Control_Comonad.extract(dictComonad))(Control_Comonad.extract(dictComonad1)));
    };
};
var bihoistCoproduct = function (natF) {
    return function (natG) {
        return function (v) {
            return Data_Bifunctor.bimap(Data_Bifunctor.bifunctorEither)(natF)(natG)(v);
        };
    };
};
module.exports = {
    Coproduct: Coproduct,
    left: left,
    right: right,
    coproduct: coproduct,
    bihoistCoproduct: bihoistCoproduct,
    newtypeCoproduct: newtypeCoproduct,
    eqCoproduct: eqCoproduct,
    eq1Coproduct: eq1Coproduct,
    ordCoproduct: ordCoproduct,
    ord1Coproduct: ord1Coproduct,
    showCoproduct: showCoproduct,
    functorCoproduct: functorCoproduct,
    extendCoproduct: extendCoproduct,
    comonadCoproduct: comonadCoproduct
};
