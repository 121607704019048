// Generated by purs version 0.14.0
"use strict";
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var withExcept = Control_Monad_Except_Trans.withExceptT(Data_Identity.functorIdentity);
var runExcept = (function () {
    var $0 = Data_Newtype.unwrap();
    return function ($1) {
        return $0(Control_Monad_Except_Trans.runExceptT($1));
    };
})();
var mapExcept = function (f) {
    return Control_Monad_Except_Trans.mapExceptT((function () {
        var $2 = Data_Newtype.unwrap();
        return function ($3) {
            return Data_Identity.Identity(f($2($3)));
        };
    })());
};
module.exports = {
    runExcept: runExcept,
    mapExcept: mapExcept,
    withExcept: withExcept,
    MonadError: Control_Monad_Error_Class.MonadError,
    catchError: Control_Monad_Error_Class.catchError,
    catchJust: Control_Monad_Error_Class.catchJust,
    throwError: Control_Monad_Error_Class.throwError,
    ExceptT: Control_Monad_Except_Trans.ExceptT,
    MonadTrans: Control_Monad_Except_Trans.MonadTrans,
    except: Control_Monad_Except_Trans.except,
    lift: Control_Monad_Except_Trans.lift,
    mapExceptT: Control_Monad_Except_Trans.mapExceptT,
    runExceptT: Control_Monad_Except_Trans.runExceptT,
    withExceptT: Control_Monad_Except_Trans.withExceptT
};
