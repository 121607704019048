// Generated by purs version 0.14.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Query = require("../Halogen.Query/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Types = require("../Types/index.js");
var Utils = require("../Utils/index.js");
var Web_Event_Event = require("../Web.Event.Event/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var Web_HTML_HTMLInputElement = require("../Web.HTML.HTMLInputElement/index.js");
var Web_UIEvent_KeyboardEvent = require("../Web.UIEvent.KeyboardEvent/index.js");
var Request = (function () {
    function Request(value0) {
        this.value0 = value0;
    };
    Request.create = function (value0) {
        return new Request(value0);
    };
    return Request;
})();
var Focus = (function () {
    function Focus(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Focus.create = function (value0) {
        return function (value1) {
            return new Focus(value0, value1);
        };
    };
    return Focus;
})();
var Move = (function () {
    function Move(value0) {
        this.value0 = value0;
    };
    Move.create = function (value0) {
        return new Move(value0);
    };
    return Move;
})();
var InputChange = (function () {
    function InputChange(value0) {
        this.value0 = value0;
    };
    InputChange.create = function (value0) {
        return new InputChange(value0);
    };
    return InputChange;
})();
var OnInput = (function () {
    function OnInput(value0) {
        this.value0 = value0;
    };
    OnInput.create = function (value0) {
        return new OnInput(value0);
    };
    return OnInput;
})();
var HandleKey = (function () {
    function HandleKey(value0) {
        this.value0 = value0;
    };
    HandleKey.create = function (value0) {
        return new HandleKey(value0);
    };
    return HandleKey;
})();
var UpdateState = (function () {
    function UpdateState(value0) {
        this.value0 = value0;
    };
    UpdateState.create = function (value0) {
        return new UpdateState(value0);
    };
    return UpdateState;
})();
var component = function (dictIsString) {
    return function (dictMonadAff) {
        var render = function (state) {
            return Halogen_HTML_Elements.input([ Halogen_HTML_Events.onValueInput(OnInput.create), Halogen_HTML_Events.onKeyDown(HandleKey.create), Halogen_HTML_Properties.value(state.input), Halogen_HTML_Properties.ref("field"), Halogen_HTML_Properties.style(state.style) ]);
        };
        var initialState = function (x) {
            return {
                input: Types.toString(dictIsString)(x.output),
                output: new Data_Maybe.Just(x.output),
                style: x.style
            };
        };
        var handleQuery = function (v) {
            if (v instanceof Request) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                    return v1.output;
                }))(function (output) {
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Functor.map(Data_Maybe.functorMaybe)(v.value0)(output));
                });
            };
            if (v instanceof Focus) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function ($25) {
                    return Data_String_CodePoints.length((function (v1) {
                        return v1.input;
                    })($25));
                }))(function (len) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query.getHTMLElementRef("field"))(function (me) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(me)(function (e) {
                            return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Web_HTML_HTMLInputElement.fromHTMLElement(e))(function (ie) {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLElement.focus(e)))(function () {
                                    var $12 = v.value0 < 0 || len < v.value0;
                                    if ($12) {
                                        return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLInputElement.setSelectionStart(len)(ie)))(function () {
                                            return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLInputElement.setSelectionEnd(len)(ie));
                                        });
                                    };
                                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLInputElement.setSelectionStart(v.value0)(ie)))(function () {
                                        return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLInputElement.setSelectionEnd(v.value0)(ie));
                                    });
                                });
                            });
                        }))(function () {
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(v.value1));
                        });
                    });
                });
            };
            throw new Error("Failed pattern match at Form.Input (line 107, column 17 - line 123, column 20): " + [ v.constructor.name ]);
        };
        var handleAction = function (v) {
            if (v instanceof OnInput) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                    var $16 = {};
                    for (var $17 in v1) {
                        if ({}.hasOwnProperty.call(v1, $17)) {
                            $16[$17] = v1[$17];
                        };
                    };
                    $16.input = v.value0;
                    $16.output = Types.fromString(dictIsString)(v.value0);
                    return $16;
                }))(function () {
                    return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Types.fromString(dictIsString)(v.value0))(function (val) {
                        return Halogen_Query_HalogenM.raise(new InputChange(val));
                    });
                });
            };
            if (v instanceof HandleKey) {
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Control_Bind.bind(Data_Maybe.bindMaybe)(Web_Event_Event.target(Web_UIEvent_KeyboardEvent.toEvent(v.value0)))(Web_HTML_HTMLInputElement.fromEventTarget))(function (e) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLInputElement.selectionStart(e)))(function (ss) {
                        var v1 = Web_UIEvent_KeyboardEvent.key(v.value0);
                        if (v1 === "ArrowUp") {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(v.value0))))(function () {
                                return Halogen_Query_HalogenM.raise(Move.create(new Types.ArrowUp(ss)));
                            });
                        };
                        if (v1 === "ArrowDown") {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(v.value0))))(function () {
                                return Halogen_Query_HalogenM.raise(Move.create(new Types.ArrowDown(ss)));
                            });
                        };
                        if (v1 === "ArrowLeft") {
                            var $21 = ss === 0;
                            if ($21) {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(v.value0))))(function () {
                                    return Halogen_Query_HalogenM.raise(new Move(Types.ArrowLeft.value));
                                });
                            };
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                        };
                        if (v1 === "ArrowRight") {
                            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v2) {
                                return v2.input;
                            }))(function (str) {
                                var $22 = ss === Data_String_CodePoints.length(str);
                                if ($22) {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(v.value0))))(function () {
                                        return Halogen_Query_HalogenM.raise(new Move(Types.ArrowRight.value));
                                    });
                                };
                                return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                            });
                        };
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    });
                });
            };
            if (v instanceof UpdateState) {
                return Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(initialState(v.value0));
            };
            throw new Error("Failed pattern match at Form.Input (line 75, column 18 - line 104, column 52): " + [ v.constructor.name ]);
        };
        return Halogen_Component.mkComponent({
            initialState: initialState,
            render: render,
            "eval": Halogen_Component.mkEval({
                handleAction: handleAction,
                handleQuery: handleQuery,
                receive: function ($26) {
                    return Data_Maybe.Just.create(UpdateState.create($26));
                },
                initialize: Halogen_Component.defaultEval.initialize,
                finalize: Halogen_Component.defaultEval.finalize
            })
        });
    };
};
module.exports = {
    Request: Request,
    Focus: Focus,
    OnInput: OnInput,
    HandleKey: HandleKey,
    UpdateState: UpdateState,
    Move: Move,
    InputChange: InputChange,
    component: component
};
