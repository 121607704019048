// Generated by purs version 0.14.0
"use strict";
var CSS_Property = require("../CSS.Property/index.js");
var CSS_String = require("../CSS.String/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var TextAlign = function (x) {
    return x;
};
var valTextAlign = new CSS_Property.Val(function (v) {
    return v;
});
var textAlign = CSS_Stylesheet.key(valTextAlign)(CSS_String.fromString(CSS_Property.isStringKey)("text-align"));
var startTextAlign = TextAlign(CSS_String.fromString(CSS_Property.isStringValue)("start"));
var rightTextAlign = TextAlign(CSS_String.fromString(CSS_Property.isStringValue)("right"));
var leftTextAlign = TextAlign(CSS_String.fromString(CSS_Property.isStringValue)("left"));
var justify = TextAlign(CSS_String.fromString(CSS_Property.isStringValue)("justify"));
var inheritTextAlign = TextAlign(CSS_String.fromString(CSS_Property.isStringValue)("inherit"));
var eqTextAlign = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordTextAlign = new Data_Ord.Ord(function () {
    return eqTextAlign;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var endTextAlign = TextAlign(CSS_String.fromString(CSS_Property.isStringValue)("end"));
var center = TextAlign(CSS_String.fromString(CSS_Property.isStringValue)("center"));
module.exports = {
    TextAlign: TextAlign,
    textAlign: textAlign,
    center: center,
    justify: justify,
    leftTextAlign: leftTextAlign,
    rightTextAlign: rightTextAlign,
    inheritTextAlign: inheritTextAlign,
    startTextAlign: startTextAlign,
    endTextAlign: endTextAlign,
    eqTextAlign: eqTextAlign,
    ordTextAlign: ordTextAlign,
    valTextAlign: valTextAlign
};
