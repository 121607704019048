// Generated by purs version 0.14.0
"use strict";
var CSS_Common = require("../CSS.Common/index.js");
var CSS_Property = require("../CSS.Property/index.js");
var CSS_String = require("../CSS.String/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Position = function (x) {
    return x;
};
var FloatLeft = (function () {
    function FloatLeft() {

    };
    FloatLeft.value = new FloatLeft();
    return FloatLeft;
})();
var FloatRight = (function () {
    function FloatRight() {

    };
    FloatRight.value = new FloatRight();
    return FloatRight;
})();
var FloatNone = (function () {
    function FloatNone() {

    };
    FloatNone.value = new FloatNone();
    return FloatNone;
})();
var Display = function (x) {
    return x;
};
var ClearFloatLeft = (function () {
    function ClearFloatLeft() {

    };
    ClearFloatLeft.value = new ClearFloatLeft();
    return ClearFloatLeft;
})();
var ClearFloatRight = (function () {
    function ClearFloatRight() {

    };
    ClearFloatRight.value = new ClearFloatRight();
    return ClearFloatRight;
})();
var ClearFloatBoth = (function () {
    function ClearFloatBoth() {

    };
    ClearFloatBoth.value = new ClearFloatBoth();
    return ClearFloatBoth;
})();
var ClearFloatNone = (function () {
    function ClearFloatNone() {

    };
    ClearFloatNone.value = new ClearFloatNone();
    return ClearFloatNone;
})();
var ClearFloatInherit = (function () {
    function ClearFloatInherit() {

    };
    ClearFloatInherit.value = new ClearFloatInherit();
    return ClearFloatInherit;
})();
var ClearFloatInlineStart = (function () {
    function ClearFloatInlineStart() {

    };
    ClearFloatInlineStart.value = new ClearFloatInlineStart();
    return ClearFloatInlineStart;
})();
var ClearFloatInlineEnd = (function () {
    function ClearFloatInlineEnd() {

    };
    ClearFloatInlineEnd.value = new ClearFloatInlineEnd();
    return ClearFloatInlineEnd;
})();
var zIndex = (function () {
    var $44 = CSS_Stylesheet.key(CSS_Property.valString)(CSS_String.fromString(CSS_Property.isStringKey)("z-index"));
    var $45 = Data_Show.show(Data_Show.showInt);
    return function ($46) {
        return $44($45($46));
    };
})();
var valPosition = new CSS_Property.Val(function (v) {
    return v;
});
var valFloat = new CSS_Property.Val(function (v) {
    if (v instanceof FloatLeft) {
        return CSS_String.fromString(CSS_Property.isStringValue)("left");
    };
    if (v instanceof FloatRight) {
        return CSS_String.fromString(CSS_Property.isStringValue)("right");
    };
    if (v instanceof FloatNone) {
        return CSS_String.fromString(CSS_Property.isStringValue)("none");
    };
    throw new Error("Failed pattern match at CSS.Display (line 116, column 1 - line 119, column 40): " + [ v.constructor.name ]);
});
var valDisplay = new CSS_Property.Val(function (v) {
    return v;
});
var valClearFloat = new CSS_Property.Val(function (v) {
    if (v instanceof ClearFloatLeft) {
        return CSS_String.fromString(CSS_Property.isStringValue)("left");
    };
    if (v instanceof ClearFloatRight) {
        return CSS_String.fromString(CSS_Property.isStringValue)("right");
    };
    if (v instanceof ClearFloatBoth) {
        return CSS_String.fromString(CSS_Property.isStringValue)("both");
    };
    if (v instanceof ClearFloatNone) {
        return CSS_String.fromString(CSS_Property.isStringValue)("none");
    };
    if (v instanceof ClearFloatInherit) {
        return CSS_String.fromString(CSS_Property.isStringValue)("inherit");
    };
    if (v instanceof ClearFloatInlineStart) {
        return CSS_String.fromString(CSS_Property.isStringValue)("inline-start");
    };
    if (v instanceof ClearFloatInlineEnd) {
        return CSS_String.fromString(CSS_Property.isStringValue)("inline-end");
    };
    throw new Error("Failed pattern match at CSS.Display (line 153, column 1 - line 160, column 56): " + [ v.constructor.name ]);
});
var tableRowGroup = Display(CSS_String.fromString(CSS_Property.isStringValue)("table-row-Group"));
var tableRow = Display(CSS_String.fromString(CSS_Property.isStringValue)("table-row"));
var tableHeaderGroup = Display(CSS_String.fromString(CSS_Property.isStringValue)("table-header-group"));
var tableFooterGroup = Display(CSS_String.fromString(CSS_Property.isStringValue)("table-footer-group"));
var tableColumnGroup = Display(CSS_String.fromString(CSS_Property.isStringValue)("table-column-group"));
var tableColumn = Display(CSS_String.fromString(CSS_Property.isStringValue)("table-column"));
var tableCell = Display(CSS_String.fromString(CSS_Property.isStringValue)("table-cell"));
var tableCaption = Display(CSS_String.fromString(CSS_Property.isStringValue)("table-caption"));
var table = Display(CSS_String.fromString(CSS_Property.isStringValue)("table"));
var $$static = Position(CSS_String.fromString(CSS_Property.isStringValue)("static"));
var showFloat = new Data_Show.Show(function (v) {
    if (v instanceof FloatLeft) {
        return "FloatLeft";
    };
    if (v instanceof FloatRight) {
        return "FloatRight";
    };
    if (v instanceof FloatNone) {
        return "FloatNone";
    };
    throw new Error("Failed pattern match at CSS.Display (line 111, column 1 - line 114, column 31): " + [ v.constructor.name ]);
});
var showClearFloat = new Data_Show.Show(function (v) {
    if (v instanceof ClearFloatLeft) {
        return "ClearFloatLeft";
    };
    if (v instanceof ClearFloatRight) {
        return "ClearFloatRight";
    };
    if (v instanceof ClearFloatBoth) {
        return "ClearFloatBoth";
    };
    if (v instanceof ClearFloatNone) {
        return "ClearFloatNone";
    };
    if (v instanceof ClearFloatInherit) {
        return "ClearFloatInherit";
    };
    if (v instanceof ClearFloatInlineStart) {
        return "ClearFloatInlineStart";
    };
    if (v instanceof ClearFloatInlineEnd) {
        return "ClearFloatInlineEnd";
    };
    throw new Error("Failed pattern match at CSS.Display (line 144, column 1 - line 151, column 51): " + [ v.constructor.name ]);
});
var runIn = Display(CSS_String.fromString(CSS_Property.isStringValue)("runIn"));
var relative = Position(CSS_String.fromString(CSS_Property.isStringValue)("relative"));
var position = CSS_Stylesheet.key(valPosition)(CSS_String.fromString(CSS_Property.isStringKey)("position"));
var opacity = CSS_Stylesheet.key(CSS_Property.valNumber)(CSS_String.fromString(CSS_Property.isStringKey)("opacity"));
var noneFloat = new CSS_Common.None(FloatNone.value);
var noneClearFloat = new CSS_Common.None(ClearFloatNone.value);
var listItem = Display(CSS_String.fromString(CSS_Property.isStringValue)("list-item"));
var inlineTable = Display(CSS_String.fromString(CSS_Property.isStringValue)("inline-table"));
var inlineGrid = Display(CSS_String.fromString(CSS_Property.isStringValue)("inline-grid"));
var inlineFlex = Display(CSS_String.fromString(CSS_Property.isStringValue)("inline-flex"));
var inlineBlock = Display(CSS_String.fromString(CSS_Property.isStringValue)("inline-block"));
var inline = Display(CSS_String.fromString(CSS_Property.isStringValue)("inline"));
var inheritClearFloat = new CSS_Common.Inherit(ClearFloatInherit.value);
var grid = Display(CSS_String.fromString(CSS_Property.isStringValue)("grid"));
var floatRight = FloatRight.value;
var floatLeft = FloatLeft.value;
var $$float = CSS_Stylesheet.key(valFloat)(CSS_String.fromString(CSS_Property.isStringKey)("float"));
var flex = Display(CSS_String.fromString(CSS_Property.isStringValue)("flex"));
var fixed = Position(CSS_String.fromString(CSS_Property.isStringValue)("fixed"));
var eqPosition = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordPosition = new Data_Ord.Ord(function () {
    return eqPosition;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var eqFloat = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof FloatLeft && y instanceof FloatLeft) {
            return true;
        };
        if (x instanceof FloatRight && y instanceof FloatRight) {
            return true;
        };
        if (x instanceof FloatNone && y instanceof FloatNone) {
            return true;
        };
        return false;
    };
});
var eqDisplay = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordDisplay = new Data_Ord.Ord(function () {
    return eqDisplay;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var eqClearFloat = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof ClearFloatLeft && y instanceof ClearFloatLeft) {
            return true;
        };
        if (x instanceof ClearFloatRight && y instanceof ClearFloatRight) {
            return true;
        };
        if (x instanceof ClearFloatBoth && y instanceof ClearFloatBoth) {
            return true;
        };
        if (x instanceof ClearFloatNone && y instanceof ClearFloatNone) {
            return true;
        };
        if (x instanceof ClearFloatInherit && y instanceof ClearFloatInherit) {
            return true;
        };
        if (x instanceof ClearFloatInlineStart && y instanceof ClearFloatInlineStart) {
            return true;
        };
        if (x instanceof ClearFloatInlineEnd && y instanceof ClearFloatInlineEnd) {
            return true;
        };
        return false;
    };
});
var displayNone = Display(CSS_String.fromString(CSS_Property.isStringValue)("none"));
var displayInherit = Display(CSS_String.fromString(CSS_Property.isStringValue)("inherit"));
var display = CSS_Stylesheet.key(valDisplay)(CSS_String.fromString(CSS_Property.isStringKey)("display"));
var clearRight = ClearFloatRight.value;
var clearLeft = ClearFloatLeft.value;
var clearInlineStart = ClearFloatInlineStart.value;
var clearInlineEnd = ClearFloatInlineEnd.value;
var clearBoth = ClearFloatBoth.value;
var clear = CSS_Stylesheet.key(valClearFloat)(CSS_String.fromString(CSS_Property.isStringKey)("clear"));
var block = Display(CSS_String.fromString(CSS_Property.isStringValue)("block"));
var absolute = Position(CSS_String.fromString(CSS_Property.isStringValue)("absolute"));
module.exports = {
    Position: Position,
    position: position,
    "static": $$static,
    absolute: absolute,
    fixed: fixed,
    relative: relative,
    Display: Display,
    inline: inline,
    block: block,
    listItem: listItem,
    runIn: runIn,
    inlineBlock: inlineBlock,
    table: table,
    inlineTable: inlineTable,
    tableRowGroup: tableRowGroup,
    tableHeaderGroup: tableHeaderGroup,
    tableFooterGroup: tableFooterGroup,
    tableRow: tableRow,
    tableColumnGroup: tableColumnGroup,
    tableColumn: tableColumn,
    tableCell: tableCell,
    tableCaption: tableCaption,
    displayNone: displayNone,
    displayInherit: displayInherit,
    flex: flex,
    inlineFlex: inlineFlex,
    grid: grid,
    inlineGrid: inlineGrid,
    display: display,
    FloatLeft: FloatLeft,
    FloatRight: FloatRight,
    FloatNone: FloatNone,
    floatLeft: floatLeft,
    floatRight: floatRight,
    "float": $$float,
    ClearFloatLeft: ClearFloatLeft,
    ClearFloatRight: ClearFloatRight,
    ClearFloatBoth: ClearFloatBoth,
    ClearFloatNone: ClearFloatNone,
    ClearFloatInherit: ClearFloatInherit,
    ClearFloatInlineStart: ClearFloatInlineStart,
    ClearFloatInlineEnd: ClearFloatInlineEnd,
    clearLeft: clearLeft,
    clearRight: clearRight,
    clearBoth: clearBoth,
    clearInlineStart: clearInlineStart,
    clearInlineEnd: clearInlineEnd,
    clear: clear,
    opacity: opacity,
    zIndex: zIndex,
    eqPosition: eqPosition,
    ordPosition: ordPosition,
    valPosition: valPosition,
    eqDisplay: eqDisplay,
    ordDisplay: ordDisplay,
    valDisplay: valDisplay,
    eqFloat: eqFloat,
    showFloat: showFloat,
    valFloat: valFloat,
    noneFloat: noneFloat,
    eqClearFloat: eqClearFloat,
    showClearFloat: showClearFloat,
    valClearFloat: valClearFloat,
    noneClearFloat: noneClearFloat,
    inheritClearFloat: inheritClearFloat
};
