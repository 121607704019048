// Generated by purs version 0.14.0
"use strict";
var $foreign = require("./foreign.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");
var Standard = (function () {
    function Standard() {

    };
    Standard.value = new Standard();
    return Standard;
})();
var Left = (function () {
    function Left() {

    };
    Left.value = new Left();
    return Left;
})();
var Right = (function () {
    function Right() {

    };
    Right.value = new Right();
    return Right;
})();
var Numpad = (function () {
    function Numpad() {

    };
    Numpad.value = new Numpad();
    return Numpad;
})();
var toUIEvent = Unsafe_Coerce.unsafeCoerce;
var toEvent = Unsafe_Coerce.unsafeCoerce;
var toEnumKeyLocation = function (v) {
    if (v === 0) {
        return new Data_Maybe.Just(Standard.value);
    };
    if (v === 1) {
        return new Data_Maybe.Just(Left.value);
    };
    if (v === 2) {
        return new Data_Maybe.Just(Right.value);
    };
    if (v === 3) {
        return new Data_Maybe.Just(Numpad.value);
    };
    return Data_Maybe.Nothing.value;
};
var fromUIEvent = Web_Internal_FFI.unsafeReadProtoTagged("KeyboardEvent");
var fromEvent = Web_Internal_FFI.unsafeReadProtoTagged("KeyboardEvent");
var fromEnumKeyLocation = function (v) {
    if (v instanceof Standard) {
        return 0;
    };
    if (v instanceof Left) {
        return 1;
    };
    if (v instanceof Right) {
        return 2;
    };
    if (v instanceof Numpad) {
        return 3;
    };
    throw new Error("Failed pattern match at Web.UIEvent.KeyboardEvent (line 107, column 3 - line 111, column 16): " + [ v.constructor.name ]);
};
var eqKeyLocation = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Standard && y instanceof Standard) {
            return true;
        };
        if (x instanceof Left && y instanceof Left) {
            return true;
        };
        if (x instanceof Right && y instanceof Right) {
            return true;
        };
        if (x instanceof Numpad && y instanceof Numpad) {
            return true;
        };
        return false;
    };
});
var ordKeyLocation = new Data_Ord.Ord(function () {
    return eqKeyLocation;
}, function (x) {
    return function (y) {
        if (x instanceof Standard && y instanceof Standard) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Standard) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Standard) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Left && y instanceof Left) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Left) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Left) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Right && y instanceof Right) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Right) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Right) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Numpad && y instanceof Numpad) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Web.UIEvent.KeyboardEvent (line 81, column 1 - line 81, column 50): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var enumKeyLocation = new Data_Enum.Enum(function () {
    return ordKeyLocation;
}, Data_Enum.defaultPred(toEnumKeyLocation)(fromEnumKeyLocation), Data_Enum.defaultSucc(toEnumKeyLocation)(fromEnumKeyLocation));
var boundedKeyLocation = new Data_Bounded.Bounded(function () {
    return ordKeyLocation;
}, Standard.value, Numpad.value);
var boundedEnumKeyLocation = new Data_Enum.BoundedEnum(function () {
    return boundedKeyLocation;
}, function () {
    return enumKeyLocation;
}, 4, fromEnumKeyLocation, toEnumKeyLocation);
var location = function (dictPartial) {
    var $13 = Data_Maybe.fromJust();
    var $14 = Data_Enum.toEnum(boundedEnumKeyLocation);
    return function ($15) {
        return $13($14($foreign.locationIndex($15)));
    };
};
module.exports = {
    fromUIEvent: fromUIEvent,
    fromEvent: fromEvent,
    toUIEvent: toUIEvent,
    toEvent: toEvent,
    location: location,
    Standard: Standard,
    Left: Left,
    Right: Right,
    Numpad: Numpad,
    toEnumKeyLocation: toEnumKeyLocation,
    fromEnumKeyLocation: fromEnumKeyLocation,
    eqKeyLocation: eqKeyLocation,
    ordKeyLocation: ordKeyLocation,
    boundedKeyLocation: boundedKeyLocation,
    enumKeyLocation: enumKeyLocation,
    boundedEnumKeyLocation: boundedEnumKeyLocation,
    key: $foreign.key,
    code: $foreign.code,
    locationIndex: $foreign.locationIndex,
    ctrlKey: $foreign.ctrlKey,
    shiftKey: $foreign.shiftKey,
    altKey: $foreign.altKey,
    metaKey: $foreign.metaKey,
    repeat: $foreign.repeat,
    isComposing: $foreign.isComposing,
    getModifierState: $foreign.getModifierState
};
