// Generated by purs version 0.14.0
"use strict";
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var TypeMismatch = (function () {
    function TypeMismatch(value0) {
        this.value0 = value0;
    };
    TypeMismatch.create = function (value0) {
        return new TypeMismatch(value0);
    };
    return TypeMismatch;
})();
var UnexpectedValue = (function () {
    function UnexpectedValue(value0) {
        this.value0 = value0;
    };
    UnexpectedValue.create = function (value0) {
        return new UnexpectedValue(value0);
    };
    return UnexpectedValue;
})();
var AtIndex = (function () {
    function AtIndex(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AtIndex.create = function (value0) {
        return function (value1) {
            return new AtIndex(value0, value1);
        };
    };
    return AtIndex;
})();
var AtKey = (function () {
    function AtKey(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AtKey.create = function (value0) {
        return function (value1) {
            return new AtKey(value0, value1);
        };
    };
    return AtKey;
})();
var Named = (function () {
    function Named(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Named.create = function (value0) {
        return function (value1) {
            return new Named(value0, value1);
        };
    };
    return Named;
})();
var MissingValue = (function () {
    function MissingValue() {

    };
    MissingValue.value = new MissingValue();
    return MissingValue;
})();
var showJsonDecodeError = new Data_Show.Show(function (v) {
    if (v instanceof TypeMismatch) {
        return "(TypeMismatch " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
    };
    if (v instanceof UnexpectedValue) {
        return "(UnexpectedValue " + (Data_Argonaut_Core.stringify(v.value0) + ")");
    };
    if (v instanceof AtIndex) {
        return "(AtIndex " + (Data_Show.show(Data_Show.showInt)(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
    };
    if (v instanceof AtKey) {
        return "(AtKey " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
    };
    if (v instanceof Named) {
        return "(Named " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
    };
    if (v instanceof MissingValue) {
        return "MissingValue";
    };
    throw new Error("Failed pattern match at Data.Argonaut.Decode.Error (line 24, column 10 - line 30, column 35): " + [ v.constructor.name ]);
});
var printJsonDecodeError = function (err) {
    var go = function (v) {
        if (v instanceof TypeMismatch) {
            return "  Expected value of type '" + (v.value0 + "'.");
        };
        if (v instanceof UnexpectedValue) {
            return "  Unexpected value " + (Data_Argonaut_Core.stringify(v.value0) + ".");
        };
        if (v instanceof AtIndex) {
            return "  At array index " + (Data_Show.show(Data_Show.showInt)(v.value0) + (":\x0a" + go(v.value1)));
        };
        if (v instanceof AtKey) {
            return "  At object key '" + (v.value0 + ("':\x0a" + go(v.value1)));
        };
        if (v instanceof Named) {
            return "  Under '" + (v.value0 + ("':\x0a" + go(v.value1)));
        };
        if (v instanceof MissingValue) {
            return "  No value was found.";
        };
        throw new Error("Failed pattern match at Data.Argonaut.Decode.Error (line 37, column 8 - line 43, column 44): " + [ v.constructor.name ]);
    };
    return "An error occurred while decoding a JSON value:\x0a" + go(err);
};
var genericJsonDecodeError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof TypeMismatch) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof UnexpectedValue) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof AtIndex) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1))));
    };
    if (x instanceof AtKey) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1)))));
    };
    if (x instanceof Named) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1))))));
    };
    if (x instanceof MissingValue) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
    };
    throw new Error("Failed pattern match at Data.Argonaut.Decode.Error (line 21, column 1 - line 21, column 68): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new TypeMismatch(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new UnexpectedValue(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new AtIndex(x.value0.value0.value0.value0, x.value0.value0.value0.value1);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new AtKey(x.value0.value0.value0.value0.value0, x.value0.value0.value0.value0.value1);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return new Named(x.value0.value0.value0.value0.value0.value0, x.value0.value0.value0.value0.value0.value1);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
        return MissingValue.value;
    };
    throw new Error("Failed pattern match at Data.Argonaut.Decode.Error (line 21, column 1 - line 21, column 68): " + [ x.constructor.name ]);
});
var eqJsonDecodeError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof TypeMismatch && y instanceof TypeMismatch) {
            return x.value0 === y.value0;
        };
        if (x instanceof UnexpectedValue && y instanceof UnexpectedValue) {
            return Data_Eq.eq(Data_Argonaut_Core.eqJson)(x.value0)(y.value0);
        };
        if (x instanceof AtIndex && y instanceof AtIndex) {
            return x.value0 === y.value0 && Data_Eq.eq(eqJsonDecodeError)(x.value1)(y.value1);
        };
        if (x instanceof AtKey && y instanceof AtKey) {
            return x.value0 === y.value0 && Data_Eq.eq(eqJsonDecodeError)(x.value1)(y.value1);
        };
        if (x instanceof Named && y instanceof Named) {
            return x.value0 === y.value0 && Data_Eq.eq(eqJsonDecodeError)(x.value1)(y.value1);
        };
        if (x instanceof MissingValue && y instanceof MissingValue) {
            return true;
        };
        return false;
    };
});
var ordJsonDecodeError = new Data_Ord.Ord(function () {
    return eqJsonDecodeError;
}, function (x) {
    return function (y) {
        if (x instanceof TypeMismatch && y instanceof TypeMismatch) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof TypeMismatch) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof TypeMismatch) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof UnexpectedValue && y instanceof UnexpectedValue) {
            return Data_Ord.compare(Data_Argonaut_Core.ordJson)(x.value0)(y.value0);
        };
        if (x instanceof UnexpectedValue) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof UnexpectedValue) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AtIndex && y instanceof AtIndex) {
            var v = Data_Ord.compare(Data_Ord.ordInt)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(ordJsonDecodeError)(x.value1)(y.value1);
        };
        if (x instanceof AtIndex) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AtIndex) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AtKey && y instanceof AtKey) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(ordJsonDecodeError)(x.value1)(y.value1);
        };
        if (x instanceof AtKey) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AtKey) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Named && y instanceof Named) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(ordJsonDecodeError)(x.value1)(y.value1);
        };
        if (x instanceof Named) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Named) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof MissingValue && y instanceof MissingValue) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Data.Argonaut.Decode.Error (line 20, column 1 - line 20, column 58): " + [ x.constructor.name, y.constructor.name ]);
    };
});
module.exports = {
    TypeMismatch: TypeMismatch,
    UnexpectedValue: UnexpectedValue,
    AtIndex: AtIndex,
    AtKey: AtKey,
    Named: Named,
    MissingValue: MissingValue,
    printJsonDecodeError: printJsonDecodeError,
    eqJsonDecodeError: eqJsonDecodeError,
    ordJsonDecodeError: ordJsonDecodeError,
    genericJsonDecodeError: genericJsonDecodeError,
    showJsonDecodeError: showJsonDecodeError
};
