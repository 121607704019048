"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var Delete = (function () {
    function Delete() {

    };
    Delete.value = new Delete();
    return Delete;
})();
var OpenDialog = (function () {
    function OpenDialog() {

    };
    OpenDialog.value = new OpenDialog();
    return OpenDialog;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var component = function (dictMonadAff) {
    var render = function (state) {
        return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(Data_Function["const"](OpenDialog.value)) ])([ Halogen_HTML_Core.text("\u3053\u306e\u30c7\u30fc\u30bf\u3092\u524a\u9664") ]) ]);
    };
    var initialState = function (name) {
        return {
            name: name
        };
    };
    var handleAction = function (v) {
        if (v instanceof OpenDialog) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML.window))(function (w) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                    return v1.name;
                }))(function (name) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_Window.confirm("\u300c" + (name + "\u300d\u3092\u524a\u9664\u3057\u307e\u3059\u304b\uff1f"))(w)))(function (confirmed) {
                        if (confirmed) {
                            return Halogen_Query_HalogenM.raise(Delete.value);
                        };
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    });
                });
            });
        };
        if (v instanceof Receive) {
            return Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(initialState(v.value0));
        };
        throw new Error("Failed pattern match at DeleteButton (line 50, column 18 - line 59, column 46): " + [ v.constructor.name ]);
    };
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($7) {
                return Data_Maybe.Just.create(Receive.create($7));
            },
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    Delete: Delete,
    OpenDialog: OpenDialog,
    Receive: Receive,
    component: component
};
