// Generated by purs version 0.14.0
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Anonymous = (function () {
    function Anonymous() {

    };
    Anonymous.value = new Anonymous();
    return Anonymous;
})();
var UseCredentials = (function () {
    function UseCredentials() {

    };
    UseCredentials.value = new UseCredentials();
    return UseCredentials;
})();
var showDecodingHint = new Data_Show.Show(function (v) {
    if (v instanceof Anonymous) {
        return "Anonymous";
    };
    if (v instanceof UseCredentials) {
        return "UseCredentials";
    };
    throw new Error("Failed pattern match at Web.HTML.HTMLImageElement.CORSMode (line 18, column 10 - line 20, column 39): " + [ v.constructor.name ]);
});
var print = function (v) {
    if (v instanceof Anonymous) {
        return "anonymous";
    };
    if (v instanceof UseCredentials) {
        return "use-credentials";
    };
    throw new Error("Failed pattern match at Web.HTML.HTMLImageElement.CORSMode (line 30, column 9 - line 32, column 38): " + [ v.constructor.name ]);
};
var parse = function (v) {
    if (v === "") {
        return new Data_Maybe.Just(Anonymous.value);
    };
    if (v === "anonymous") {
        return new Data_Maybe.Just(Anonymous.value);
    };
    if (v === "use-credentials") {
        return new Data_Maybe.Just(UseCredentials.value);
    };
    return Data_Maybe.Nothing.value;
};
var eqCORSMode = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Anonymous && y instanceof Anonymous) {
            return true;
        };
        if (x instanceof UseCredentials && y instanceof UseCredentials) {
            return true;
        };
        return false;
    };
});
var ordCORSMode = new Data_Ord.Ord(function () {
    return eqCORSMode;
}, function (x) {
    return function (y) {
        if (x instanceof Anonymous && y instanceof Anonymous) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Anonymous) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Anonymous) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof UseCredentials && y instanceof UseCredentials) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Web.HTML.HTMLImageElement.CORSMode (line 15, column 1 - line 15, column 44): " + [ x.constructor.name, y.constructor.name ]);
    };
});
module.exports = {
    Anonymous: Anonymous,
    UseCredentials: UseCredentials,
    parse: parse,
    print: print,
    eqCORSMode: eqCORSMode,
    ordCORSMode: ordCORSMode,
    showDecodingHint: showDecodingHint
};
