// Generated by purs version 0.14.0
"use strict";
var DOM_HTML_Indexed_ButtonType = require("../DOM.HTML.Indexed.ButtonType/index.js");
var DOM_HTML_Indexed_CrossOriginValue = require("../DOM.HTML.Indexed.CrossOriginValue/index.js");
var DOM_HTML_Indexed_DirValue = require("../DOM.HTML.Indexed.DirValue/index.js");
var DOM_HTML_Indexed_FormMethod = require("../DOM.HTML.Indexed.FormMethod/index.js");
var DOM_HTML_Indexed_InputAcceptType = require("../DOM.HTML.Indexed.InputAcceptType/index.js");
var DOM_HTML_Indexed_InputType = require("../DOM.HTML.Indexed.InputType/index.js");
var DOM_HTML_Indexed_KindValue = require("../DOM.HTML.Indexed.KindValue/index.js");
var DOM_HTML_Indexed_MenuType = require("../DOM.HTML.Indexed.MenuType/index.js");
var DOM_HTML_Indexed_MenuitemType = require("../DOM.HTML.Indexed.MenuitemType/index.js");
var DOM_HTML_Indexed_OnOff = require("../DOM.HTML.Indexed.OnOff/index.js");
var DOM_HTML_Indexed_OrderedListType = require("../DOM.HTML.Indexed.OrderedListType/index.js");
var DOM_HTML_Indexed_PreloadValue = require("../DOM.HTML.Indexed.PreloadValue/index.js");
var DOM_HTML_Indexed_ScopeValue = require("../DOM.HTML.Indexed.ScopeValue/index.js");
var DOM_HTML_Indexed_StepValue = require("../DOM.HTML.Indexed.StepValue/index.js");
var DOM_HTML_Indexed_WrapValue = require("../DOM.HTML.Indexed.WrapValue/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Halogen_Query_Input = require("../Halogen.Query.Input/index.js");
var Halogen_VDom = require("../Halogen.VDom/index.js");
var Halogen_VDom_DOM_Prop = require("../Halogen.VDom.DOM.Prop/index.js");
var Halogen_VDom_Types = require("../Halogen.VDom.Types/index.js");
var Web_HTML_Common = require("../Web.HTML.Common/index.js");
var IsProp = function (toPropValue) {
    this.toPropValue = toPropValue;
};
var HTML = function (x) {
    return x;
};
var widget = function ($18) {
    return HTML(Halogen_VDom_Types.Widget.create($18));
};
var toPropValue = function (dict) {
    return dict.toPropValue;
};
var text = function ($19) {
    return HTML(Halogen_VDom_Types.Text.create($19));
};
var ref = function (f) {
    return Halogen_VDom_DOM_Prop.Ref.create(function ($20) {
        return f((function (v) {
            if (v instanceof Halogen_VDom_DOM_Prop.Created) {
                return new Data_Maybe.Just(v.value0);
            };
            if (v instanceof Halogen_VDom_DOM_Prop.Removed) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at Halogen.HTML.Core (line 103, column 21 - line 105, column 23): " + [ v.constructor.name ]);
        })($20));
    });
};
var prop = function (dictIsProp) {
    return function (v) {
        var $21 = Halogen_VDom_DOM_Prop.Property.create(v);
        var $22 = toPropValue(dictIsProp);
        return function ($23) {
            return $21($22($23));
        };
    };
};
var newtypeHTML = new Data_Newtype.Newtype(function () {
    return undefined;
});
var renderWidget = function (f) {
    return function (g) {
        return function (v) {
            return Halogen_VDom_Types.renderWidget(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Halogen_VDom_DOM_Prop.functorProp)(Data_Functor.map(Halogen_Query_Input.functorInput)(f))))((function () {
                var $24 = Data_Newtype.un()(HTML);
                return function ($25) {
                    return $24(g($25));
                };
            })())(v);
        };
    };
};
var keyed = function (ns) {
    return function (name) {
        return function (props) {
            return function (children) {
                return new Halogen_VDom_Types.Keyed(ns, name, props, children);
            };
        };
    };
};
var isPropWrapValue = new IsProp(function ($26) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_WrapValue.renderWrapValue($26));
});
var isPropString = new IsProp(Halogen_VDom_DOM_Prop.propFromString);
var isPropStepValue = new IsProp(function ($27) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_StepValue.renderStepValue($27));
});
var isPropScopeValue = new IsProp(function ($28) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_ScopeValue.renderScopeValue($28));
});
var isPropPreloadValue = new IsProp(function ($29) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_PreloadValue.renderPreloadValue($29));
});
var isPropOrderedListType = new IsProp(function ($30) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_OrderedListType.renderOrderedListType($30));
});
var isPropOnOff = new IsProp(function ($31) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_OnOff.renderOnOff($31));
});
var isPropNumber = new IsProp(Halogen_VDom_DOM_Prop.propFromNumber);
var isPropMenuitemType = new IsProp(function ($32) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_MenuitemType.renderMenuitemType($32));
});
var isPropMenuType = new IsProp(function ($33) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_MenuType.renderMenuType($33));
});
var isPropMediaType = new IsProp((function () {
    var $34 = Data_Newtype.unwrap();
    return function ($35) {
        return Halogen_VDom_DOM_Prop.propFromString($34($35));
    };
})());
var isPropKindValue = new IsProp(function ($36) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_KindValue.renderKindValue($36));
});
var isPropInt = new IsProp(Halogen_VDom_DOM_Prop.propFromInt);
var isPropInputType = new IsProp(function ($37) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_InputType.renderInputType($37));
});
var isPropInputAcceptType = new IsProp(function ($38) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_InputAcceptType.renderInputAcceptType($38));
});
var isPropFormMethod = new IsProp(function ($39) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_FormMethod.renderFormMethod($39));
});
var isPropDirValue = new IsProp(function ($40) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_DirValue.renderDirValue($40));
});
var isPropCrossOriginValue = new IsProp(function ($41) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_CrossOriginValue.renderCrossOriginValue($41));
});
var isPropButtonType = new IsProp(function ($42) {
    return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_ButtonType.renderButtonType($42));
});
var isPropBoolean = new IsProp(Halogen_VDom_DOM_Prop.propFromBoolean);
var handler = Halogen_VDom_DOM_Prop.Handler.create;
var element = function (ns) {
    return function (name) {
        return function (props) {
            return function (children) {
                return new Halogen_VDom_Types.Elem(ns, name, props, children);
            };
        };
    };
};
var bifunctorHTML = new Data_Bifunctor.Bifunctor(function (f) {
    return function (g) {
        return function (v) {
            return Data_Bifunctor.bimap(Halogen_VDom_Types.bifunctorVDom)(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Halogen_VDom_DOM_Prop.functorProp)(Data_Functor.map(Halogen_Query_Input.functorInput)(g))))(f)(v);
        };
    };
});
var functorHTML = new Data_Functor.Functor(Data_Bifunctor.rmap(bifunctorHTML));
var attr = function (ns) {
    return function (v) {
        return Halogen_VDom_DOM_Prop.Attribute.create(ns)(v);
    };
};
module.exports = {
    HTML: HTML,
    renderWidget: renderWidget,
    widget: widget,
    text: text,
    element: element,
    keyed: keyed,
    prop: prop,
    attr: attr,
    handler: handler,
    ref: ref,
    IsProp: IsProp,
    toPropValue: toPropValue,
    newtypeHTML: newtypeHTML,
    bifunctorHTML: bifunctorHTML,
    functorHTML: functorHTML,
    isPropString: isPropString,
    isPropInt: isPropInt,
    isPropNumber: isPropNumber,
    isPropBoolean: isPropBoolean,
    isPropMediaType: isPropMediaType,
    isPropButtonType: isPropButtonType,
    isPropCrossOriginValue: isPropCrossOriginValue,
    isPropDirValue: isPropDirValue,
    isPropFormMethod: isPropFormMethod,
    isPropInputType: isPropInputType,
    isPropKindValue: isPropKindValue,
    isPropMenuitemType: isPropMenuitemType,
    isPropMenuType: isPropMenuType,
    isPropOnOff: isPropOnOff,
    isPropOrderedListType: isPropOrderedListType,
    isPropPreloadValue: isPropPreloadValue,
    isPropScopeValue: isPropScopeValue,
    isPropStepValue: isPropStepValue,
    isPropWrapValue: isPropWrapValue,
    isPropInputAcceptType: isPropInputAcceptType,
    ElemName: Halogen_VDom.ElemName,
    Namespace: Halogen_VDom.Namespace,
    Attribute: Halogen_VDom_DOM_Prop.Attribute,
    Handler: Halogen_VDom_DOM_Prop.Handler,
    Property: Halogen_VDom_DOM_Prop.Property,
    Ref: Halogen_VDom_DOM_Prop.Ref,
    AttrName: Web_HTML_Common.AttrName,
    ClassName: Web_HTML_Common.ClassName,
    PropName: Web_HTML_Common.PropName
};
