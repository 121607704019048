// Generated by purs version 0.14.0
"use strict";
var CSS_Utils = require("../CSS.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Form_Input = require("../Form.Input/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML = require("../Halogen.HTML/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Query = require("../Halogen.Query/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Types = require("../Types/index.js");
var Utils = require("../Utils/index.js");
var DebitCoord = (function () {
    function DebitCoord(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    DebitCoord.create = function (value0) {
        return function (value1) {
            return new DebitCoord(value0, value1);
        };
    };
    return DebitCoord;
})();
var CreditCoord = (function () {
    function CreditCoord(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    CreditCoord.create = function (value0) {
        return function (value1) {
            return new CreditCoord(value0, value1);
        };
    };
    return CreditCoord;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var InsertDebit = (function () {
    function InsertDebit(value0) {
        this.value0 = value0;
    };
    InsertDebit.create = function (value0) {
        return new InsertDebit(value0);
    };
    return InsertDebit;
})();
var InsertCredit = (function () {
    function InsertCredit(value0) {
        this.value0 = value0;
    };
    InsertCredit.create = function (value0) {
        return new InsertCredit(value0);
    };
    return InsertCredit;
})();
var RemoveDebit = (function () {
    function RemoveDebit(value0) {
        this.value0 = value0;
    };
    RemoveDebit.create = function (value0) {
        return new RemoveDebit(value0);
    };
    return RemoveDebit;
})();
var RemoveCredit = (function () {
    function RemoveCredit(value0) {
        this.value0 = value0;
    };
    RemoveCredit.create = function (value0) {
        return new RemoveCredit(value0);
    };
    return RemoveCredit;
})();
var InsertColumn = (function () {
    function InsertColumn(value0) {
        this.value0 = value0;
    };
    InsertColumn.create = function (value0) {
        return new InsertColumn(value0);
    };
    return InsertColumn;
})();
var RemoveColumn = (function () {
    function RemoveColumn(value0) {
        this.value0 = value0;
    };
    RemoveColumn.create = function (value0) {
        return new RemoveColumn(value0);
    };
    return RemoveColumn;
})();
var HandleOutputName = (function () {
    function HandleOutputName(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    HandleOutputName.create = function (value0) {
        return function (value1) {
            return new HandleOutputName(value0, value1);
        };
    };
    return HandleOutputName;
})();
var HandleOutputValue = (function () {
    function HandleOutputValue(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    HandleOutputValue.create = function (value0) {
        return function (value1) {
            return new HandleOutputValue(value0, value1);
        };
    };
    return HandleOutputValue;
})();
var component = function (dictMonadAff) {
    var renderDebitRemoveButtons = function (state) {
        var v = Data_Array.length(state.debitIds);
        if (v === 0) {
            return [  ];
        };
        return Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(Utils.range(0)(v - 1 | 0))(function (idx) {
            return Halogen_HTML_Elements.button([ Halogen_HTML_Properties.style(CSS_Utils.gridItem(idx + 3 | 0)(1)), Halogen_HTML_Events.onClick(Data_Function["const"](new RemoveDebit(idx))) ])([ Halogen_HTML_Core.text("-") ]);
        });
    };
    var renderDebitInsertButtons = function (state) {
        var l = Data_Array.length(state.debitIds);
        return [ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.style(CSS_Utils.gridItem(l + 3 | 0)(1)), Halogen_HTML_Events.onClick(Data_Function["const"](new InsertDebit(l))) ])([ Halogen_HTML_Core.text("+") ]) ];
    };
    var renderCreditRemoveButtons = function (state) {
        var v = Data_Array.length(state.creditIds);
        if (v === 0) {
            return [  ];
        };
        return Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(Utils.range(0)(v - 1 | 0))(function (idx) {
            return Halogen_HTML_Elements.button([ Halogen_HTML_Properties.style(CSS_Utils.gridItem((idx + Data_Array.length(state.debitIds) | 0) + 4 | 0)(1)), Halogen_HTML_Events.onClick(Data_Function["const"](new RemoveCredit(idx))) ])([ Halogen_HTML_Core.text("-") ]);
        });
    };
    var renderCreditInsertButtons = function (state) {
        var l = Data_Array.length(state.creditIds);
        return [ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.style(CSS_Utils.gridItem((l + Data_Array.length(state.debitIds) | 0) + 4 | 0)(1)), Halogen_HTML_Events.onClick(Data_Function["const"](new InsertCredit(l))) ])([ Halogen_HTML_Core.text("+") ]) ];
    };
    var renderColumnRemoveButtons = function (state) {
        var v = Data_Array.length(state.columnIds);
        if (v === 0) {
            return [  ];
        };
        return Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(Utils.range(0)(v - 1 | 0))(function (idx) {
            return Halogen_HTML_Elements.button([ Halogen_HTML_Properties.style(CSS_Utils.gridItem(1)(idx + 3 | 0)), Halogen_HTML_Events.onClick(Data_Function["const"](new RemoveColumn(idx))) ])([ Halogen_HTML_Core.text("-") ]);
        });
    };
    var renderColumnInsertButtons = function (state) {
        var l = Data_Array.length(state.columnIds);
        return [ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.style(CSS_Utils.gridItem(1)(l + 3 | 0)), Halogen_HTML_Events.onClick(Data_Function["const"](new InsertColumn(l))) ])([ Halogen_HTML_Core.text("+") ]) ];
    };
    var initialState = function (pillarsData) {
        var nDebit = Data_Array.length(pillarsData.debitNames);
        var nCredit = Data_Array.length(pillarsData.creditNames);
        var nCol = Data_Array.length(pillarsData.pillarNames);
        return {
            debitIds: Utils.range(0)(nDebit - 1 | 0),
            creditIds: Utils.range(nDebit)((nDebit + nCredit | 0) - 1 | 0),
            columnIds: Utils.range(0)(nCol - 1 | 0),
            nextRowId: nDebit + nCredit | 0,
            nextColumnId: nCol,
            pillarsData: pillarsData
        };
    };
    var getPosition = function (direction) {
        return function (coord) {
            return function (colLen) {
                if (direction instanceof Types.ArrowUp) {
                    return direction.value0;
                };
                if (direction instanceof Types.ArrowDown) {
                    return direction.value0;
                };
                if (direction instanceof Types.ArrowLeft) {
                    if (coord instanceof DebitCoord && coord.value1 === -1) {
                        return 0;
                    };
                    if (coord instanceof CreditCoord && coord.value1 === -1) {
                        return 0;
                    };
                    return -1 | 0;
                };
                if (direction instanceof Types.ArrowRight) {
                    var colIdx = (function () {
                        if (coord instanceof DebitCoord) {
                            return coord.value1;
                        };
                        if (coord instanceof CreditCoord) {
                            return coord.value1;
                        };
                        throw new Error("Failed pattern match at Form (line 375, column 18 - line 377, column 31): " + [ coord.constructor.name ]);
                    })();
                    var $66 = colIdx === (colLen - 1 | 0);
                    if ($66) {
                        return -1 | 0;
                    };
                    return 0;
                };
                throw new Error("Failed pattern match at Form (line 366, column 40 - line 379, column 47): " + [ direction.constructor.name ]);
            };
        };
    };
    var destCoord = function (coord) {
        return function (direction) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function ($214) {
                return Data_Array.length((function (v) {
                    return v.debitIds;
                })($214));
            }))(function (nDebitRow) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function ($215) {
                    return Data_Array.length((function (v) {
                        return v.creditIds;
                    })($215));
                }))(function (nCreditRow) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function ($216) {
                        return Data_Array.length((function (v) {
                            return v.columnIds;
                        })($216));
                    }))(function (nCol) {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)((function () {
                            if (coord instanceof DebitCoord) {
                                if (direction instanceof Types.ArrowLeft) {
                                    return new DebitCoord(coord.value0, coord.value1 - 1 | 0);
                                };
                                if (direction instanceof Types.ArrowRight) {
                                    if (coord.value1 === (nCol - 1 | 0)) {
                                        return new DebitCoord(coord.value0, coord.value1);
                                    };
                                    if (Data_Boolean.otherwise) {
                                        return new DebitCoord(coord.value0, coord.value1 + 1 | 0);
                                    };
                                };
                                if (direction instanceof Types.ArrowUp) {
                                    return new DebitCoord(coord.value0 - 1 | 0, coord.value1);
                                };
                                if (direction instanceof Types.ArrowDown) {
                                    if (coord.value0 === (nDebitRow - 1 | 0)) {
                                        return new CreditCoord(0, coord.value1);
                                    };
                                    if (Data_Boolean.otherwise) {
                                        return new DebitCoord(coord.value0 + 1 | 0, coord.value1);
                                    };
                                };
                                throw new Error("Failed pattern match at Form (line 344, column 25 - line 352, column 46): " + [ direction.constructor.name ]);
                            };
                            if (coord instanceof CreditCoord) {
                                if (direction instanceof Types.ArrowLeft) {
                                    return new CreditCoord(coord.value0, coord.value1 - 1 | 0);
                                };
                                if (direction instanceof Types.ArrowRight) {
                                    if (coord.value1 === (nCol - 1 | 0)) {
                                        return new CreditCoord(coord.value0, coord.value1);
                                    };
                                    if (Data_Boolean.otherwise) {
                                        return new CreditCoord(coord.value0, coord.value1 + 1 | 0);
                                    };
                                };
                                if (direction instanceof Types.ArrowDown) {
                                    if (coord.value0 === (nCreditRow - 1 | 0)) {
                                        return new CreditCoord(coord.value0, coord.value1);
                                    };
                                    if (Data_Boolean.otherwise) {
                                        return new CreditCoord(coord.value0 + 1 | 0, coord.value1);
                                    };
                                };
                                if (direction instanceof Types.ArrowUp) {
                                    if (coord.value0 === 0) {
                                        return new DebitCoord(nDebitRow - 1 | 0, coord.value1);
                                    };
                                    if (Data_Boolean.otherwise) {
                                        return new CreditCoord(coord.value0 - 1 | 0, coord.value1);
                                    };
                                };
                                throw new Error("Failed pattern match at Form (line 353, column 26 - line 363, column 47): " + [ direction.constructor.name ]);
                            };
                            throw new Error("Failed pattern match at Form (line 343, column 10 - line 363, column 47): " + [ coord.constructor.name ]);
                        })());
                    });
                });
            });
        };
    };
    var renderCreditValues = function (state) {
        return Data_Array.concat(Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(state.columnIds)(state.pillarsData.values))(function (columnIdx) {
            return function (v) {
                return Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(state.creditIds)(v.value1.credit))(function (rowIdx) {
                    return function (v1) {
                        return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                            return "value";
                        }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(v1.value0, v.value0))(Form_Input.component(Types.fromStringNumber)(dictMonadAff))({
                            output: v1.value1,
                            style: CSS_Utils.gridItem((rowIdx + Data_Array.length(state.debitIds) | 0) + 4 | 0)(columnIdx + 3 | 0)
                        })(HandleOutputValue.create(new CreditCoord(rowIdx, columnIdx)));
                    };
                });
            };
        }));
    };
    var renderDebitValues = function (state) {
        return Data_Array.concat(Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(state.columnIds)(state.pillarsData.values))(function (columnIdx) {
            return function (v) {
                return Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(state.debitIds)(v.value1.debit))(function (rowIdx) {
                    return function (v1) {
                        return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                            return "value";
                        }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(v1.value0, v.value0))(Form_Input.component(Types.fromStringNumber)(dictMonadAff))({
                            output: v1.value1,
                            style: CSS_Utils.gridItem(rowIdx + 3 | 0)(columnIdx + 3 | 0)
                        })(HandleOutputValue.create(new DebitCoord(rowIdx, columnIdx)));
                    };
                });
            };
        }));
    };
    var move = function (coord) {
        return function (direction) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(destCoord(coord)(direction))(function (destination) {
                    var pos = getPosition(direction)(coord)(Data_Array.length(state.columnIds));
                    if (destination instanceof DebitCoord) {
                        var mRowId = Data_Array.index(state.debitIds)(destination.value0);
                        var mColId = Data_Array.index(state.columnIds)(destination.value1);
                        var v = new Data_Tuple.Tuple(mRowId, mColId);
                        if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                        };
                        if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Just) {
                            return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                                return "name";
                            }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(-1 | 0, v.value1.value0))(Form_Input.Focus.create(pos));
                        };
                        if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Nothing) {
                            return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                                return "name";
                            }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(v.value0.value0, -1 | 0))(Form_Input.Focus.create(pos));
                        };
                        if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Just) {
                            return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                                return "value";
                            }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(v.value0.value0, v.value1.value0))(Form_Input.Focus.create(pos));
                        };
                        throw new Error("Failed pattern match at Form (line 322, column 9 - line 326, column 97): " + [ v.constructor.name ]);
                    };
                    if (destination instanceof CreditCoord) {
                        var mRowId = Data_Array.index(state.creditIds)(destination.value0);
                        var mColId = Data_Array.index(state.columnIds)(destination.value1);
                        var v = new Data_Tuple.Tuple(mRowId, mColId);
                        if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                        };
                        if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Just) {
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                        };
                        if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Nothing) {
                            return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                                return "name";
                            }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(v.value0.value0, -1 | 0))(Form_Input.Focus.create(pos));
                        };
                        if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Just) {
                            return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                                return "value";
                            }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(v.value0.value0, v.value1.value0))(Form_Input.Focus.create(pos));
                        };
                        throw new Error("Failed pattern match at Form (line 332, column 9 - line 336, column 97): " + [ v.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at Form (line 316, column 5 - line 336, column 97): " + [ destination.constructor.name ]);
                });
            });
        };
    };
    var handleAction = function (v) {
        if (v instanceof Receive) {
            return Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(initialState(v.value0));
        };
        if (v instanceof InsertDebit) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                var mNewState = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)(state.nextRowId)(state.debitIds))(function (debitIds) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe)(Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(state.pillarsData.values)(function (pillarValues) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)(0.0)(pillarValues.debit))(function (debit) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                debit: debit,
                                credit: pillarValues.credit
                            });
                        });
                    })))(function (values) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)("")(state.pillarsData.debitNames))(function (debitNames) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                debitNames: debitNames,
                                values: values,
                                creditNames: state.pillarsData.creditNames,
                                pillarNames: state.pillarsData.pillarNames
                            });
                        });
                    }))(function (pillarsData) {
                        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((function () {
                            var $123 = {};
                            for (var $124 in state) {
                                if ({}.hasOwnProperty.call(state, $124)) {
                                    $123[$124] = state[$124];
                                };
                            };
                            $123.debitIds = debitIds;
                            $123.nextRowId = state.nextRowId + 1 | 0;
                            $123.pillarsData = pillarsData;
                            return $123;
                        })());
                    });
                });
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(mNewState)(function (newState) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(newState))(function () {
                        return Halogen_Query_HalogenM.raise(newState.pillarsData);
                    });
                });
            });
        };
        if (v instanceof InsertCredit) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                var mNewState = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)(state.nextRowId)(state.creditIds))(function (creditIds) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe)(Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(state.pillarsData.values)(function (pillarValues) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)(0.0)(pillarValues.credit))(function (credit) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                credit: credit,
                                debit: pillarValues.debit
                            });
                        });
                    })))(function (values) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)("")(state.pillarsData.creditNames))(function (creditNames) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                creditNames: creditNames,
                                values: values,
                                debitNames: state.pillarsData.debitNames,
                                pillarNames: state.pillarsData.pillarNames
                            });
                        });
                    }))(function (pillarsData) {
                        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((function () {
                            var $127 = {};
                            for (var $128 in state) {
                                if ({}.hasOwnProperty.call(state, $128)) {
                                    $127[$128] = state[$128];
                                };
                            };
                            $127.creditIds = creditIds;
                            $127.nextRowId = state.nextRowId + 1 | 0;
                            $127.pillarsData = pillarsData;
                            return $127;
                        })());
                    });
                });
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(mNewState)(function (newState) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(newState))(function () {
                        return Halogen_Query_HalogenM.raise(newState.pillarsData);
                    });
                });
            });
        };
        if (v instanceof RemoveDebit) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                var mNewState = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(state.debitIds))(function (debitIds) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe)(Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(state.pillarsData.values)(function (pillarValues) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(pillarValues.debit))(function (debit) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                debit: debit,
                                credit: pillarValues.credit
                            });
                        });
                    })))(function (values) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(state.pillarsData.debitNames))(function (debitNames) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                debitNames: debitNames,
                                values: values,
                                creditNames: state.pillarsData.creditNames,
                                pillarNames: state.pillarsData.pillarNames
                            });
                        });
                    }))(function (pillarsData) {
                        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((function () {
                            var $131 = {};
                            for (var $132 in state) {
                                if ({}.hasOwnProperty.call(state, $132)) {
                                    $131[$132] = state[$132];
                                };
                            };
                            $131.debitIds = debitIds;
                            $131.pillarsData = pillarsData;
                            return $131;
                        })());
                    });
                });
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(mNewState)(function (newState) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(newState))(function () {
                        return Halogen_Query_HalogenM.raise(newState.pillarsData);
                    });
                });
            });
        };
        if (v instanceof RemoveCredit) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                var mNewState = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(state.creditIds))(function (creditIds) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe)(Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(state.pillarsData.values)(function (pillarValues) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(pillarValues.credit))(function (credit) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                credit: credit,
                                debit: pillarValues.debit
                            });
                        });
                    })))(function (values) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(state.pillarsData.creditNames))(function (creditNames) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                creditNames: creditNames,
                                values: values,
                                debitNames: state.pillarsData.debitNames,
                                pillarNames: state.pillarsData.pillarNames
                            });
                        });
                    }))(function (pillarsData) {
                        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((function () {
                            var $135 = {};
                            for (var $136 in state) {
                                if ({}.hasOwnProperty.call(state, $136)) {
                                    $135[$136] = state[$136];
                                };
                            };
                            $135.creditIds = creditIds;
                            $135.pillarsData = pillarsData;
                            return $135;
                        })());
                    });
                });
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(mNewState)(function (newState) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(newState))(function () {
                        return Halogen_Query_HalogenM.raise(newState.pillarsData);
                    });
                });
            });
        };
        if (v instanceof InsertColumn) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                var mNewState = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)(state.nextColumnId)(state.columnIds))(function (columnIds) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)("")(state.pillarsData.pillarNames))(function (pillarNames) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.insertAt(v.value0)({
                            debit: Data_Array.replicate(Data_Array.length(state.debitIds))(0.0),
                            credit: Data_Array.replicate(Data_Array.length(state.creditIds))(0.0)
                        })(state.pillarsData.values))(function (values) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((function () {
                                var $139 = {};
                                for (var $140 in state) {
                                    if ({}.hasOwnProperty.call(state, $140)) {
                                        $139[$140] = state[$140];
                                    };
                                };
                                $139.columnIds = columnIds;
                                $139.nextColumnId = state.nextColumnId + 1 | 0;
                                $139.pillarsData = {
                                    pillarNames: pillarNames,
                                    values: values,
                                    creditNames: state.pillarsData.creditNames,
                                    debitNames: state.pillarsData.debitNames
                                };
                                return $139;
                            })());
                        });
                    });
                });
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(mNewState)(function (newState) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(newState))(function () {
                        return Halogen_Query_HalogenM.raise(newState.pillarsData);
                    });
                });
            });
        };
        if (v instanceof RemoveColumn) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                var mNewState = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(state.columnIds))(function (columnIds) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(state.pillarsData.pillarNames))(function (pillarNames) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v.value0)(state.pillarsData.values))(function (values) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((function () {
                                var $143 = {};
                                for (var $144 in state) {
                                    if ({}.hasOwnProperty.call(state, $144)) {
                                        $143[$144] = state[$144];
                                    };
                                };
                                $143.columnIds = columnIds;
                                $143.pillarsData = {
                                    pillarNames: pillarNames,
                                    values: values,
                                    creditNames: state.pillarsData.creditNames,
                                    debitNames: state.pillarsData.debitNames
                                };
                                return $143;
                            })());
                        });
                    });
                });
                return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(mNewState)(function (newState) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(newState))(function () {
                        return Halogen_Query_HalogenM.raise(newState.pillarsData);
                    });
                });
            });
        };
        if (v instanceof HandleOutputName && v.value1 instanceof Form_Input.Move) {
            return move(v.value0)(v.value1.value0);
        };
        if (v instanceof HandleOutputValue && v.value1 instanceof Form_Input.Move) {
            return move(v.value0)(v.value1.value0);
        };
        if (v instanceof HandleOutputName && v.value1 instanceof Form_Input.InputChange) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return v1.pillarsData;
            }))(function (pillarsData) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)((function () {
                    if (v.value0 instanceof DebitCoord) {
                        if (v.value0.value0 < 0 && v.value0.value1 >= 0) {
                            return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Data_Array.updateAt(v.value0.value1)(v.value1.value0)(pillarsData.pillarNames))(function (pillarNames) {
                                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                    var $157 = {};
                                    for (var $158 in v1) {
                                        if ({}.hasOwnProperty.call(v1, $158)) {
                                            $157[$158] = v1[$158];
                                        };
                                    };
                                    $157.pillarsData = (function () {
                                        var $154 = {};
                                        for (var $155 in pillarsData) {
                                            if ({}.hasOwnProperty.call(pillarsData, $155)) {
                                                $154[$155] = pillarsData[$155];
                                            };
                                        };
                                        $154.pillarNames = pillarNames;
                                        return $154;
                                    })();
                                    return $157;
                                });
                            });
                        };
                        if (v.value0.value0 >= 0 && v.value0.value1 < 0) {
                            return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Data_Array.updateAt(v.value0.value0)(v.value1.value0)(pillarsData.debitNames))(function (debitNames) {
                                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                    var $163 = {};
                                    for (var $164 in v1) {
                                        if ({}.hasOwnProperty.call(v1, $164)) {
                                            $163[$164] = v1[$164];
                                        };
                                    };
                                    $163.pillarsData = (function () {
                                        var $160 = {};
                                        for (var $161 in pillarsData) {
                                            if ({}.hasOwnProperty.call(pillarsData, $161)) {
                                                $160[$161] = pillarsData[$161];
                                            };
                                        };
                                        $160.debitNames = debitNames;
                                        return $160;
                                    })();
                                    return $163;
                                });
                            });
                        };
                        if (Data_Boolean.otherwise) {
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                        };
                    };
                    if (v.value0 instanceof CreditCoord) {
                        if (v.value0.value0 >= 0 && v.value0.value1 < 0) {
                            return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Data_Array.updateAt(v.value0.value0)(v.value1.value0)(pillarsData.creditNames))(function (creditNames) {
                                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                    var $171 = {};
                                    for (var $172 in v1) {
                                        if ({}.hasOwnProperty.call(v1, $172)) {
                                            $171[$172] = v1[$172];
                                        };
                                    };
                                    $171.pillarsData = (function () {
                                        var $168 = {};
                                        for (var $169 in pillarsData) {
                                            if ({}.hasOwnProperty.call(pillarsData, $169)) {
                                                $168[$169] = pillarsData[$169];
                                            };
                                        };
                                        $168.creditNames = creditNames;
                                        return $168;
                                    })();
                                    return $171;
                                });
                            });
                        };
                        if (Data_Boolean.otherwise) {
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                        };
                    };
                    throw new Error("Failed pattern match at Form (line 282, column 7 - line 289, column 35): " + [ v.value0.constructor.name ]);
                })())(function () {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                        return v1.pillarsData;
                    }))(Halogen_Query_HalogenM.raise);
                });
            });
        };
        if (v instanceof HandleOutputValue && v.value1 instanceof Form_Input.InputChange) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return v1.pillarsData;
            }))(function (pillarsData) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)((function () {
                    if (v.value0 instanceof DebitCoord) {
                        var mNewValues = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(pillarsData.values)(v.value0.value1))(function (pillarValues) {
                            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.updateAt(v.value0.value0)(v.value1.value0)(pillarValues.debit))(function (debit) {
                                return Data_Array.updateAt(v.value0.value1)((function () {
                                    var $180 = {};
                                    for (var $181 in pillarValues) {
                                        if ({}.hasOwnProperty.call(pillarValues, $181)) {
                                            $180[$181] = pillarValues[$181];
                                        };
                                    };
                                    $180.debit = debit;
                                    return $180;
                                })())(pillarsData.values);
                            });
                        });
                        return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(mNewValues)(function (newValues) {
                            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                var $186 = {};
                                for (var $187 in v1) {
                                    if ({}.hasOwnProperty.call(v1, $187)) {
                                        $186[$187] = v1[$187];
                                    };
                                };
                                $186.pillarsData = (function () {
                                    var $183 = {};
                                    for (var $184 in pillarsData) {
                                        if ({}.hasOwnProperty.call(pillarsData, $184)) {
                                            $183[$184] = pillarsData[$184];
                                        };
                                    };
                                    $183.values = newValues;
                                    return $183;
                                })();
                                return $186;
                            });
                        });
                    };
                    if (v.value0 instanceof CreditCoord) {
                        var mNewValues = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(pillarsData.values)(v.value0.value1))(function (pillarValues) {
                            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.updateAt(v.value0.value0)(v.value1.value0)(pillarValues.credit))(function (credit) {
                                return Data_Array.updateAt(v.value0.value1)((function () {
                                    var $191 = {};
                                    for (var $192 in pillarValues) {
                                        if ({}.hasOwnProperty.call(pillarValues, $192)) {
                                            $191[$192] = pillarValues[$192];
                                        };
                                    };
                                    $191.credit = credit;
                                    return $191;
                                })())(pillarsData.values);
                            });
                        });
                        return Utils.whenJust(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(mNewValues)(function (newValues) {
                            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                var $197 = {};
                                for (var $198 in v1) {
                                    if ({}.hasOwnProperty.call(v1, $198)) {
                                        $197[$198] = v1[$198];
                                    };
                                };
                                $197.pillarsData = (function () {
                                    var $194 = {};
                                    for (var $195 in pillarsData) {
                                        if ({}.hasOwnProperty.call(pillarsData, $195)) {
                                            $194[$195] = pillarsData[$195];
                                        };
                                    };
                                    $194.values = newValues;
                                    return $194;
                                })();
                                return $197;
                            });
                        });
                    };
                    throw new Error("Failed pattern match at Form (line 293, column 7 - line 307, column 109): " + [ v.value0.constructor.name ]);
                })())(function () {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                        return v1.pillarsData;
                    }))(Halogen_Query_HalogenM.raise);
                });
            });
        };
        throw new Error("Failed pattern match at Form (line 186, column 18 - line 308, column 39): " + [ v.constructor.name ]);
    };
    var renderColumnNames = function (state) {
        return Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(state.columnIds)(state.pillarsData.pillarNames))(function (idx) {
            return function (v) {
                return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(-1 | 0, v.value0))(Form_Input.component(Types.fromStringString)(dictMonadAff))({
                    output: v.value1,
                    style: CSS_Utils.gridItem(2)(idx + 3 | 0)
                })(HandleOutputName.create(new DebitCoord(-1 | 0, idx)));
            };
        });
    };
    var renderCreditNames = function (state) {
        return Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(state.creditIds)(state.pillarsData.creditNames))(function (idx) {
            return function (v) {
                return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(v.value0, -1 | 0))(Form_Input.component(Types.fromStringString)(dictMonadAff))({
                    output: v.value1,
                    style: CSS_Utils.gridItem((idx + Data_Array.length(state.debitIds) | 0) + 4 | 0)(2)
                })(HandleOutputName.create(new CreditCoord(idx, -1 | 0)));
            };
        });
    };
    var renderDebitNames = function (state) {
        return Data_Function.flip(Data_Array.mapWithIndex)(Data_Array.zip(state.debitIds)(state.pillarsData.debitNames))(function (idx) {
            return function (v) {
                return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(v.value0, -1 | 0))(Form_Input.component(Types.fromStringString)(dictMonadAff))({
                    output: v.value1,
                    style: CSS_Utils.gridItem(idx + 3 | 0)(2)
                })(HandleOutputName.create(new DebitCoord(idx, -1 | 0)));
            };
        });
    };
    var render = function (state) {
        return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.style(CSS_Utils.grid((Data_Array.length(state.debitIds) + Data_Array.length(state.creditIds) | 0) + 4 | 0)(Data_Array.length(state.columnIds) + 3 | 0)) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderDebitRemoveButtons(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderCreditRemoveButtons(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderColumnRemoveButtons(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderDebitInsertButtons(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderCreditInsertButtons(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderColumnInsertButtons(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderColumnNames(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderDebitNames(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderCreditNames(state))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderDebitValues(state))(renderCreditValues(state)))))))))))) ]);
    };
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($217) {
                return Data_Maybe.Just.create(Receive.create($217));
            },
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    Receive: Receive,
    InsertDebit: InsertDebit,
    InsertCredit: InsertCredit,
    RemoveDebit: RemoveDebit,
    RemoveCredit: RemoveCredit,
    InsertColumn: InsertColumn,
    RemoveColumn: RemoveColumn,
    HandleOutputName: HandleOutputName,
    HandleOutputValue: HandleOutputValue,
    DebitCoord: DebitCoord,
    CreditCoord: CreditCoord,
    component: component
};
