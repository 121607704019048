// Generated by purs version 0.14.0
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Encode_Encoders = require("../Data.Argonaut.Encode.Encoders/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Record = require("../Record/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var GEncodeJson = function (gEncodeJson) {
    this.gEncodeJson = gEncodeJson;
};
var EncodeJson = function (encodeJson) {
    this.encodeJson = encodeJson;
};
var gEncodeJsonNil = new GEncodeJson(function (v) {
    return function (v1) {
        return Foreign_Object.empty;
    };
});
var gEncodeJson = function (dict) {
    return dict.gEncodeJson;
};
var encodeVoid = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeVoid);
var encodeRecord = function (dictGEncodeJson) {
    return function (dictRowToList) {
        return new EncodeJson(function (rec) {
            return Data_Argonaut_Core.fromObject(gEncodeJson(dictGEncodeJson)(rec)(Type_Proxy["Proxy"].value));
        });
    };
};
var encodeNonEmptyString = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeNonEmptyString);
var encodeJsonUnit = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeUnit);
var encodeJsonJson = new EncodeJson(Control_Category.identity(Control_Category.categoryFn));
var encodeJsonJString = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeString);
var encodeJsonJNumber = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeNumber);
var encodeJsonJBoolean = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeBoolean);
var encodeJsonInt = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeInt);
var encodeJsonCodePoint = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeCodePoint);
var encodeJsonChar = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeChar);
var encodeJson = function (dict) {
    return dict.encodeJson;
};
var encodeJsonArray = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeArray(encodeJson(dictEncodeJson)));
};
var encodeJsonEither = function (dictEncodeJson) {
    return function (dictEncodeJson1) {
        return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeEither(encodeJson(dictEncodeJson))(encodeJson(dictEncodeJson1)));
    };
};
var encodeJsonList = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeList(encodeJson(dictEncodeJson)));
};
var encodeJsonMaybe = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeMaybe(encodeJson(dictEncodeJson)));
};
var encodeJsonNonEmptyArray = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeNonEmptyArray(encodeJson(dictEncodeJson)));
};
var encodeJsonNonEmptyList = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeNonEmptyList(encodeJson(dictEncodeJson)));
};
var encodeJsonNonEmpty_Array = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeNonEmpty_Array(encodeJson(dictEncodeJson)));
};
var encodeJsonNonEmpty_List = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeNonEmpty_List(encodeJson(dictEncodeJson)));
};
var encodeJsonTuple = function (dictEncodeJson) {
    return function (dictEncodeJson1) {
        return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeTuple(encodeJson(dictEncodeJson))(encodeJson(dictEncodeJson1)));
    };
};
var encodeMap = function (dictOrd) {
    return function (dictEncodeJson) {
        return function (dictEncodeJson1) {
            return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeMap(dictOrd)(encodeJson(dictEncodeJson))(encodeJson(dictEncodeJson1)));
        };
    };
};
var encodeSet = function (dictOrd) {
    return function (dictEncodeJson) {
        return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeSet(dictOrd)(encodeJson(dictEncodeJson)));
    };
};
var gEncodeJsonCons = function (dictEncodeJson) {
    return function (dictGEncodeJson) {
        return function (dictIsSymbol) {
            return function (dictCons) {
                return new GEncodeJson(function (row) {
                    return function (v) {
                        return Foreign_Object.insert(Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value))(encodeJson(dictEncodeJson)(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(row)))(gEncodeJson(dictGEncodeJson)(row)(Type_Proxy["Proxy"].value));
                    };
                });
            };
        };
    };
};
var encodeIdentity = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeIdentity(encodeJson(dictEncodeJson)));
};
var encodeForeignObject = function (dictEncodeJson) {
    return new EncodeJson(Data_Argonaut_Encode_Encoders.encodeForeignObject(encodeJson(dictEncodeJson)));
};
module.exports = {
    encodeJson: encodeJson,
    gEncodeJson: gEncodeJson,
    EncodeJson: EncodeJson,
    GEncodeJson: GEncodeJson,
    encodeIdentity: encodeIdentity,
    encodeJsonMaybe: encodeJsonMaybe,
    encodeJsonTuple: encodeJsonTuple,
    encodeJsonEither: encodeJsonEither,
    encodeJsonUnit: encodeJsonUnit,
    encodeJsonJBoolean: encodeJsonJBoolean,
    encodeJsonJNumber: encodeJsonJNumber,
    encodeJsonInt: encodeJsonInt,
    encodeJsonJString: encodeJsonJString,
    encodeJsonJson: encodeJsonJson,
    encodeJsonCodePoint: encodeJsonCodePoint,
    encodeNonEmptyString: encodeNonEmptyString,
    encodeJsonNonEmpty_Array: encodeJsonNonEmpty_Array,
    encodeJsonNonEmptyArray: encodeJsonNonEmptyArray,
    encodeJsonNonEmpty_List: encodeJsonNonEmpty_List,
    encodeJsonNonEmptyList: encodeJsonNonEmptyList,
    encodeJsonChar: encodeJsonChar,
    encodeJsonArray: encodeJsonArray,
    encodeJsonList: encodeJsonList,
    encodeForeignObject: encodeForeignObject,
    encodeSet: encodeSet,
    encodeMap: encodeMap,
    encodeVoid: encodeVoid,
    encodeRecord: encodeRecord,
    gEncodeJsonNil: gEncodeJsonNil,
    gEncodeJsonCons: gEncodeJsonCons
};
