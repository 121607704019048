// Generated by purs version 0.14.0
"use strict";
var drop = "drop";
var dragstart = "dragstart";
var dragover = "dragover";
var dragleave = "dragleave";
var dragexit = "dragexit";
var dragenter = "dragenter";
var dragend = "dragend";
var drag = "drag";
module.exports = {
    dragstart: dragstart,
    drag: drag,
    dragenter: dragenter,
    dragexit: dragexit,
    dragleave: dragleave,
    dragover: dragover,
    drop: drop,
    dragend: dragend
};
