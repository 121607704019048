// Generated by purs version 0.14.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Product = function (x) {
    return x;
};
var showProduct = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (v) {
            return "(product " + (Data_Show.show(dictShow)(v.value0) + (" " + (Data_Show.show(dictShow1)(v.value1) + ")")));
        });
    };
};
var product = function (fa) {
    return function (ga) {
        return new Data_Tuple.Tuple(fa, ga);
    };
};
var newtypeProduct = new Data_Newtype.Newtype(function () {
    return undefined;
});
var functorProduct = function (dictFunctor) {
    return function (dictFunctor1) {
        return new Data_Functor.Functor(function (f) {
            return function (v) {
                return Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple)(Data_Functor.map(dictFunctor)(f))(Data_Functor.map(dictFunctor1)(f))(v);
            };
        });
    };
};
var eq1Product = function (dictEq1) {
    return function (dictEq11) {
        return new Data_Eq.Eq1(function (dictEq) {
            return function (v) {
                return function (v1) {
                    return Data_Eq.eq1(dictEq1)(dictEq)(v.value0)(v1.value0) && Data_Eq.eq1(dictEq11)(dictEq)(v.value1)(v1.value1);
                };
            };
        });
    };
};
var eqProduct = function (dictEq1) {
    return function (dictEq11) {
        return function (dictEq) {
            return new Data_Eq.Eq(Data_Eq.eq1(eq1Product(dictEq1)(dictEq11))(dictEq));
        };
    };
};
var ord1Product = function (dictOrd1) {
    return function (dictOrd11) {
        return new Data_Ord.Ord1(function () {
            return eq1Product(dictOrd1.Eq10())(dictOrd11.Eq10());
        }, function (dictOrd) {
            return function (v) {
                return function (v1) {
                    var v2 = Data_Ord.compare1(dictOrd1)(dictOrd)(v.value0)(v1.value0);
                    if (v2 instanceof Data_Ordering.EQ) {
                        return Data_Ord.compare1(dictOrd11)(dictOrd)(v.value1)(v1.value1);
                    };
                    return v2;
                };
            };
        });
    };
};
var ordProduct = function (dictOrd1) {
    return function (dictOrd11) {
        return function (dictOrd) {
            return new Data_Ord.Ord(function () {
                return eqProduct(dictOrd1.Eq10())(dictOrd11.Eq10())(dictOrd.Eq0());
            }, Data_Ord.compare1(ord1Product(dictOrd1)(dictOrd11))(dictOrd));
        };
    };
};
var bihoistProduct = function (natF) {
    return function (natG) {
        return function (v) {
            return Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple)(natF)(natG)(v);
        };
    };
};
var applyProduct = function (dictApply) {
    return function (dictApply1) {
        return new Control_Apply.Apply(function () {
            return functorProduct(dictApply.Functor0())(dictApply1.Functor0());
        }, function (v) {
            return function (v1) {
                return product(Control_Apply.apply(dictApply)(v.value0)(v1.value0))(Control_Apply.apply(dictApply1)(v.value1)(v1.value1));
            };
        });
    };
};
var bindProduct = function (dictBind) {
    return function (dictBind1) {
        return new Control_Bind.Bind(function () {
            return applyProduct(dictBind.Apply0())(dictBind1.Apply0());
        }, function (v) {
            return function (f) {
                return product(Control_Bind.bind(dictBind)(v.value0)((function () {
                    var $67 = Data_Newtype.unwrap();
                    return function ($68) {
                        return Data_Tuple.fst($67(f($68)));
                    };
                })()))(Control_Bind.bind(dictBind1)(v.value1)((function () {
                    var $69 = Data_Newtype.unwrap();
                    return function ($70) {
                        return Data_Tuple.snd($69(f($70)));
                    };
                })()));
            };
        });
    };
};
var applicativeProduct = function (dictApplicative) {
    return function (dictApplicative1) {
        return new Control_Applicative.Applicative(function () {
            return applyProduct(dictApplicative.Apply0())(dictApplicative1.Apply0());
        }, function (a) {
            return product(Control_Applicative.pure(dictApplicative)(a))(Control_Applicative.pure(dictApplicative1)(a));
        });
    };
};
var monadProduct = function (dictMonad) {
    return function (dictMonad1) {
        return new Control_Monad.Monad(function () {
            return applicativeProduct(dictMonad.Applicative0())(dictMonad1.Applicative0());
        }, function () {
            return bindProduct(dictMonad.Bind1())(dictMonad1.Bind1());
        });
    };
};
module.exports = {
    Product: Product,
    product: product,
    bihoistProduct: bihoistProduct,
    newtypeProduct: newtypeProduct,
    eqProduct: eqProduct,
    eq1Product: eq1Product,
    ordProduct: ordProduct,
    ord1Product: ord1Product,
    showProduct: showProduct,
    functorProduct: functorProduct,
    applyProduct: applyProduct,
    applicativeProduct: applicativeProduct,
    bindProduct: bindProduct,
    monadProduct: monadProduct
};
